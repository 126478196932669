import React, { useState } from 'react';
import ReactMarkdown from 'react-markdown';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import "./CompartmentalizedTranscriptModal.css";
import TranscriptAnalysisToggle from '../../TranscriptAnalysisToggle';

const CompartmentalizedTranscriptModal = ({ title, transcriptData, onClose, onCopy,onRunAnalysis, setCustomPrompt,initialToggleTab,showOptionsOnOpen=false,isLoading=false}) => {
  const [copiedSection, setCopiedSection] = useState(null);
  const [openSections, setOpenSections] = useState({ full: true, sentence: false, word: false });
  const [searchTerms, setSearchTerms] = useState({ full: '', sentence: '', word: '' });

  const transcript = transcriptData?.channels?.[0]?.alternatives?.[0];

  if (!transcript) {
    return (
      <div className="ctsm-modal">
        <div className="ctsm-modal-content">
          <span className="ctsm-close-button" onClick={onClose}>&times;</span>
          <h1 className='ctsm-modal-title'>{title}</h1>
          <p>No transcript data available.</p>
        </div>
      </div>
    );
  }

  const handleOptionChange = (newOption) => {
    setCustomPrompt(newOption)
  };

  const handleCustomPromptChange = (newPrompt) => {
    setCustomPrompt(newPrompt)
  };

  const handleCopy = (text, section) => {
    onCopy(text);
    setCopiedSection(section);
    setTimeout(() => setCopiedSection(null), 1000);
  };

  const toggleSection = (section) => {
    setOpenSections(prev => ({ ...prev, [section]: !prev[section] }));
  };

  const handleSearch = (section, term) => {
    setSearchTerms(prev => ({ ...prev, [section]: term }));
  };

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = Math.floor(seconds % 60);
    return `${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
  };

  const renderTranscriptSection = (sectionName) => {
    const searchTerm = searchTerms[sectionName].toLowerCase();
    let filteredContent;

    if (sectionName === 'full') {
      filteredContent = transcript.transcript
        .split('. ')
        .filter(sentence => sentence.toLowerCase().includes(searchTerm));
    } else if (sectionName === 'sentence') {
      filteredContent = transcript.paragraphs?.paragraphs
        ?.flatMap(p => p.sentences)
        .filter(item => item.text.toLowerCase().includes(searchTerm)) || [];
    } else {
      filteredContent = transcript.words
        .filter(item => item.word.toLowerCase().includes(searchTerm));
    }    

    return (
      <div className="ctsm-transcript-section">
        <div className="ctsm-section-header" onClick={() => toggleSection(sectionName)}>
          <span className="ctsm-arrow">
            {openSections[sectionName] ? <ArrowDropDownIcon /> : <ArrowForwardIosIcon />}
          </span>
          <h2>{sectionName === 'full' ? 'Full Transcript' : sectionName === 'sentence' ? 'Sentence Level Timestamps' : 'Word Level Timestamps'}</h2>
          <button
            className="ctsm-copy-button"
            onClick={(e) => {
              e.stopPropagation();
              const sectionContent = sectionName === 'full'
                ? transcript.transcript
                : JSON.stringify(filteredContent.map(item => ({
                    start: item.start,
                    end: item.end,
                    text: item.text || item.punctuated_word || item.word
                  })), null, 2);
              handleCopy(sectionContent, sectionName);
            }}
          >
            {copiedSection === sectionName ? 'Copied!' : 'Copy'}
          </button>
        </div>
        {openSections[sectionName] && (
          <>
            <input 
              type="text" 
              placeholder="Search..." 
              value={searchTerms[sectionName]}
              onChange={(e) => handleSearch(sectionName, e.target.value)}
              className="ctsm-search-input"
            />
            <div className="ctsm-content">
              {sectionName === 'full' ? (
                <ReactMarkdown className="ctsm-markdown-content">
                  {filteredContent.join('. ')}
                </ReactMarkdown>
              ) : sectionName === 'sentence' ? (
                filteredContent.map((item, index) => (
                  <p key={index} className="ctsm-timestamp-item">
                    <span className="ctsm-timestamp">{formatTime(item.start)}</span>
                    <span className="ctsm-text">{item.text}</span>
                  </p>
                ))
              ) : (
                filteredContent.map((item, index) => (
                  <p key={index} className="ctsm-timestamp-item">
                    <span className="ctsm-timestamp">{formatTime(item.start)}</span>
                    <span className="ctsm-text">{item.punctuated_word || item.word}</span>
                  </p>
                ))
              )}
            </div>
          </>
        )}
      </div>
    );
  };

  const renderTranscriptContent = () => (
    <>
      {renderTranscriptSection('full')}
      {renderTranscriptSection('sentence')}
      {renderTranscriptSection('word')}
    </>
  );

  return (
    <div className="ctsm-modal">
      <div className="ctsm-modal-content">
        <span className="ctsm-close-button" onClick={onClose}>&times;</span>
        <h1 className='ctsm-modal-title'>{title}</h1>
        <TranscriptAnalysisToggle
          transcriptAvailable={!!transcript}
          renderTranscriptContent={renderTranscriptContent}
          onOptionChange={handleOptionChange}
          onCustomPromptChange={handleCustomPromptChange}
          onRunAnalysis={onRunAnalysis}
          showOptionsOnOpen={showOptionsOnOpen}
          initialToggleTab={initialToggleTab}
          isLoading={isLoading}
        />
      </div>
    </div>
  );
};

export default React.memo(CompartmentalizedTranscriptModal);
