import React from 'react';
import { Typography } from '@mui/material';
import './IntroPageQ42024.css' // Import the stylesheet


//logos
import cascdrLogo from '../../media/images/cascdr/CASCDR.webp';
import cascdrTitle from '../../media/images/cascdr/cascdr_name_only.webp';
import logicAppPreview from '../../media/images/cascdr/logic-app-preview-main-page.webp'
import logicAppPreviewIcon from '../../media/images/services/logic-app-v2.png'
import CarouselComponent from '../../common/components/CarouselComponent';
import SquareAppGrid from '../../common/components/menus/SquareAppGrid';

import cvLogo from '../../media/images/services/cascdr-vision-v2.png';
import gptLogo from '../../media/images/services/gpt-icon.svg';
import CHADLogo from '../../media/images/services/chadbot-v2.png';
import whisperLogo from '../../media/images/services/voiceToTextV2Image.png';
import rssAgentLogo from '../../media/images/services/rss-agent-v2.png';
import sdLogo from '../../media/images/services/image2-text-v2.svg';
import satStackerLogo from '../../media/images/services/sat-stacker-v2.svg';
import transcriberLogo from '../../media/images/services/rss-transcript-v2.png';
import TestimonialsGrid from '../../common/components/menus/TestimonialsGrid';
import ContactUsSection from '../../common/components/menus/ContactUsSection';

//Testimonials
import gentryImage from '../../media/images/demo/ryan-gentry-headshot.png'
import keysImage from '../../media/images/demo/brandon-keys-headshot.jpeg'
import cgImage from '../../media/images/demo/car-g.webp'
import cascdrOnBlack from '../../media/images/misc/cascdr-placeholder.png'

//Contact info
import EmailIcon from '../../media/images/misc/email-icon.png';
import XIcon from '../../media/images/misc/x-icon.png';
import SNIcon from '../../media/images/misc/sn-icon.png';
import NostrIcon from '../../media/images/misc/nostr-icon.png'



const IntroPageQ42024 = () => {
    const allAppsArray = [
      {
            pageName: 'TEXT TO IMAGE GENERATION',
            description: 'Generate images from text prompts with Stable Diffusion Models',
            image: sdLogo,
            background: 'bg-gradient-to-r from-[#f8801e] to-[#f84c1e]',
            link: 'SD',
        },
        {
            pageName: 'SAT STACKER ASSISTANT',
            description: 'Appraise the value of an item in bitcoin with just a photo. Stack sats.',
            image: satStackerLogo,
            background: 'bg-gradient-to-r from-[#f8801e] to-[#f84c1e]',
            link: 'Logic%20App&wfid=image2text_playground',
        },
        {
          pageName: 'CHATGPT3.5 PROXY',
          description: 'Chat with ChatGPT 3.5 without making an account. Privacy first AI.',
          image: gptLogo,
          background: 'bg-gradient-to-r from-[#f8801e] to-[#f84c1e]',
          link: 'GPT',
        },
        {
            pageName: 'RSS AGENT',
            description: 'Write summaries and articles from RSS feeds on demand.',
            image: rssAgentLogo,
            background: 'bg-gradient-to-r from-[#f8801e] to-[#f84c1e]',
            link: 'yt-agent',
        },
        {
          pageName: 'VOICE TO TEXT',
          description: 'Transcribe audio and video files with word level precision.',
          image: whisperLogo,
          background: 'bg-gradient-to-r from-[#f8801e] to-[#f84c1e]',
          link: 'whisper',
      },
      {
          pageName: 'GET A PODCAST TRANSCRIPT',
          description: 'Pull a Podcast & Transcribe with Whisper in one click.',
          image: transcriberLogo,
          background: 'bg-gradient-to-r from-[#f8801e] to-[#f84c1e]',
          link: 'Logic%20App&wfid=yitter_to_transcriptions',
      },
      {
        pageName: 'LOGIC APP PREVIEW',
        description: 'View demo and examples of chained workflows.',
        image: logicAppPreviewIcon,
        background: 'bg-gradient-to-r from-[#f8801e] to-[#f84c1e]',
        link: 'Logic App',
      },
      {
        pageName: 'CASCDRVISION',
        description: 'View demo and examples of chained workflows.',
        image: cvLogo,
        background: 'bg-gradient-to-r from-[#f8801e] to-[#f84c1e]',
        link: 'yitter',
      },
      {
        pageName: 'CHADBOT',
        description: 'Get AI summaries & more straight to Nostr DMs!',
        image: CHADLogo,
        background: 'bg-gradient-to-r from-[#f8801e] to-[#f84c1e]',
        link: 'chadbot',
      },
    ];

    const testimonials = [
      {
        text: '"[CASCDR] demonstrates how services integrated into a workflow and combined into a whole application can be greater than the sum of the parts."',
        name: "Ryan Gentry",
        role: "Head of BD at Lightning Labs",
        image: gentryImage
      },
      {
        text: '"CASCDR has helped Green Candle cut back on the amount of time it takes to put out quality content. It has given me more time to be creative, focus on quality, and continue to put out content in a timely manner."',
        name: "Brandon Keys",
        role: "Host of Green Candle Investments",
        image: keysImage
      },
      {
        text: '"CASCDR is at the forefront of integrating AI with bitcoin, paving the way for a future where technology serves genuine market needs without compromise."',
        name: "Car Gonzalez",
        role: "CEO Pleblab, Host of Thriller Bitcoin",
        image: cgImage
      },
      // Add more testimonials as needed
    ];
    const missionStatement = [
      {
        text: '"Use AI and Bitcoin tools to empower the individual. To help users get the most out of the productivity gains from the AI Revolution and to enrich their lives while preserving their privacy and respecting their beliefs, wishes and sovereignty. Furthermore, we want to empower a decentralized network of innovators to create value in the AI space and earn accordingly."',
        name: "CASCDR",
        role: "Mission Statement",
        image: cascdrOnBlack
      },
    ];

    const contactLinks = [
      { name: 'Twitter', icon: XIcon, url: 'https://twitter.com/cascdr_' },
      { name: 'Stacker News', icon: SNIcon, url: 'https://stacker.news/cascdr' },
      { name: 'Nostr', icon: NostrIcon, url: 'https://primal.net/p/npub14pfjj6jf8y702pdjar2q36ve5r4t2gu2lp4yma00j49jkgy7d90swg7mwj' },
      { name: 'Email', icon: EmailIcon, url: 'mailto:jim@cascdr.xyz' },
    ];
    
    return (
        <div className="intro-container">
          <div className="content-wrapper">
            <div className="intro-content">
              <img src={cascdrLogo} alt="CASCDR Logo" className="logo" />
              <img src={cascdrTitle} alt="CASCDR" className="title-logo" />
              <div className="subtitle-preview-wrapper">
                <Typography 
                  variant="h5" 
                  component="h2" 
                  className="subtitle"
                  sx={{ 
                    fontWeight: 400, 
                    fontSize:'34px',
                  }}
                >
                  Private Bitcoin Payable AI Services.
                </Typography>
              </div>
              <div className="preview-container">
                <img src={logicAppPreview} alt="CASCDR" className="logic-app-preview" />
              </div>
            </div>
            <div className="apps-section">
              <CarouselComponent title="Featured apps" />
              <SquareAppGrid appData={allAppsArray} title="All Apps" />
              <TestimonialsGrid title="Our Mission" testimonials={missionStatement} />
              <TestimonialsGrid title="Testimonials" testimonials={testimonials}/>
            </div>
            <ContactUsSection title="Contact Us" links={contactLinks} />
          </div>
        </div>
    );
};

export default IntroPageQ42024;
