import React, { useState } from 'react';
import { BeatLoader } from 'react-spinners';
import { signIn, signUp, refreshSubAndSignInStatus } from '../../lib/accountManagement';
// import bannerImage from '../../../media/images/cascadr/Cascadr__1_-removebg-preview.png';


export default function SignInUpComponent({onSuccess, onClose}) {
  const [isSignUp, setIsSignUp] = useState(false);
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const handleSignIn = async () => {
    // const result = await refreshSubAndSignInStatus();
    // console.log("refresh result:",result)
    // Example signIn function, replace with actual sign-in logic
    setLoading(true);
    console.log('Signing in...');
    try{
      const result = await signIn(email,password);
      onSuccess();
    }
    catch{
      alert("Error signing in. Please try again.")
    }

    setLoading(false);
  };

  const handleSignUp = async () => {
    // Example signIn function, replace with actual sign-in logic
    setLoading(true);
    console.log('Signing up...');
    try{
      const result = await signUp(email,password);
      onSuccess();
    }
    catch{
      alert("Error signing up. Please try again.")
    }
    
    // simulate network request
    setLoading(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (isSignUp) {
      if (password !== confirmPassword) {
        alert('Passwords do not match!');
        return;
      }
      // Implement sign-up logic here
      handleSignUp();
      console.log('Signing up...');
    } else {
      handleSignIn();
    }
  };

  return (
    <div> {/* Outer container remains unchanged */}
    <div className="w-full max-w-md p-6 bg-white dark:bg-orange-600 rounded-lg shadow-lg relative"> {/* Add relative positioning here */}
      <div className="p-4">
          <div className="flex justify-center mb-4">
            <button 
              style={{marginRight:"8px"}}
              className={`font-bold ${!isSignUp ? 'text-orange-900 underline' : 'text-gray-700'}`} 
              onClick={() => setIsSignUp(false)}>
              Sign in
            </button>
            <button 
                style={{marginLeft:"8px"}}
                className={`font-bold ${isSignUp ? 'text-orange-900 underline' : 'text-gray-700'}`} 
                onClick={() => setIsSignUp(true)}>
                Sign up
            </button>  
          </div>
          <form onSubmit={handleSubmit}>
            <div className="space-y-4">
              <label htmlFor="email" className="block text-sm font-medium text-gray-700">Email</label>
              <input
                id="email"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="satoshin@gmx.com"
                className="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm text-black"
              />

              <div className="flex items-center justify-between">
                <label htmlFor="password" className="block text-sm font-medium text-gray-700">Password</label>
                {/* {!isSignUp && <a href="#" className="text-indigo-600 hover:text-indigo-500 text-sm">Forgot password?</a>} */}
              </div>
              <input
                id="password"
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm text-black"
              />

              {isSignUp && (
                <div>
                  <label htmlFor="confirmPassword" className="block text-sm font-medium text-gray-700">Confirm Password</label>
                  <input
                    id="confirmPassword"
                    type="password"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    className="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm text-black"
                  />
                </div>
              )}

              <button
                type="submit"
                disabled={loading}
                className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-orange-900 hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500"
              >
                {loading ? <BeatLoader color="#FFFFFF" /> : isSignUp ? 'Sign up' : 'Sign in'}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
