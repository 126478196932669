import React, { createContext, useContext, useState } from 'react';

const LogicAppTabContext = createContext();

export function LogicAppTabProvider({ children }) {
  const [logicAppTab, setLogicAppTab] = useState('llm_to_text2image');

  return (
    <LogicAppTabContext.Provider value={{ logicAppTab, setLogicAppTab }}>
      {children}
    </LogicAppTabContext.Provider>
  );
}


export function useLogicAppTab() {
  const context = useContext(LogicAppTabContext);
  if (!context) {
    throw new Error('useActiveTab must be used within an LogicAppTabContext');
  }
  return context;
}



