import React, { memo } from 'react';
import { Handle, useReactFlow, useStoreApi, Position } from 'reactflow';
import '../GPT/GPTNode.css';
import sd_icon from '../../media/images/services/stable-diffusion.png'
import options from '../../common/constants'


function Dropdown({ value, nodeId, onChange }) {
  return (
    <div className="custom-node__select">
      <div>SD Model</div>
      <select className="nodrag custom_node__dropdown" onChange={onChange} value={value}>
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    </div>
  );
}

function TextInput({ value, nodeId }) {
  const { setNodes } = useReactFlow();
  const store = useStoreApi();

  const onChange = (evt) => {
    const { nodeInternals } = store.getState();
    setNodes(
      Array.from(nodeInternals.values()).map((node) => {
        if (node.id === nodeId) {
          node.data = {
            ...node.data,
            textInput: evt.target.value,
          };
        }

        return node;
      })
    );
  };

  return (
    <div className="custom-node__input">
    {/*TODO: make this conditionally render based on whether it's hooked up to something else */}
{/*      <div>Type Your SD Query:</div>
      <textarea 
        className="nodrag" 
        onChange={onChange} 
        value={value} 
        rows={8} // specifies the number of lines
        style={{ resize: 'none', width: '100%' }} // additional styling, you can also move it to your CSS file
      />*/}
    </div>
  );
}

function SDNode({ id, data }) {
  //console.log('SD Node data: data',JSON.stringify(data, null, 2))
  var icon = sd_icon;
  return (
    <div className="custom-node">
      <div className="custom-node__icon">
        <img src={icon} alt="icon" />
      </div>
      <div className="custom-node__header">
        <strong>SD Request</strong>
      </div>
      <div className="custom-node__body">
        <Dropdown nodeId={id} value={data.dropdown} onChange={data.onChange}/>
        <TextInput nodeId={id} value={data.textInput} />
      </div>
      <Handle type="target" position={Position.Left} id="a" />
      <Handle type="source" position={Position.Right} id="a" />
      <div className="custom-node__header">
        <p><strong>Estimated Price: </strong>{data.price ? data.price/1000 : "N/A"} sats</p>
      </div>
    </div>
  );
}




export default memo(SDNode);
