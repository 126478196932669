import {useEffect, useState, Fragment} from 'react';
import { getHasEmailAndSubscription, getIsSignedIn } from '../../nip105-client.js';
import CustomAlert from '../CustomAlert.jsx';
import Box from '@mui/material/Box';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Paper from '@mui/material/Paper';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import AddressForm from './AddressForm';
import Grid from '@mui/material/Grid';
import PaymentFormComponent from './PaymentFormComponent';
import PricingCard from '../modal/PricingCard';
import axios from 'axios';
import cascdrAmber from '../../../media/images/cascdr/cascdrAmberLogo.png';
import squareLogo from '../../../media/images/misc/squareLogo.png';
import { BeatLoader } from 'react-spinners';
import SignInUpComponent from '../SignInUpComponent.jsx'

import "./CheckoutFormComponent.css";
import { step } from '@material-tailwind/react';

function Copyright() {
  return (
    <div>
      <Typography variant="body2" color="text.secondary" align="center">
        {'Copyright © '}
        <Link color="inherit" href="https://cascdr.vercel.app/">
          CASCDR
        </Link>{' '}
        {new Date().getFullYear()}
        {'.'}
      </Typography>
      <Typography variant="body2" color="text.secondary" align="center">
        Powered by
        <Link color="inherit" href="https://squareup.com/us/en" style={{marginLeft: "5px"}}>
          Square
        </Link>{' '}
      </Typography>
    </div>
  );
}

const steps = ['Sign In','Billing Address', 'Payment Details'];

function getStepContent({step, onSignUpInSuccess, setFormData, formData, setPaymentProcessing, paymentProcessing, paymentFailed, setCard}) {
  switch (step) {
    case 0:
      return <SignInUpComponent onSuccess={onSignUpInSuccess}/>
    case 1:
      return <AddressForm setParentFormData={setFormData} parentFormData={formData}/>;
    case 2:
      return <PaymentFormComponent setPaymentProcessing={setPaymentProcessing} paymentProcessing={paymentProcessing} paymentFailed={paymentFailed} setCard={setCard}/>;
    default:
      throw new Error('Unknown step');
  }
}

function hasEmptyFields(obj, allowedField) {
  for (const key in obj) {
    if (obj.hasOwnProperty(key) && key !== allowedField && obj[key] === "") {
      return true;
    }
  }
  return false;
}

export default function CheckoutFormComponent({handleClose,onSuccess,onAlreadySignedUp,handleAlreadySignedUpShown}) {
  const [activeStep, setActiveStep] = useState(0);
  const [consent, setConsent] = useState(true);
  const [isPaymentProcessing, setIsPaymentProcessing] = useState(false);
  const [isPaymentFailed, setIsPaymentFailed] = useState(false);
  const [card, setCard] = useState(null);
  const [showAlreadySignedUp, setShowAlreadySignedUp] = useState(false);
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    address1: '',
    address2: '',
    city: '',
    state: '',
    zip: '',
    country: ''
  });

  const debug = false
  const paymentServerUrl = (debug === false) ? "https://cascdr-auth-backend-cw4nk.ondigitalocean.app" : "http://localhost:4000";


  const handleNext = () => {
    setActiveStep(activeStep + 1);
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  const handlePayment = async () => {
    const statusContainer = document.getElementById('payment-status-container');
    setIsPaymentProcessing(true);
    setIsPaymentFailed(false);
    statusContainer.innerHTML = "Processing..."
    try {
      const result = await card.tokenize();
      const userEmail = localStorage.getItem('email');
      if (result.status === 'OK' && result.token && userEmail) {
        console.log("customeer email:", userEmail)
        console.log("card result:",result)
        console.log(`Payment token is ${result.token}`);
        const paymentToken = result.token;
        console.log("payment token:", paymentToken);
        console.log("formData:", formData);
        const card = {
          "billing_address":{
            "address_line_1":formData.address1,
            "address_line_2":formData.address2,
            "locality":formData.city,
            "administrative_district_level_1":formData.state,
            "postal_code":formData.zip,
            "country":formData.country
          }
        }
        const cardholderName = `${formData.firstName} ${formData.lastName}`
        const response = await axios.post(`${paymentServerUrl}/purchase-subscription`, {
          email:userEmail,
          paymentToken,
          productName: "amber",
          cardholderName,
          card
        });
        localStorage.setItem("isSubscribed","true")
        statusContainer.innerHTML = "Payment Successful";
        setIsPaymentProcessing(false);
        onSuccess();
        //console.log("Backend response:", response.data);
      } else {
        let errorMessage = `Tokenization failed with status: ${result.status}`;
        if (result.errors) {
          errorMessage += ` and errors: ${JSON.stringify(result.errors)}`;
        }

        throw new Error(errorMessage);
      }
    } catch (e) {
      console.error(e);
      statusContainer.innerHTML = "Payment Failed";
      setIsPaymentProcessing(false);
      setIsPaymentFailed(true);
    }
  };

  useEffect(() => {
    if(getHasEmailAndSubscription() === true){
      setShowAlreadySignedUp(true);
      handleAlreadySignedUpShown(); // Call this function here
    }
    else if(getIsSignedIn() === true){
      handleNext();
    }
  }, []);

  useEffect(() => {
    handleAlreadySignedUpShown();
  }, [showAlreadySignedUp]);

  useEffect(() => {
    console.log('formDataParent:', formData);
  }, [formData]);

  const onSignUpInSuccess = () => {
    if(getHasEmailAndSubscription() === true){
      setShowAlreadySignedUp(true);
      handleAlreadySignedUpShown(); // Call this function here
    }
    else{
      handleNext();
    }
  }

  const handleAlertClose = () => {
    console.log("handleAlertClose")
    onAlreadySignedUp();
  }

  return (
    showAlreadySignedUp ? (
      <div style={{
        position: 'fixed',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        zIndex: 1050,
      }}>
        <CustomAlert
          isOpen={showAlreadySignedUp}
          message={"You're already subscribed to CASCDR! Enjoy the app!"}
          button1={{ text: "Great!", action: handleAlertClose }}
        />
      </div>
    ) : (
      <Fragment>
      <div className='flex-container w-[90%] max-h-[80%]'>
        <div className='image-box'>
          <img src={cascdrAmber} alt="CASCDR Amber Logo" style={{minWidth: "400px", height: "auto", padding: "5px", maxHeight: "300px"}}/>
          <div style={{display: "flex", flexDirection: "row"}}>
            Powered By: 
            <img src={squareLogo} alt="Square Logo" style={{minWidth: "auto", height: "auto", paddingBottom: "25px", maxHeight: "50px", paddingLeft: "20px"}}/>
          </div>
          <div className="pricing-container">
            <PricingCard
              plan="Amber Plan"
              price="9.99"
              description="Private, sovereign AI at your fingertips."
              buttonText="Get started for free"
              features={[
                "Access to 20+ CASCDR Services",
                "Unlimited runs",
                "1st access to New Features"
              ]}
            />
          </div>
        </div>
        <div className='checkout-box'>
          <Paper variant="outlined" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }} style={{maxWidth: "70%", minWidth: "400px"}}>
            <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-end"}}>
              <button type="button" style={{fontSize: "20px", margin: "15px"}} onClick={handleClose}>
                X
              </button>
            </div>
            <Typography variant="h4" align="center">
              Checkout
            </Typography>
            <Stepper activeStep={activeStep} sx={{ 
            pt: 3, 
            pb: 5,
            "& .MuiStepConnector-line": {
              borderTopWidth: "4px",
            },
            "& .MuiStepConnector-root.Mui-active .MuiStepConnector-line": {
              borderColor: "orangered",
            },
            "& .MuiStepLabel-root .Mui-completed": {
              color: 'orangered', // circle color (COMPLETED)
            },
            "& .MuiStepLabel-root .Mui-active": {
              color: 'orangered', // circle color (ACTIVE)
            }, }}>
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel sx={{
                  '& .MuiStepLabel-root .Mui-completed': {
                    color: 'redorange'
                  },}}>
                    {label}
                  </StepLabel>
                </Step>
              ))}
            </Stepper>
            {activeStep === steps.length ? (
              <Fragment>
                <Typography variant="h5" gutterBottom>
                  Thank you for your purchase!
                </Typography>
              </Fragment>
            ) : (
              <Fragment>
                {getStepContent({step: activeStep,onSignUpInSuccess, setFormData, formData, setPaymentProcessing: setIsPaymentProcessing, paymentProcessing: isPaymentProcessing, paymentFailed: isPaymentFailed, setCard})}
                {activeStep === steps.length -1 && 
                  <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: '30px', marginBottom: '15px'}}>
                    <Grid item xs={12}>
                      <FormControlLabel
                        control={<Checkbox 
                                    name="saveAddress"
                                    value="consent"
                                    checked={consent}
                                    style ={{ color: "orangered" }}
                                    onChange={(e) => setConsent(e.target.checked)}
                                  />}
                        label="I consent to CASCDR saving my payment information on file."
                      />
                    </Grid>
                  </Box>
                }
                <div style={{display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "row"}}>
                  <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    {activeStep !== 0 && (
                      <Button 
                        variant="contained"
                        onClick={handleBack}
                        sx={{ mt: 3, ml: 1 }}
                        className='checkout-button'
                        style={{width: "150px"}}
                      >
                        Back
                      </Button>
                    )}

                    {activeStep !== 0 && (
                      <Button
                        variant="contained"
                        onClick={activeStep === steps.length - 1 ? handlePayment : handleNext}
                        sx={{ mt: 3, ml: 1 }}
                        className='checkout-button'
                        style={{width: "150px"}}
                        disabled={hasEmptyFields(formData, 'address2') || (activeStep === steps.length - 1 && !consent) || isPaymentProcessing}
                      >
                        {isPaymentProcessing ? <BeatLoader color="#FFFFFF" /> : activeStep === steps.length - 1 ? 'Subscribe' : 'Next'}
                    </Button>
                    )}
                  </Box>
                </div>
              </Fragment>
            )}
            <br></br>
            <Copyright />
          </Paper>
        </div>
      </div>
    </Fragment>
    )
  );
}