import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import { Tooltip } from '@mui/material';

const ArchivedClipsComponent = ({ onClipCopy }) => {
  const [clips, setClips] = useState([]);

  useEffect(() => {
    let storedClips = JSON.parse(localStorage.getItem('archivedClips') || '[]');
    storedClips.sort((a, b) => new Date(b.date) - new Date(a.date));
    setClips(storedClips);
  }, []);

  const handleCopyClipUrl = (url) => {
    navigator.clipboard.writeText(url).then(() => {
      onClipCopy();
    }).catch(err => console.error('Failed to copy URL: ', err));
  };

  return (
    <div style={{ margin: '10px', overflowY: 'auto', maxHeight:'55vh'}}>
      {clips.length > 0 ? (
        <ul>
          {clips.map((clip, index) => (
            <li key={index} style={{ border: '1px solid #201201', padding: '10px', marginBottom: '10px', borderRadius: '8px', backgroundColor: 'black', color: '#FFBD4D' }}>
              <div style={{ textAlign: 'left' }}>
                <strong className='singleLine' style={{ color: 'white', fontSize: '18px' }}>{clip.videoTitle}</strong>
                <p style={{ color: 'rgba(255, 255, 255, 0.75)', fontSize: '16px', marginTop: '5px' }}>
                  {clip.transcript}
                </p>
              </div>
              <Tooltip title="Copy to clipboard" placement="top">
                <Button onClick={() => handleCopyClipUrl(clip.url)} sx={{
                  padding: '10px',
                  width: '100px',
                  border: '2px solid #FFA500',
                  borderRadius: '4px',
                  cursor: 'pointer',
                  background: 'transparent',
                  color: 'white',
                  fontWeight: 'bold',
                  margin: '2px',
                  whiteSpace: 'nowrap', // Ensures text does not wrap
                  '&:hover': {
                    background: '#FFA500',
                    color: 'black'
                  }
                }}>
                  Clip 🔗
                </Button>
              </Tooltip>
            </li>
          ))}
        </ul>
      ) : (
        <div style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '55vh',
          color: 'white',
          fontSize: '20px',
          fontWeight: 'bold',
          backgroundColor: '#201201',
          borderRadius: '8px'
        }}>
          No Clips Made Yet
        </div>
      )}
    </div>
  );
};

export default ArchivedClipsComponent;
