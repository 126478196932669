const options = [
  //... your options
  { value: 'dream-shaper-8797', label: 'dream-shaper-8797' },
  { value: 'sdxl', label: 'sdxl' },
  {value: 'f222-diffusion', label: 'f222-diffusion'},
  {value: 'night-diffusion', label: 'night-diffusion'}
];
export const WHISPR_ENDPOINT = "https://whispr-v3-w-caching-ex8zk.ondigitalocean.app";


export default options;