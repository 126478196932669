import React from 'react';
import rightArrowImage from '../../media/images/misc/forward-button.png';

const SquareAppCard = ({ title, description, icon, actionId, onClick }) => {
  return (
    <div 
      className="w-[284px] h-[284px] rounded-lg overflow-hidden cursor-pointer relative transition-all duration-300 ease-in-out hover:outline hover:outline-2 hover:outline-white hover:outline-offset-0 hover:shadow-[0_0_0_1px_white]"
      onClick={() => onClick(actionId)}
    >
      <div className="w-full h-full bg-gradient-to-r from-[#f8801e] to-[#f84c1e] p-6 flex flex-col justify-between">
        <div className="flex flex-col items-center">
          <img 
            src={icon} 
            alt={title} 
            className="w-24 h-24 mb-4" 
            // style={{
            //   filter: 'brightness(0) invert(1)',
            //   WebkitFilter: 'brightness(0) invert(1)'
            // }}
          />
          <h3 className="text-xl font-bold text-black mb-2 text-center">{title}</h3>
          <p className="text-sm text-black text-center" style={{fontSize:'20px', marginLeft:'10px', marginRight:'10px'}}>{description}</p>
        </div>
        <div className="absolute bottom-4 right-4 w-[35px] h-[35px] flex items-center justify-center">
          <img 
            src={rightArrowImage} 
            alt="Next" 
            className="w-full h-full"
          />
        </div>
      </div>
    </div>
  );
};

export default SquareAppCard;