import React from 'react';

const TestimonialCard = ({ text, name, role, image, imagePosition }) => {
  return (
    <div className={`bg-black p-4 md:p-6 rounded-lg border border-white w-full flex flex-col ${imagePosition === 'right' ? 'md:flex-row-reverse' : 'md:flex-row'}`}>
      <div className={`flex flex-col items-center ${imagePosition === 'right' ? 'md:items-end' : 'md:items-start'} w-full md:w-1/3 mb-4 md:mb-0`}>
        <div className="rounded-full border border-[#ffffff] mb-4" style={{borderWidth:'4px'}}>
          <img 
            src={image} 
            alt={name} 
            className="w-32 h-32 md:w-48 md:h-48 rounded-full object-cover"
          />
        </div>
        <div className={`flex flex-col items-center ${imagePosition === 'right' ? 'md:items-end' : 'md:items-start'}`}>
          <h4 className="text-white font-bold text-lg md:text-2xl text-center md:text-left">{name}</h4>
          <p className="text-gray-400 text-sm md:text-lg pt-1 text-center md:text-left">{role}</p>
        </div>
      </div>
      <div className="w-full md:w-2/3 flex items-center justify-center md:justify-start">
        <p className={`text-white text-lg md:text-2xl lg:text-3xl text-center ${imagePosition === 'right' ? 'md:text-right md:pr-6' : 'md:text-left md:pl-6'}`}>
          {text}
        </p>
      </div>
    </div>
  );
};

export default TestimonialCard;