import React, { useState } from 'react';
import CheckoutFormComponent from '../checkout/CheckoutFormComponent';

function CheckoutFormModal({ isOpen, onClose, onSuccess, onAlreadySignedUp }) {
  const [hasBorder, setHasBorder] = useState(true);

  if (!isOpen) {
    return null;
  }

  const handleAlreadySignedUpShown = () => {
    setHasBorder(false);
  };

  const borderStyle = hasBorder
    ? { borderColor: 'transparent', borderWidth: '1px' }
    : { borderColor: 'white', borderWidth: '1px' };

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50" style={{ zIndex: 9999 }}>
      <div 
        className="bg-black p-8 w-[90%] max-h-[80%] overflow-y-auto rounded-lg shadow-lg relative" 
        style={{ ...borderStyle, zIndex: '1000' }}
      >
        <CheckoutFormComponent 
          handleClose={onClose} 
          onSuccess={onSuccess} 
          onAlreadySignedUp={onAlreadySignedUp} 
          handleAlreadySignedUpShown={handleAlreadySignedUpShown}
        />
      </div>
    </div>
  );
}

export default CheckoutFormModal;