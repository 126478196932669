import React, { useState, useEffect } from 'react';
import CheckoutFormModal from '../../common/components/modal/CheckoutFormModal';
import CustomAlert from '../../common/components/CustomAlert';
import { useActiveTab } from '../../common/ActiveTabContext';
import { getHasEmailAndSubscription } from '../../common/nip105-client';

const UpgradePage = () => {
    const { setActiveTab } = useActiveTab();
    const [showSuccessAlert, setShowSuccessAlert] = useState(false); // State to control the display of the success alert
    const [showCheckout,setShowCheckout] = useState(false);
    const [showAlreadySignedUp,setShowAlreadySignedUp] = useState(false);

    useEffect(() => {
        if(getHasEmailAndSubscription() === true){
            setShowCheckout(false);
            setShowAlreadySignedUp(true);
            return;
        }
        else{
            setShowAlreadySignedUp(false);
            setShowCheckout(true);
        }
    }, []);
    

    const onSuccess = () => {
        // Logic to handle successful upgrade
        setShowSuccessAlert(true); // Show the success alert
    };

    const onClose = () => {
        // Logic to handle when modal is closed
        // Intentionally empty if you don't need any specific action on close
        handleAlertClose();
    };

    const onAlreadySignedUp = () => {
        onClose();
    }

    const handleAlertClose = () => {
        // Function to handle when the alert is acknowledged
        setShowSuccessAlert(false); // Close the success alert
        setActiveTab(null); // Reset the active tab to show the IntroPage
    };

    return (
        <div style={{ height: '100vh', width: '100vw' }}>
            <CheckoutFormModal isOpen={showCheckout} onClose={onClose} onSuccess={onSuccess} onAlreadySignedUp={onAlreadySignedUp} />
            {showSuccessAlert && (
                <div style={{
                    position: 'fixed', // Make sure it's on top of other content
                    top: '50%', // Center vertically
                    left: '50%', // Center horizontally
                    transform: 'translate(-50%, -50%)', // Adjust transform to center the alert
                    zIndex: 1050, // Higher z-index to be on top of the modal
                }}>
                    <CustomAlert
                        isOpen={showSuccessAlert}
                        message={"Your subscription was a success! Enjoy exploring the future of AI with us!"}
                        button1={{ text: "Great!", action: handleAlertClose }}
                    />
                </div>
            )}
            {showAlreadySignedUp && (
                <div style={{
                    position: 'fixed', // Make sure it's on top of other content
                    top: '50%', // Center vertically
                    left: '50%', // Center horizontally
                    transform: 'translate(-50%, -50%)', // Adjust transform to center the alert
                    zIndex: 1050, // Higher z-index to be on top of the modal
                }}>
                    <CustomAlert
                        isOpen={showAlreadySignedUp}
                        message={"You're already subscribed to CASCDR! Enjoy the app!"}
                        button1={{ text: "Great!", action: handleAlertClose }}
                    />
                </div>
            )}
        </div>
    );
}

export default UpgradePage;
