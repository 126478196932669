import { useEffect } from "react";
import ChoosePaymentModal from './modal/ChoosePaymentModal';
import CheckoutFormModal from './modal/CheckoutFormModal';
import AlertModal from './modal/AlertModal';
import { resetQueuedJob, getQueuedJob, requestLNProvider, PaymentChoiceStatus } from "../nip105-client";

const PaymentChoiceComponent = ({ paymentChoiceStatus,  setPaymentChoiceStatus }) => {

    function nextPaymentChoiceState(event) {
        console.log(`Event received: ${event}`);
        
        const userEmail = localStorage.getItem("email");
        const isSubscribed = localStorage.getItem("isSubscribed") === "true";
        console.log(`User email: ${userEmail}, Is Subscribed: ${isSubscribed}`);
        
        if (event === "lightningChosen") {
            console.log("Lightning payment chosen");
            requestLNProvider();
            setPaymentChoiceStatus(PaymentChoiceStatus.LIGHTNING_CHOSEN);
        } else if (event === "cardChosen") {
            if (!isSubscribed) {
                console.log("User is signed in but not subscribed, moving to checkout");
                setPaymentChoiceStatus(PaymentChoiceStatus.SQUARE_CHECKOUT);
            } else {
                console.log("User is subscribed, marking as done");
                setPaymentChoiceStatus(PaymentChoiceStatus.DONE);
            }
        } else if (event === "signInSuccess") {
            console.log("Sign-in success, moving to checkout");
            const nextState = (isSubscribed === false) ? (PaymentChoiceStatus.SQUARE_CHECKOUT) : (null)
            setPaymentChoiceStatus(nextState);
        }
    };

    return (
        <>
            <ChoosePaymentModal isOpen={paymentChoiceStatus === PaymentChoiceStatus.CHOOSE_PAYMENT_METHOD}
                onClose={() => setPaymentChoiceStatus(null)}
                onCardSelected={() => nextPaymentChoiceState("cardChosen")}
                onLNSelected={() => nextPaymentChoiceState("lightningChosen")}
                // onButtonClick does not seem to be a valid prop for ChoosePaymentModal based on your provided code
                style={{ zIndex: '1000' }}
            />
            <CheckoutFormModal
                isOpen={paymentChoiceStatus === PaymentChoiceStatus.SQUARE_CHECKOUT}
                onClose={() => setPaymentChoiceStatus(PaymentChoiceStatus.SQUARE_CHECKOUT_SUCCEEDED)}
                onSuccess={() => setPaymentChoiceStatus(PaymentChoiceStatus.SQUARE_CHECKOUT_SUCCEEDED)}
                onAlreadySignedUp={() => setPaymentChoiceStatus(null)}
            />
            <AlertModal
                isOpen={paymentChoiceStatus === PaymentChoiceStatus.SQUARE_CHECKOUT_SUCCEEDED}
                onClose={() => setPaymentChoiceStatus(PaymentChoiceStatus.DONE)}
                alertText={"CASCDR Amber Subscription Activated! Enjoy unlimited user of CASCDR!"}
                buttonText={"Let's go!"}
            />
        </>
    );
};

export default PaymentChoiceComponent;