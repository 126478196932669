import React, { memo } from 'react';
import { Handle, useReactFlow, useStoreApi, Position } from 'reactflow';
import '../GPT/GPTNode.css';
import RSSIcon from '../../media/images/services/rss_icon.png';

const options = [
  //... your options
  { value: 'GPT3.5Turbo', label: 'GPT 3.5 Turbo' },
];

function Dropdown({ value, nodeId }) {
  const { setNodes } = useReactFlow();
  const store = useStoreApi();

  const onChange = (evt) => {
    const { nodeInternals } = store.getState();
    setNodes(
      Array.from(nodeInternals.values()).map((node) => {
        if (node.id === nodeId) {
          node.data = {
            ...node.data,
            dropdown: evt.target.value,
          };
        }

        return node;
      })
    );
  };

  return (
    <div className="custom-node__select">
      <div>GPT Model</div>
      <select className="nodrag custom_node__dropdown" onChange={onChange} value={value}>
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    </div>
  );
}

function TextInput({ value, nodeId,onChange }) {
  return (
    <div className="custom-node__input">
      <div>Podcast Feed Name:</div>
      <textarea 
        className="nodrag" 
        onChange={onChange} 
        value={value} 
        rows={4} // specifies the number of lines
        style={{ resize: 'none', width: '100%' ,color:'black','paddingLeft':'2px','paddingRight':'2px'}} // additional styling, you can also move it to your CSS file
      />
    </div>
  );
}

function RSSExtractorNode({ id, data = { textInput: '', onChange: () => {}, price: null } }) {
  return (
    <div className="custom-node">
      <div className="custom-node__icon">
        <img src={RSSIcon} alt="icon" />
      </div>
      <div className="custom-node__header">
        <strong>Get Last RSS Episode</strong>
      </div>
      <div className="custom-node__body">
        <TextInput nodeId={id} value={data.textInput} onChange={data.onChange}/>
      </div>
      <Handle type="source" position={Position.Right} id="b" />
      <Handle type="target" position={Position.Left} id="a" />
      <div className="custom-node__header">
        <p><strong>Estimated Price: </strong>{data.price ? data.price/1000 : "N/A"} sats</p>
      </div>
    </div>
  );
}




export default memo(RSSExtractorNode);
