import React, { useState, useEffect, useRef } from 'react';
import { run_make_clips } from '../nip105-client';
import './LogCaptureComponent.css';

const TranscriptCaptureComponent = ({
  title, 
  videoId,
  videoTitle,
  transcript,
  setTranscriptTime, 
  playerTime, 
  setPaymentChoiceStatus,
  transcriptLoading,
  onSearchSubmit,
  onMakeClip
}) => {
  const [isCopied, setIsCopied] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const refs = useRef([]);
  const [highlightIndex, setHighlightIndex] = useState(-1);
  const [makeClipMode, setMakeClipMode] = useState(false);
  const [selectedIndices, setSelectedIndices] = useState([]);
  const [isCreatingClip, setIsCreatingClip] = useState(false);
  const [isClipCopied, setIsClipCopied] = useState(false);
  const [clipLink, setClipLink] = useState(null);
  const [dots, setDots] = useState('');
  const [autoScroll, setAutoScroll] = useState(true)
  const [showBubble, setShowBubble] = useState(false);
  const [bubbleText, setBubbleText] = useState("")

  useEffect(() => {
    refs.current = refs.current.slice(0, transcript.length);
  }, [transcript]);

  useEffect(() => {
    console.log(`playerTime in TranscriptCaptureComponent: ${playerTime}`);
    if (!Array.isArray(transcript) || transcript.length === 0 || !autoScroll) {
      return;
    }
    const activeIndex = transcript.findIndex(([time, _], index) => {
      const nextTime = index + 1 < transcript.length ? transcript[index + 1][0] : Infinity;
      return isActive(time, nextTime);
    });
    if (activeIndex !== -1 && refs.current[activeIndex]) {
      refs.current[activeIndex].scrollIntoView({
        behavior: 'smooth',
        block: 'center'
      });
    }
  }, [playerTime, transcript]); // Ensure `playerTime` and `transcript` are dependencies

  const isActive = (startTime, endTime) => {
    return playerTime >= startTime && playerTime < endTime;
  };


  const toggleMakeClipMode = () => {
    setMakeClipMode(!makeClipMode);
    
    if (!makeClipMode) {
      setBubbleText("Highlight or click text chunks to add them to the clip.")
      setShowBubble(true);
      setHighlightIndex(-1)
      setSelectedIndices([]);  // Clear selection when entering make clip mode
    }
    else{
      handleFinishClip();
    }
  };

  const handleFinishClip = async () => {
    if (selectedIndices.length === 0) {
      console.log("No clips selected.");
      return;
    }
  
    // Sort indices and merge contiguous segments
    const sortedIndices = selectedIndices.sort((a, b) => a - b);
    const mergedSegments = sortedIndices.reduce((acc, index) => {
      const lastSegment = acc[acc.length - 1];
      const currentTime = transcript[index][0]; // Assuming transcript[index] is [time, text]
      
      const nextTime = index + 1 < transcript.length ? transcript[index + 1][0] : null;
  
      if (lastSegment && lastSegment.end === currentTime) {
        lastSegment.end = nextTime;
      } else {
        acc.push({ start: currentTime, end: nextTime });
      }
      return acc;
    }, []);

    const initialText = sortedIndices.reduce((acc, index) => {
      const text = transcript[index][1] + "..."; // Assuming transcript[index] is [time, text]

      return text;
    }, []);

    try{
      console.log("Merged Segments:", mergedSegments);
      setIsCreatingClip(true);
      const clipLink = await run_make_clips(videoId,mergedSegments);
      if (clipLink.authCategory && clipLink.authCategory === "not-selected") {
        setPaymentChoiceStatus("choosePaymentMethod");
        setIsCreatingClip(false);
        return;
      }
      console.log(`clipLink:${clipLink}`)
      const clipUrl = clipLink.resultUrl; // Directly using the correct key
  
      if (clipUrl) {
        const clipData = {
          date: new Date().toISOString(),
          url: clipUrl, // Storing the actual URL
          transcript: initialText,
          videoTitle: videoTitle
        };
        const existingClips = JSON.parse(localStorage.getItem('archivedClips') || '[]');
        localStorage.setItem('archivedClips', JSON.stringify([...existingClips, clipData]));
        onMakeClip();
        console.log(`Clip link stored: ${clipUrl}`);
      }
      
    }
    catch{
      
    }
    onMakeClip();
    setIsCreatingClip(false);
  };
  

  const handleSelectText = (index) => {
    if (makeClipMode) {
      const newSelection = selectedIndices.includes(index)
        ? selectedIndices.filter(i => i !== index)
        : [...selectedIndices, index];
      setSelectedIndices(newSelection);
    } else {
      setTranscriptTime(transcript[index][0]);  // Set time as usual if not in makeClipMode
    }
  };

  const handleTextMouseUp = () => {
    if (makeClipMode) {
      const selection = window.getSelection();
      if (!selection.isCollapsed) {
        const range = selection.getRangeAt(0);
        const startNode = range.startContainer.parentNode;
        const endNode = range.endContainer.parentNode;
        const startIndex = Array.from(refs.current).indexOf(startNode);
        const endIndex = Array.from(refs.current).indexOf(endNode);
        
        if (startIndex !== -1 && endIndex !== -1) {
          const indicesToAdd = [];
          for (let i = Math.min(startIndex, endIndex); i <= Math.max(startIndex, endIndex); i++) {
            indicesToAdd.push(i);
          }
          setSelectedIndices(prevIndices => [...new Set([...prevIndices, ...indicesToAdd])]);
        }

        selection.removeAllRanges();
      }
    }
  };


  

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const searchValue = inputValue.trim().toLowerCase();
    let startIndex = highlightIndex + 1;
    let index = transcript.slice(startIndex).findIndex(([_, text]) => text.toLowerCase().includes(searchValue));

    if (index === -1 && startIndex > 0) {
      index = transcript.slice(0, startIndex).findIndex(([_, text]) => text.toLowerCase().includes(searchValue));
      index = index !== -1 ? index : -1;
    } else {
      index += startIndex;
    }

    if (index !== -1 && refs.current[index]) {
      refs.current[index].scrollIntoView({ behavior: 'smooth', block: 'center' });
      setHighlightIndex(index);
      setAutoScroll(false);
    } else {
      setHighlightIndex(-1);
    }
  };

  const handleCheckboxChange = (event) => {
    setAutoScroll(event.target.checked);
  };

  const handleCopyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(() => {}).catch((err) => {
      console.error('Error copying to clipboard:', err);
    });
  };

  const handleCopy = () => {
    handleCopyToClipboard(joinTranscriptText(transcript));
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 1000);
  };

  const handleGetClipLink = () =>{
    navigator.clipboard.writeText(clipLink).then(() => {}).catch((err) => {
      console.error('Error copying to clipboard:', err);
    });
    setIsClipCopied(true);
    setTimeout(() => {
      setIsClipCopied(false);
    }, 1000);
  };


  function joinTranscriptText(transcript) {
    return transcript && Array.isArray(transcript) ? transcript.map(item => item[1]).join(" ") : "";
  }

  useEffect(() => {
    if (isCreatingClip) {
      const interval = setInterval(() => {
        setDots(prevDots => (prevDots.length < 3 ? prevDots + '.' : ''));
      }, 500);
  
      return () => clearInterval(interval); // Cleanup interval on component unmount
    } else {
      setDots('');
    }
  }, [isCreatingClip]);

  useEffect(() => {
    if (showBubble) {
      const timer = setTimeout(() => setShowBubble(false), 5000); // Hide bubble after 5 seconds
      return () => clearTimeout(timer);
    }
  }, [showBubble]);

  return (
    <div style={{ height: 'full', borderRadius: '15px' }} onMouseUp={handleTextMouseUp}>
      <form onSubmit={handleSubmit} style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
        <input type="text" placeholder='Find Text in the Transcript' value={inputValue} onChange={handleInputChange} style={{ flexGrow: 1, backgroundColor: 'black', color: 'white', borderWidth: '1px', borderColor: '#201201', padding: '4px', marginRight: '10px' }} />
        <button type="submit" style={{ backgroundColor: 'black', color: 'white', borderColor: 'white', borderWidth: '1px', padding: '10px 20px', borderRadius: '10px' }}>Find</button>
      </form>
      <div className="transcript-area">
        <h1 style={{ fontSize: '22px' }}><strong>{title}</strong></h1>
        <div style={{ overflowY: 'auto' }}>
          {transcript && Array.isArray(transcript) &&
          (transcript.map(([time, text], index) => {
            const nextTime = index + 1 < transcript.length ? transcript[index + 1][0] : Infinity;
            const isActiveClass = isActive(time, nextTime) ? 'active' : '';
            const isSelectedClass = (makeClipMode && selectedIndices.includes(index) || (index === highlightIndex && selectedIndices.includes(index) === false)) ? 'highlighted' : '';
            return (
              <p key={index} ref={el => refs.current[index] = el}
                className={`transcript-text ${isActiveClass} ${isSelectedClass}`}
                onClick={() => handleSelectText(index)}>
                {text}
              </p>
            );
          }))}
        </div>
      </div>
      {showBubble && (
        <div className="bubble-alert">
          {bubbleText}
        </div>
      )}
      <div className="button-container nowrap" style={{display: 'flex', gap: '10px'}}>
        <button onClick={toggleMakeClipMode} disabled={isCreatingClip || transcriptLoading || transcript.length < 2 || (selectedIndices.length === 0 && makeClipMode === true)} className="button-style">
          {makeClipMode ? ('Finish Clip') : 'Make Clip'}
        </button>
        {(makeClipMode === true && isCreatingClip === false) && (
        <button onClick={() => setMakeClipMode(false)} className="button-style">
          {'Cancel'}
        </button>
        )}
        {((isCreatingClip || clipLink) && makeClipMode === false)&&
          (
            <button onClick={handleGetClipLink} disabled={isCreatingClip || transcriptLoading || transcript.length < 2} className="button-style">
              {isCreatingClip ? `Fetching Clip${dots}` : isClipCopied ? 'Copied!' : 'Clip Link'}
            </button>
          )
        }
        <button onClick={handleCopy} disabled={isCopied || transcriptLoading || transcript.length < 2} className="button-style">
          {isCopied ? 'Copied!' : 'Copy Transcript'}
        </button>
      </div>
      <div>
      <input 
            type="checkbox" 
            checked={autoScroll} 
            onChange={handleCheckboxChange} 
            style={{margin:'10px'}}
          />
        <label >
          Autoscroll
        </label>
      </div>
    </div>
  );
};

export default TranscriptCaptureComponent;
