import React from 'react';

function DemoModal({ isOpen, onNext,onClose, title, explanationText, imageUrl, buttonText }) {
  if (!isOpen) {
    return null;
  }

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50">
      <div className="bg-black p-8 w-[400px] rounded-lg shadow-lg relative" style={{borderColor:'white',borderWidth:'1px',zIndex:'1000'}}>
        {/* Close Button */}
        <button
          onClick={onClose}
          style={{ position: 'absolute', top: '10px', right: '10px' }}
          className="text-white text-lg hover:text-gray-300"
        >
          X
        </button>

        {/* Modal Title */}
        <h2 className="text-2xl font-semibold mb-4">{title}</h2>

        {/* Modal Content (Explanation Text and Image) */}
        <div className="flex flex-col items-center">
          <p className="text-center mb-4">{explanationText}</p>
          <img src={imageUrl} alt="Modal" className="max-w-full max-h-[200px] mb-4" />
        </div>

        {/* Modal Button */}
        <div className="flex justify-end">
          <button
            onClick={onNext}
            className="bg-gradient-to-r from-[#F81E1E] to-[#FFBD4D] text-white px-4 py-2 rounded-full hover:opacity-80"
          >
            {buttonText}
          </button>
        </div>
      </div>
    </div>
  );
}

export default DemoModal;
