import React, { useState, useEffect, memo } from 'react';
import { Handle, useReactFlow, useStoreApi, Position } from 'reactflow';
import './GPTNode.css';
import gpt_icon from '../../media/images/services/ChatGPT_logo.png';

const options = [
  //... your options
  { value: 'GPT3.5Turbo', label: 'GPT 3.5 Turbo' },
];

function Dropdown({ value, nodeId }) {
  const { setNodes } = useReactFlow();
  const store = useStoreApi();

  const onChange = (evt) => {
    const { nodeInternals } = store.getState();
    setNodes(
      Array.from(nodeInternals.values()).map((node) => {
        if (node.id === nodeId) {
          node.data = {
            ...node.data,
            dropdown: evt.target.value,
          };
        }
        return node;
      })
    );
  };

  return (
    <div className="custom-node__select">
      <div>GPT Model</div>
      <select className="nodrag custom_node__dropdown" onChange={onChange} value={value}>
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    </div>
  );
}

function TextInput({ initialValue, nodeId, onChange }) {
  const [inputText, setInputText] = useState(initialValue);

  useEffect(() => {
    setInputText(initialValue);
  }, [initialValue]);

  const handleInputChange = (event) => {
    setInputText(event.target.value);
    onChange(event); // Call the onChange function passed from the parent component if needed
  };

  return (
    <div className="custom-node__input">
      <div>Type Your ChatGPT Query:</div>
      <textarea 
        className="nodrag" 
        onChange={handleInputChange} 
        value={inputText} 
        rows={8}
        style={{ resize: 'none', width: '100%', color: 'black'}}
      />
    </div>
  );
}

function GPTNode({ id, data }) {
  return (
    <div className="custom-node">
      <div className="custom-node__icon">
        <img src={gpt_icon} alt="icon" />
      </div>
      <div className="custom-node__header">
        <strong>ChatGPT Request</strong>
      </div>
      <div className="custom-node__body">
        <Dropdown nodeId={id} value={data.dropdown} style={{ color: "black" }}/>
        <TextInput nodeId={id} initialValue={data.textInput} onChange={data.onChange}/>
      </div>
      <Handle type="source" position={Position.Right} id="b" />
      <Handle type="target" position={Position.Left} id="a" />
      <div className="custom-node__header">
        <p><strong>Estimated Price: </strong>{data.price ? data.price/1000 : "N/A"} sats</p>
      </div>
    </div>
  );
}

export default memo(GPTNode);
