import React, { useState, useEffect } from 'react';

const defaultPrompts = {
  Summary: "Write a short summary with enumerated bullet points of the top points covered in this podcast with the following format in markdown: \n1. TLDR section with 2 to 3 sentences \n2. Key points section with 5-10 bullet points depending on length of the podcast & amount of content. \n3. Top Quotes: 2-3 quotes of high significance \n4. Conclusion summarizing in a few sentences. \nDo not directly reference the podcast transcript.",
  Article: "Write a short blog post based on the key points from this provided podcast. Please do not directly reference the transcript itself and instead write in the tone of an opinion piece about the core ideas.",
  Quotes: "Extract the top 5 most thought provoking quotes from this talk. Be sure to remove filler words like uh, uhm , like to make the speaker sound a bit more eloquent but do not stray from the underlying verbatim intent aside from that.",
  HowTo: "Write a detailed enumerated list that explains all of the technical steps required to complete this project.",
  PromoBlog: "Write a promotional summary overview of this transcript about a video. Keep in mind this is meant to be a short form blog post on my website. It needs to be 4 to 10 paragraphs that are well structured. Try to cover all the high level details that summarize all the topics covered."
};

const PromptOptionsComponent = ({ onOptionChange, onCustomPromptChange, showOptionsOnOpen=false }) => {
  const [option, setOption] = useState('Summary');
  const [customPromptVisible, setCustomPromptVisible] = useState(false);
  const [customPrompts, setCustomPrompts] = useState({});

  useEffect(() => {
    onOptionChange(option);
    onCustomPromptChange(customPrompts[option] !== undefined ? customPrompts[option] : defaultPrompts[option]);
  }, [option, customPrompts, onOptionChange, onCustomPromptChange]);

  useEffect(() => {
    // console.log(`showOptionsOnOpen:`,showOptionsOnOpen)
    if(showOptionsOnOpen){setCustomPromptVisible(true)}
  }, []);

  const handleOptionChange = (newOption) => {
    setOption(newOption);
  };

  const handleCustomPromptChange = (event) => {
    const newPrompt = event.target.value;
    setCustomPrompts(prevPrompts => ({
      ...prevPrompts,
      [option]: newPrompt
    }));
    onCustomPromptChange(newPrompt);
  };

  const handleCustomPromptToggle = () => {
    setCustomPromptVisible(!customPromptVisible);
  };

  const getPromptValue = () => {
    if (customPrompts[option] !== undefined) {
      return customPrompts[option];
    }
    return defaultPrompts[option];
  };

  return (
    <div className="prompt-options-container">
      <div className="segmented-control">
        {Object.keys(defaultPrompts).map((opt) => (
          <button
            key={opt}
            onClick={() => handleOptionChange(opt)}
            className={option === opt ? 'active' : ''}
          >
            {opt}
          </button>
        ))}
      </div>

      <div className="custom-prompt-header" onClick={handleCustomPromptToggle}>
        <div className={`arrow ${customPromptVisible ? 'down' : 'right'}`}></div>
        <span>Custom Prompt (Optional)</span>
      </div>

      {customPromptVisible && (
        <textarea
          value={getPromptValue()}
          onChange={handleCustomPromptChange}
          placeholder="Enter your custom prompt here..."
          className="yitter-textarea"
        />
      )}
    </div>
  );
};

export default PromptOptionsComponent;