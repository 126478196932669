import React from 'react';
import ReactMarkdown from 'react-markdown';
import "./TranscriptModal.css";
import Button from '@mui/material/Button';
import remarkGfm from 'remark-gfm';
import remarkImages from 'remark-images';
import remarkCapitalize from 'remark-capitalize'
import ResizeImage from 'react-resize-image'

const renderers = {
  // Custom renderer for links
  a: ({ href, children }) => (
    <a style={{textDecoration: 'underline'}} href={href}>
      {children}
    </a>
  ),
  img: ({src, alt, title}) => (
      <ResizeImage src={'https://imgur.com/jwj0vXe'} alt={alt} title={title} width={300} height={200} />
  ),
  h1: ({children}) => (
    <h1 style={{fontSize: '2.0rem', fontWeight: 'bold', marginBottom: '1.2rem'}}>{children}</h1>
  ),
  h2: ({children}) => (
    <h2 style={{fontSize: '1.8rem', fontWeight: 'bold', marginBottom: '1.2rem'}}>{children}</h2>
  ),
  h3: ({children}) => (
    <h3 style={{fontSize: '1.6rem', fontWeight: 'italicized', marginBottom: '1.0rem'}}>{children}</h3>
  ),
  ul: ({children}) => (
    <ul style={{margin: '1.2rem'}}>{children}</ul>
  ),
  li: ({children}) => (
    <li style={{marginBottom: '.8rem'}}>- {children}</li>
  ),
  p: ({children}) => (
    <p style={{marginBottom: '.8rem'}}>{children}</p>
  )
};

const TranscriptModal = ({title, transcript, onClose, onCopy }) => {
  const [isCopied, setIsCopied] = React.useState(false);
  const isOpen = true;
  const remarkPlugins = [remarkGfm, remarkImages, remarkCapitalize ]

  const handleCopy = () => {
    onCopy(transcript);
    setIsCopied(true);

    // Reset the isCopied state after 2 seconds
    setTimeout(() => {
      setIsCopied(false);
    }, 1000);
  };

          const testMarkdown = "## Summary\n\n### TLDR\nThe transcript discusses the corruption and unethical practices within the agricultural lending system, specifically focusing on Alabama Farm Credit. The interviewee, Dustin Kittle, sheds light on how lenders manipulate borrowers and push them out of their land, leading to financial distress and potential loss of farms.\n\n### Key Points\n- Dustin Kittle exposes the unethical practices of Alabama Farm Credit, revealing how executives benefit from pushing farmers out of their land.\n- The interview highlights the lack of government intervention and the systemic issues within the agricultural lending system.\n- Kittle emphasizes the need for education among farmer borrowers to understand the risks and challenges they face.\n- The interviewee suggests that a direct relationship between farmers and consumers could help address the issues in agriculture.\n- Kittle advocates for a return to independent farming and self-sustainability to combat the exploitative practices of agricultural lenders.\n\n### Conclusion\nThe transcript reveals the disturbing reality of how farmers are being mistreated and exploited by lenders, leading to financial distress and potential loss of farms. Dustin Kittle's efforts to expose these practices and advocate for change highlight the need for transparency, education, and a shift towards independent farming practices to ensure the sustainability of agriculture. [remark plugins](https://github.com/remarkjs/remark/blob/main/doc/plugins.md#list-of-plugins)\n";

  return (
      <div className='modal'>
        <div className='modal-header-transcript'>
          <h1 className='modal-title'>{title}</h1>
          <span> 
            <Button sx={{
                fontSize: '36px', 
                color: 'white',
                display: 'flex',
                flexDirection:'row',
                padding: 0,
                alignItems: 'flex-start', 
                ":hover":{ 
                  backgroundColor: 'transparent', 
                  color: '#FFBD4D'} 
              }} onClick={onClose} >&times;</Button> </span>
        </div>
        <div className='modal-content'>
          <div className='transcript-section'>
            <ReactMarkdown children={transcript} remarkPlugins={remarkPlugins} components={renderers}/>
          </div>
            
        </div>
        <div className='modal-footer'>
          <Button className='copy-button' onClick={handleCopy} disabled={isCopied}>
                {isCopied ? 'Copied!' : 'Copy'}
          </Button>
        </div>
      </div>
  );
};

export default TranscriptModal;
