import React, { useState, useRef, useEffect } from 'react';
import './WhisperComponent.css'; 
import { run_whisper_example, run_rss_agent, createGuidFromUrl } from '../../common/nip105-client';
import { BeatLoader } from 'react-spinners';
import icon from '../../media/images/services/audio_signal.jpg';
import CompartmentalizedTranscriptModal from '../../common/components/modal/CompartmentalizedTranscriptModal';
import TranscriptModal from '../../common/components/modal/TranscriptModal';
import { ActiveTabProvider, useActiveTab } from '../../common/ActiveTabContext';

const FileInput = ({ selectedFile, setSelectedFile, onFileLoaded }) => {
  const [displayedName, setDisplayedName] = useState("");
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
    onFileLoaded(file);
    setDisplayedName(file.name);
  };

  return (
    <div className="whisper-button">
      <input type="file" onChange={handleFileChange} />
      {selectedFile && (
        <div>
          <p className="truncate">Selected File: {displayedName}</p>
        </div>
      )}
    </div>
  );
};

const WhisperComponent = ({ paymentChoiceStatus, setPaymentChoiceStatus }) => {
  const [remoteURL, setRemoteURL] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isTranscriptModalOpen, setIsTranscriptModalOpen] = useState(false);
  const [customPrompt, setCustomPrompt] = useState('');
  const [initialToggleTab,setInitialToggleTab] = useState('Transcript');
  const [isAiAnalysisOpen,setIsAiAnalysisOpen] = useState(false)
  const [aiAnalysis,setAiAnalysis] = useState('')
  const [transcriptData, setTranscriptData] = useState(null);
  const [paymentHash,setPaymentHash] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const fileInputRef = useRef(null);
  const { activeTab, setActiveTab } = useActiveTab();

  useEffect(() => {
    setRemoteURL("");
  }, [selectedFile]);

  const handleInputChange = (event) => {
    setRemoteURL(event.target.value);
  };

  const handleKeyPress = (event) => {
    setSelectedFile(null);
    if (event.key === 'Enter') {
      handleButtonClick();
    }
  };

  const checkRemoteFileDuration = async (url) => {
    return new Promise((resolve, reject) => {
      const media = new Audio(url);

      media.onloadedmetadata = () => {
        const durationInMinutes = media.duration / 60;
        if (durationInMinutes > 300) {
          reject(new Error("The media file exceeds the 300 minute duration limit."));
        } else {
          resolve(media.duration);
        }
        URL.revokeObjectURL(media.src);
      };

      media.onerror = () => {
        reject(new Error("Failed to load the media file."));
      };
    });
  };

  useEffect(() => {
    const handlePopState = () => {
      const params = new URLSearchParams(window.location.search);
      const tab = params.get('tab');
      setActiveTab(tab ? tab : null);
    };

    window.addEventListener('popstate', handlePopState);
    return () => window.removeEventListener('popstate', handlePopState);
  }, [setActiveTab]);

  const handleButtonClick = async () => {
    if (!remoteURL && !selectedFile) {
      alert("Please provide a file or a URL.");
      return;
    }

    setIsLoading(true);

    try {
      if (remoteURL) {
        await checkRemoteFileDuration(remoteURL);
      }
      performJob(remoteURL, selectedFile);
    } catch (error) {
      alert(error.message);
      setIsLoading(false);
    }
  };

  const handleFileLoaded = (file) => {
    const url = URL.createObjectURL(file);
    const audio = new Audio(url);

    audio.addEventListener('loadedmetadata', () => {
      if (audio.duration > 60 * 300) {
        alert("Audio is limited to 5 hours or less for this service. Please check back for updates for when we upgrade.");
        setSelectedFile(null);
      }
      URL.revokeObjectURL(url);
    });
  };

  const performJob = async (remoteLink, localLink) => {
    try {
      const result = await run_whisper_example(remoteLink, localLink);
      if (result.authCategory === "not-selected") {
        setPaymentChoiceStatus("choosePaymentMethod");
        setIsLoading(false);
        return;
      }
      if (result.channels?.[0]?.alternatives?.[0]?.transcript && result.paymentHash) {
        setPaymentHash(result.paymentHash)//set for later use
        setTranscriptData(result);
        setIsTranscriptModalOpen(true);
      } else {
        throw new Error('Transcript not found in the response');
      }
      setSelectedFile(null);
      setRemoteURL('');
    } catch (error) {
      alert("An error occurred during processing.");
    } finally {
      setIsLoading(false);
    }
  };

  const runAnalysisJob = async () => {
    setInitialToggleTab('AI Analysis')
    //TODO: replace with RAG module for now this is a hack
    const previousJobPaymentHash = paymentHash;
    const data = {
      previousJobPaymentHash: previousJobPaymentHash,
      customPrompt: customPrompt
    };

    
    setIsLoading(true);
    try {
      const result = await run_rss_agent(data);
      console.log(`got result:`,result)
      if (result.authCategory && result.authCategory === "not-selected") {
        setPaymentChoiceStatus("choosePaymentMethod");
      } else {
        console.log(`rss_agent result:`,result)
        setAiAnalysis(result.transcript);
        setIsAiAnalysisOpen(true)
      }
    } catch (error) {
      console.error('Error running YouTube agent:', error);
      alert('Failed to process the request. Please try again.');
    }
    setIsLoading(false);
  }

  return (
    <div className="whisper-container">
      <div className="whisper-header-container">
        <div className="whisper__icon">
          <img src={icon} alt="icon" />
        </div>
        <h1 className="whisper-title">Voice to Text: Upload or Provide Link to Audio/Video Files for Transcript</h1>
      </div>
          {isTranscriptModalOpen && transcriptData &&(
            <CompartmentalizedTranscriptModal
              title="Media Transcript"
              transcriptData={transcriptData}
              onClose={() => setIsTranscriptModalOpen(false)}
              onCopy={(text) => navigator.clipboard.writeText(text)}
              onRunAnalysis={runAnalysisJob}
              setCustomPrompt={setCustomPrompt}
              showOptionsOnOpen={true}
              initialToggleTab={initialToggleTab}
              isLoading={isLoading}
            />
          )}
          {isAiAnalysisOpen && (
            <TranscriptModal
              title={"Summary"}
              transcript={aiAnalysis}
              onClose={() => setIsAiAnalysisOpen(false)}
              onCopy={(text) => navigator.clipboard.writeText(text)}
            />
          )}
       <div className="whisper-input-button-container">
        <input 
          type="text" 
          value={remoteURL}
          onChange={handleInputChange}
          onKeyPress={handleKeyPress}
          placeholder="Enter your file URL here"
          className="whisper-input"
        />
        <div className="button-container">
          <button 
            onClick={handleButtonClick}
            className="whisper-button"
            disabled={isLoading}
          >
            {isLoading ? (
              <BeatLoader color="white" size={8} loading={true} />
            ) : (
              <p>Transcribe</p>
            )}
          </button>
          {!isLoading && (
            <FileInput selectedFile={selectedFile} setSelectedFile={setSelectedFile} ref={fileInputRef} onFileLoaded={handleFileLoaded} />
          )}
        </div>
      </div>
    </div>
  );
};

export default WhisperComponent;