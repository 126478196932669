import React, { useState } from 'react';
import Button from '@mui/material/Button'; // For the toggle button
import './QuotesDisplayComponent.css';
import { run_make_clips } from '../nip105-client';
import { BeatLoader } from 'react-spinners';

function formatTime(seconds) {
  const floored = Math.floor(seconds);
  const h = Math.floor(floored / 3600);
  const m = Math.floor((floored % 3600) / 60);
  const s = floored % 60;
  return [h, m > 9 ? m : (h ? '0' + m : m || '0'), s > 9 ? s : '0' + s].filter(Boolean).join(':');
}

const QuotesDisplayComponent = ({setPaymentChoiceStatus,videoTitle, quotes, videoId, onPreview, onClickText, onClipFinish }) => {
  const [startTime, setStartTime] = useState({});
  const [endTime, setEndTime] = useState({});
  const [loadingStates, setLoadingStates] = useState(Array(quotes.length).fill(false));

  const handleStartTimeChange = (index, increment) => {
    setStartTime(prev => {
      const newTime = (prev[index] !== undefined ? prev[index] : quotes[index].start) + increment;
      const endTimeValue = endTime[index] !== undefined ? endTime[index] : quotes[index].end;
      return { ...prev, [index]: newTime < 0 ? 0 : newTime >= endTimeValue ? endTimeValue - 1 : newTime };
    });
  };

  const handleEndTimeChange = (index, increment) => {
    setEndTime(prev => {
      const newTime = (prev[index] !== undefined ? prev[index] : quotes[index].end) + increment;
      const startTimeValue = startTime[index] !== undefined ? startTime[index] : quotes[index].start;
      return { ...prev, [index]: newTime <= startTimeValue ? startTimeValue + 1 : newTime };
    });
  };

  const handlePreviewClick = (index) => {
    const start = startTime[index] !== undefined ? startTime[index] : quotes[index].start;
    const end = endTime[index] !== undefined ? endTime[index] : quotes[index].end;
    onPreview(start, end);
  };

  const handleTextClick = (index) => {
    const start = startTime[index] !== undefined ? startTime[index] : quotes[index].start;
    onClickText(start);
  }

  const handleMakeClipClick = async (index) => {
    setLoadingStates(prev => prev.map((item, idx) => idx === index ? true : item));
  
    const start = startTime[index] !== undefined ? startTime[index] : quotes[index].start;
    const end = endTime[index] !== undefined ? endTime[index] : quotes[index].end;
    const segment = [{ "start": start, "end": end }];
  
    try {
      const response = await run_make_clips(videoId, segment);
      const clipUrl = response.resultUrl; // Directly using the correct key

      if (response.authCategory && response.authCategory === "not-selected") {
        setPaymentChoiceStatus("choosePaymentMethod");
        setLoadingStates(prev => prev.map((item, idx) => idx === index ? false : item));
        return;
      }
  
      if (clipUrl) {
        const clipData = {
          date: new Date().toISOString(),
          url: clipUrl, // Storing the actual URL
          transcript: quotes[index].quote,
          videoTitle: videoTitle
        };
        const existingClips = JSON.parse(localStorage.getItem('archivedClips') || '[]');
        localStorage.setItem('archivedClips', JSON.stringify([...existingClips, clipData]));
        onClipFinish();
        console.log(`Clip link stored: ${clipUrl}`);
      }
    } catch (error) {
      console.error('Failed to make clip:', error);
    } finally {
      setLoadingStates(prev => prev.map((item, idx) => idx === index ? false : item));
    }
  };
  
  
  
  

  return (
    <div className="quotes-display-container" style={{ borderWidth: '1px', borderColor: '#201201', margin: '4px', maxHeight: '55vh', overflowY: 'auto' }}>
      <h1>Top Quotes Clips</h1>
      <ul>
        {quotes.map((quote, index) => (
          <li key={index} style={{ border: '1px solid #201201', padding: '10px', marginBottom: '10px', borderRadius: '8px', backgroundColor: 'black', color: '#FFBD4D', fontFamily: 'Courier New, Courier, monospace' }}>
            <p className="quote-text" onClick={() => handleTextClick(index)}>
              "{quote.quote}"
            </p>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: '10px' }}>
              <div>
                <label>Start: </label>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <span style={{ margin: '0 5px' }}>{formatTime(startTime[index] !== undefined ? startTime[index] : quotes[index].start)}</span>
                  <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <button onClick={() => handleStartTimeChange(index, 1)}>&#9650;</button>
                    <button onClick={() => handleStartTimeChange(index, -1)}>&#9660;</button>
                  </div>
                </div>
              </div>
              <div>
                <label>End: </label>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <span style={{ margin: '0 5px' }}>{formatTime(endTime[index] !== undefined ? endTime[index] : quotes[index].end)}</span>
                  <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <button onClick={() => handleEndTimeChange(index, 1)}>&#9650;</button>
                    <button onClick={() => handleEndTimeChange(index, -1)}>&#9660;</button>
                  </div>
                </div>
              </div>
              {/* <Button onClick={() => handlePreviewClick(index)} sx={{
                padding: '10px',
                width: '80px',
                border: '2px solid #FFA500',
                borderRadius: '4px',
                cursor: 'pointer',
                background: 'transparent',
                color: 'white',
                fontWeight: 'bold',
                margin: '2px',
                '&:hover': {
                  background: '#FFA500',
                  color: 'black'
                }
              }}>Preview</Button> */}
              <Button onClick={() => handleMakeClipClick(index)} 
                disabled={loadingStates[index]}
                sx={{
                  padding: '10px',
                  width: '100px',
                  border: '2px solid #FFA500',
                  borderRadius: '4px',
                  cursor: 'pointer',
                  background: 'transparent',
                  color: 'white',
                  fontWeight: 'bold',
                  margin: '2px',
                  whiteSpace: 'nowrap', // Ensures text does not wrap
                  '&:hover': {
                    background: '#FFA500',
                    color: 'black'
                  }
              }}>
                {loadingStates[index] ? <BeatLoader color="white" size={8} /> : 'Make Clip'}
              </Button>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default QuotesDisplayComponent;
