import React from 'react';
import CreditCardIcon from '../../../media/logos/CreditCardIcon';
import LightningIcon from '../../../media/logos/LightningIcon';


function ChoosePaymentModal({ isOpen, onCardSelected,onLNSelected, onClose }) {
  if (!isOpen) {
    return null;
  }

  // Common button style
  const buttonStyle = {
    display: 'flex', // Use flex to align items
    justifyContent: 'center', // Center content
    alignItems: 'center',
    width: '200px', // Equal width for all buttons
    padding: '10px',
    margin: '10px 0',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    position: 'relative', // To position the SVG absolutely within the button
  };

  // Styles for the SVG to position it on the right
  const svgStyle = {
    position: 'absolute', // Absolute position to place it on the right
    right: '10px', // Distance from the right edge of the button
    width: '24px',
    height: '24px',
  };

  // Adjusted styles for each button's background color
  const lightningButtonStyle = {
    ...buttonStyle,
    backgroundColor: '#FFA500',
  };

  const cardButtonStyle = {
    ...buttonStyle,
    backgroundColor: '#6BCB77',
  };

  const iconStyle = {
    fill: 'white', // Set the SVG fill color to white
    width: '15px', // Set the width of the SVG
    height: '15px', // Set the height of the SVG
    position: 'absolute', // Absolute position to place it on the right
    right: '20px', // Distance from the right edge of the button
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50" style={{ zIndex: 9999 }}>
      <div className="bg-black p-8 w-[400px] rounded-lg shadow-lg relative" style={{ borderColor: 'white', borderWidth: '1px', zIndex: '1000' }}>
        {/* Close Button */}
        <button onClick={onClose} style={{ position: 'absolute', top: '10px', right: '10px', color: 'white', background: 'none', border: 'none', cursor: 'pointer' }}>X</button>

        {/* Modal Title */}
        <h2 className="text-2xl font-semibold mb-4 text-white">Choose Payment Method to Continue</h2>

        {/* Button Container */}
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          {/* Card Button */}
          <button onClick={onCardSelected} style={cardButtonStyle}>
            Card
            <CreditCardIcon style={iconStyle} />
          </button>

          {/* Lightning Button */}
          <button onClick={onLNSelected} style={lightningButtonStyle}>
            Lightning
            <LightningIcon style={iconStyle} />
          </button>
        </div>
      </div>
    </div>
  );
}

export default ChoosePaymentModal;
