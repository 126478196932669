import React, { useState, useEffect, useRef } from 'react';
import ReactPlayer from 'react-player';
import { Paper, IconButton, Slider, Typography, Box, Tooltip } from '@mui/material';
import { PlayArrow, Pause, VolumeUp, CloudDownload, Work } from '@mui/icons-material';
import { getYoutubeVideoId, getVideoDetails, getVideoTimestampedTranscript} from '../nip105-client';
import Button from '@mui/material/Button';
import { BeatLoader } from 'react-spinners';
import CustomAlert from './CustomAlert';


function VideoAlert({isOpen,message,affirmativeButtonText,closeAction}) {

  const button1 = {
    text: affirmativeButtonText,
    action: () => {
      console.log('closeAction is:', typeof closeAction); // Check if it's a function
      if (typeof closeAction === 'function') {
        closeAction(); // Hide the alert if it's a function
      } else {
        console.error('closeAction is not a function'); // Log an error if it's not
        console.log(typeof closeAction )
      }
    },
  };


  return (
    <div>      
      {isOpen && (
        <CustomAlert 
          message={message}
          button1={button1} 
          // button2={button2} 
        />
      )}
    </div>
  );
}

function VideoPlayer({ 
  url, 
  ytUrl, 
  showTranscript,
  transcriptVisible, 
  seekToTime,
  handleTimeUpdate,
  isPaused,
  transcriptLoading,
  hideTranscript,
  loopStart,
  loopEnd,
  shouldLoop,
  currentVideoTitle,
  setCurrentVideoTitle
}) {
  const [playing, setPlaying] = useState(false);
  const [volume, setVolume] = useState(0.8);
  const [played, setPlayed] = useState(0);
  const [seeking, setSeeking] = useState(false);
  const [duration, setDuration] = useState(0);
  const [showIcon, setShowIcon] = useState(false);
  const [currentVideoId, setCurrentVideoId] = useState("");
  
  const playerRef = useRef(null);
  const [expiryTime, setExpiryTime] = useState(null);
  const [isExpiryAlertShown, setIsExpiryAlertShown] = useState(false);
  

  useEffect(() => {
    if (!shouldLoop && playerRef.current) {
      playerRef.current.seekTo(loopEnd, 'seconds'); // Jump to end of loop when stopping
    }
  }, [shouldLoop, loopEnd]);

  useEffect(() => {
    const fetchVideoDetails = async () => {
      try {
        const videoId = getYoutubeVideoId(ytUrl); // Ensure ytUrl is correctly sourced, possibly from props or state
        console.log("got videoId", videoId);
        
        if (!videoId) {
          throw new Error('Invalid video ID'); // Handle cases where videoId is not retrieved
        }
  
        const details = await getVideoDetails(videoId);
        console.log(`videoDetails:`, details);
        
        setCurrentVideoId(videoId);  // Assuming setCurrentVideoId is a state setter function
        setCurrentVideoTitle(details.data.videoTitle); // Assuming setCurrentVideoTitle is a state setter function
      } catch (error) {
        console.error("Error setting YouTube video ID:", error);
      }
    };
  
    fetchVideoDetails();
  }, [ytUrl]);

  useEffect(() => {
    if (url) {
      const newUrl = new URL(url);
      const expiryTimestamp = newUrl.searchParams.get('expire');
      if (expiryTimestamp) {
        setExpiryTime(new Date(parseInt(expiryTimestamp, 10) * 1000));
      } else {
        setExpiryTime(null);
      }
    }
  }, [url]);

  useEffect(() =>{
    try{
      if(currentVideoTitle){
        console.log("currentVideoTitle set:",currentVideoTitle)
      }
    }
    catch(error){
      console.log("error setting youtubeVideoId")
    }
  },[currentVideoTitle])

  useEffect(() => {
    if (playerRef.current && seekToTime !== null && !isNaN(seekToTime)) {
      playerRef.current.seekTo(parseFloat(seekToTime), 'seconds');
      setPlaying(true)
    }
  }, [seekToTime]); // Depend on seekToTime to trigger seek action

  useEffect(() => {
    console.log(`isPaused changed: ${isPaused}`); // Log to check if entering the effect
    if (isPaused) {
      console.log('Pausing video...'); // Confirm this code runs
      setPlaying(false);
    }
  }, [isPaused]);
  
  
  const togglePlayPause = () => {
    if (expiryTime && new Date() > expiryTime) {
      console.error('Video link has expired');
      setPlaying(false)
      setIsExpiryAlertShown(true);
      return;
    }
    else{
      console.log(`expiryTime is:${expiryTime} and current time is:${new Date()}`)
    }
    setPlaying(!playing);
    setShowIcon(true);
    setTimeout(() => setShowIcon(false), 500); // Hide icon after 500ms
  };

  const handleVolumeChange = (event, newValue) => {
    setVolume(parseFloat(newValue));
  };

  const handleProgress = (progress) => {
    if (shouldLoop && playerRef.current) {
      const playedSeconds = playerRef.current.getCurrentTime();
      if (playedSeconds >= loopEnd) {
        playerRef.current.seekTo(loopStart, 'seconds');
      }
    }

    const playedTime = progress.played * duration;
    if(playedTime){
      handleTimeUpdate(playedTime);
    }
    setPlayed(progress.played);
  };

  const handleSeekChange = (event, newValue) => {
    setPlayed(parseFloat(newValue));
  };

  const handleSeekMouseDown = () => {
    setSeeking(true);
  };

  const handleSeekMouseUp = (event, newValue) => {
    setSeeking(false);
    playerRef.current.seekTo(newValue, 'fraction');
  };

  const handleDuration = (duration) => {
    setDuration(duration);
  };

  return (
    <Paper elevation={3} sx={{ 
        position: 'relative', 
        padding: '20px', 
        backgroundColor: '#202021', 
        color: '#FFF', 
        aspectRatio: '16 / 9',
        maxHeight: '50vh !important',
        maxWidth: transcriptVisible ? '60vw': '72vw',
        margin: 'auto',
        marginBottom: transcriptVisible ? '0px' : '60px',
        boxShadow: '0px 0px 12px rgba(200, 200, 200, 0.5)',
        border: '1px solid #D3D3D3',
        borderRadius: '12px',
        '@media (max-width: 900px)': {
          width: '95vw', // Width for screens smaller than 900px
        },
      }}>
       {isExpiryAlertShown ? (
        <VideoAlert
          isOpen={isExpiryAlertShown}
          message={"Video Link Expired. Please request a new one."}
          affirmativeButtonText={"OK"}
          closeAction={() => setIsExpiryAlertShown(false)}
        />
      ) : (
        <>
          <Box
            onClick={togglePlayPause}
            sx={{
              position: 'relative',
              cursor: 'pointer',
              margin: 'auto',
            }}
          >
            <ReactPlayer
              ref={playerRef}
              url={url}
              playing={playing}
              volume={volume}
              onProgress={handleProgress}
              progressInterval={250}
              onDuration={handleDuration}
              width="100%"
              height="100%"
              style={{ pointerEvents: 'none', margin: 'auto', border: '1px solid #2E2E2E' }}
            />
            {showIcon && (
              <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', backgroundColor: 'rgba(32, 32, 32, 0.60)' }}>
                {playing ? <PlayArrow sx={{ fontSize: '12rem', color: '#FFF' }} /> : <Pause sx={{ fontSize: '12rem', color: '#FFF' }} />}
              </Box>
            )}
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', gap: 2, padding: '10px' }}>
            <VolumeUp sx={{ color: '#FFF' }} />
            <Typography variant="subtitle2" color="#FFF" sx={{ minWidth: 35 }}>
              {Math.round(volume * 100)}%
            </Typography>
            <Slider
              min={0}
              max={1}
              step={0.01}
              value={volume}
              onChange={handleVolumeChange}
              sx={{ width: 200, color: '#FFF', '& .MuiSlider-track': { color: '#f57c38' } }}
            />
            <Tooltip title={currentVideoTitle || 'Loading...'}>
              <Typography
                variant="subtitle1"
                sx={{
                  flexGrow: 1,
                  marginRight: 2,
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis'
                }}
              >
                {currentVideoTitle || 'Loading...'}
              </Typography>
            </Tooltip>
            <IconButton
              sx={{ color: '#FFF' }}
              component="a"
              href={url}
              target="_blank"
              rel="noopener noreferrer"
            >
              <CloudDownload />
            </IconButton>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <IconButton onClick={togglePlayPause} sx={{ color: '#FFF' }}>
              {playing ? <Pause /> : <PlayArrow />}
            </IconButton>
            <Typography variant="subtitle2" color="#FFF" sx={{ minWidth: 50 }}>
              {formatTime(played * duration)}
            </Typography>
            <Slider
              min={0}
              max={1}
              step={0.01}
              value={played}
              onChange={handleSeekChange}
              onMouseDown={handleSeekMouseDown}
              onChangeCommitted={handleSeekMouseUp}
              sx={{ flexGrow: 1, color: '#FFF', '& .MuiSlider-track': { color: '#f57c38' }, '& .MuiSlider-rail': { color: '#FFF' } }}
            />
            <Typography variant="subtitle2" color="#FFF" sx={{ minWidth: 50 }}>
              {formatTime(duration)}
            </Typography>
            <Button onClick={transcriptVisible ? hideTranscript : showTranscript} sx={{
              padding: '15px 15px',
              width: '180px',
              border: '2px solid #FFA500', // Fluorescent orange border
              borderRadius: '4px',
              cursor: 'pointer',
              background: 'transparent', // Clear background
              color: 'white',
              fontWeight: 'bold',
              '&:hover': {
                background: '#FFA500', // Fluorescent orange background on hover
                color: 'black', // Text color changes to black on hover
              }
            }}>
              {transcriptVisible ? 'Collapse' : <span>AI Tools🛠️</span>}
            </Button>
          </Box>
        </>
      )}
    </Paper>
  );
}

function formatTime(seconds) {
  const floored = Math.floor(seconds);
  const h = Math.floor(floored / 3600);
  const m = Math.floor((floored % 3600) / 60);
  const s = floored % 60;
  return [h, m > 9 ? m : (h ? '0' + m : m || '0'), s > 9 ? s : '0' + s].filter(Boolean).join(':');
}

export default VideoPlayer;
