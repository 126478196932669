import 'tailwindcss/tailwind.css';
import React, { useState, useEffect } from 'react';
import './styles.css'; // Import your CSS file

import NavbarWithMegaMenu from './common/components/menus/NavbarWithMegaMenu';
import { ActiveTabProvider, useActiveTab } from './common/ActiveTabContext';
import { LogicAppTabProvider, useLogicAppTab } from './services/LogicApp/LogicAppTabContext';
import LogicAppView from './services/LogicApp/LogicAppView';

import SDComponent from './services/StableDiffusion/SDComponent';
import ChatComponent from './services/GPT/ChatComponent';
import YitterComponent from './services/Yitter/YitterComponent';
import WhisperComponent from './services/Whisper/WhisperComponent';
import YTAgentComponent from './services/YTAgent/YTAgentComponent';
import CHADbotComponent from './services/CHADbot/CHADbotComponent';
import UpgradePage from './pages/IntroPage/UpgradePage';
import { getQueuedJob, resetQueuedJob } from './common/nip105-client';
import PaymentChoiceComponent from './common/components/paymentChoiceComponent';

import IntroPageQ42024 from './pages/IntroPage/IntroPageQ42024';

const oldUI = false;

function getInitialTabFromQueryString() {
  const params = new URLSearchParams(window.location.search);
  const tab = params.get('tab');
  const wfid = params.get('wfid'); // Extract wfid from the query string
  return { tab: tab ? tab : null, wfid: wfid ? wfid : null }; // Return both tab and wfid
}

const App = ({ wfid }) => {

  const { activeTab,setActiveTab } = useActiveTab();
  const [paymentChoiceStatus, setPaymentChoiceStatus] = useState(null);

  //DEBUG ONLY
  // useEffect(() => {
  //   console.log(`activeTab:${activeTab}`)
  // }, [activeTab]);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const tab = params.get('tab');

    if (tab !== null) {
      // Assuming you have a way to set the active tab based on this number
      // setActiveTab(tab); or similar logic to load the appropriate content
      console.log(`Tab selected: ${tab}`);
    }

  }, []);

  return (
    <div className="App" style={{backgroundColor: 'black'}}>
      {activeTab !== null ? (
        <div>
          <PaymentChoiceComponent paymentChoiceStatus={paymentChoiceStatus} setPaymentChoiceStatus={setPaymentChoiceStatus} />
          {activeTab != 'demo' && <NavbarWithMegaMenu paymentChoiceStatus={paymentChoiceStatus} setPaymentChoiceStatus={setPaymentChoiceStatus} />}
          {(activeTab === 0 || activeTab === 'Logic App' || activeTab === 'demo') && <LogicAppView wfid={wfid} paymentChoiceStatus={paymentChoiceStatus} setPaymentChoiceStatus={setPaymentChoiceStatus}/>}
          {(activeTab === 1 || activeTab === "GPT") && <ChatComponent paymentChoiceStatus={paymentChoiceStatus} setPaymentChoiceStatus={setPaymentChoiceStatus} />}
          {(activeTab === 2 || activeTab === "yitter") && <YitterComponent paymentChoiceStatus={paymentChoiceStatus} setPaymentChoiceStatus={setPaymentChoiceStatus}/>}
          {(activeTab === 3 || activeTab === "yt-agent") && <YTAgentComponent paymentChoiceStatus={paymentChoiceStatus} setPaymentChoiceStatus={setPaymentChoiceStatus} />}
          {(activeTab === 4 || activeTab === "chadbot") && <CHADbotComponent paymentChoiceStatus={paymentChoiceStatus} setPaymentChoiceStatus={setPaymentChoiceStatus}/>}
          {(activeTab === 5 || activeTab === "whisper") && <WhisperComponent paymentChoiceStatus={paymentChoiceStatus} setPaymentChoiceStatus={setPaymentChoiceStatus}/>}
          {(activeTab === 6 || activeTab === "SD") && <SDComponent paymentChoiceStatus={paymentChoiceStatus} setPaymentChoiceStatus={setPaymentChoiceStatus}/>}
          {(activeTab === 7 || activeTab === "upgrade") && <UpgradePage />}
        </div>
      ) : (
        <IntroPageQ42024 />
      )}
    </div>
  );
};

const RootApp = () => {
  const { tab, wfid } = getInitialTabFromQueryString(); // Destructure to get tab and wfid

  return (
    <LogicAppTabProvider>
      <ActiveTabProvider initialActiveTab={tab}>
        <App wfid={wfid} /> {/* Pass wfid as a prop to App */}
      </ActiveTabProvider>
    </LogicAppTabProvider>
  );
};


export default RootApp;
