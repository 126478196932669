import React from 'react';

const AlertModal = ({ isOpen, onClose, alertText, buttonText }) => {
  if (!isOpen) {
    return null;
  }

  const modalStyle = {
    display: 'block', // isOpen is checked above, so it's always 'block' when rendered
    position: 'fixed',
    top: '0',
    left: '0',
    width: '100%',
    height: '100%',
    background: 'rgba(0, 0, 0, 0.5)',
    zIndex: '9999',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  };

  const modalContentStyle = {
    background: 'white', // Set to white background
    color: 'black', // Set text color to black
    margin: 'auto',
    padding: '20px',
    width: '80%', // Adjust for closer to 1:1 aspect ratio based on content
    maxWidth: '300px', // Limit max width to not exceed screen width on mobile
    aspectRatio: '3 / 1', // Set an aspect ratio close to 1:1
    borderRadius: '10px', // Rounded corners
    textAlign: 'center',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)', // Soft shadow for depth
    display: 'flex',
    flexDirection: 'column', // Stack elements vertically
    justifyContent: 'center', // Center content vertically
    alignItems: 'center', // Center content horizontally
  };

  const buttonStyle = {
    marginTop: '20px',
    padding: '10px 20px',
    backgroundColor: '#FFA500', // Orange background
    color: 'white',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    fontSize: '16px',
  };

  return (
    <div style={modalStyle}>
      <div style={modalContentStyle}>
        <p style={{ marginBottom: '20px' }}>{alertText}</p> {/* Add margin to separate text from button */}
        <button style={buttonStyle} onClick={onClose}>{buttonText}</button>
      </div>
    </div>
  );
};

export default AlertModal;
