import React, { useState, useEffect, useRef } from 'react'; // Added useRef
import './LogCaptureComponent.css';

const LogCaptureComponent = ({title, setWorkflowResult}) => {
  const [logs, setLogs] = useState([]);
  //const [workflowResult,setWorkflowResult] = useState(null);
  const logsEndRef = useRef(null); // Ref to the end of logs container

  useEffect(() => {
    const originalLog = console.log;

    console.log = (...args) => {
      const logText = args.join(' ');
      originalLog.apply(console, ['Received:', logText]);

      const result = logText.match(/Stable Diffusion Result (https:\/\/[^\s]+)/);
      if (result && result[1]) {
        const url = result[1];
        originalLog.apply(console, ['Attempting to open URL:', url]);
        setWorkflowResult(url);
        window.open(url, '_blank');
      }

      setLogs((prevLogs) => [...prevLogs, logText]);
    };

    return () => {
      console.log = originalLog;
    };
  }, []);



  useEffect(() => {
    if (logsEndRef.current) {
      logsEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [logs]); // Added new useEffect to handle autoscroll

  return (
    <div className="log-capture-component">
      <h1 style={{fontSize:'22px'}}><strong>{title}</strong></h1>
      <div style={{ overflowY: 'hidden', maxHeight: 'calc(100vh - 50px)' }}> {/* adjusted style for scrolling */}
        <ul>
          {logs.map((log, index) => (
            <li key={index} style={{ wordWrap: 'break-word' }}>{log}</li>
          ))}
          <div ref={logsEndRef} /> {/* placed at the end of logs container to enable autoscrolling */}
        </ul>
      </div>
    </div>
  );
};

export default LogCaptureComponent;