import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import { Tooltip } from '@mui/material';
import './ImageHistoryComponent.css';

const ImageHistoryComponent = ({ onImageCopy }) => {
  const [images, setImages] = useState([]);

  useEffect(() => {
    const handleStorageUpdate = () => {
      let storedImages = JSON.parse(localStorage.getItem('imageRequests') || '[]');
      storedImages.sort((a, b) => new Date(b.date) - new Date(a.date));
      setImages(storedImages);
    };

    // Initially load images
    handleStorageUpdate();

    // Setup event listener for storage updates
    window.addEventListener('onImageStorageUpdated', handleStorageUpdate);

    // Clean up the event listener
    return () => window.removeEventListener('onImageStorageUpdated', handleStorageUpdate);
  }, []);

  const handleCopyImageUrl = (url) => {
    navigator.clipboard.writeText(url).then(() => {
      onImageCopy();
    }).catch(err => console.error('Failed to copy image URL: ', err));
  };

  return (
    <div className="image-history-sidebar">
      {images.length > 0 ? (
        images.map((image, index) => (
          <div key={index} className="image-history-item">
            <img src={image.thumbnail} alt="Thumbnail" className="image-history-thumbnail"/>
            <div className="image-history-info">
              <strong>{new Date(image.date).toLocaleString()}</strong>
              <p>{image.prompt}</p>
              <button className='copy-button' onClick={() => handleCopyImageUrl(image.url)}>Copy URL</button>
            </div>
          </div>
        ))
      ) : (
        <div className="empty-history">
          <h1 style={{textAlign:'center',padding:'16px', fontSize:'26px'}}>No Images Generated Yet. Image history will appear here as you generate them.</h1>
        </div>
      )}
    </div>
  );
};

export default ImageHistoryComponent;
