import React, { memo, useState, useEffect, useRef } from 'react';
import { Handle, useReactFlow, useStoreApi, Position } from 'reactflow';
import '../GPT/GPTNode.css';
import'./Image2TextNode.css';
import sd_icon from '../../media/images/misc/camera_emoji.png'
import options from '../../common/constants'

function FileInput({ selectedFile, setSelectedFile }) {
  const handleFileChange = (event) => {
    //console.log(typeof setSelectedFile);
    const file = event.target.files[0]; // Get the first selected file
    // Check if the file type is accepted
    if (file && ['image/jpeg', 'image/png', 'image/webp', 'image/gif', 'image/bmp'].includes(file.type)) {
      setSelectedFile(file);
      //console.log('Selected File:', file);
    } else {
      // Reset the file input and alert the user
      event.target.value = ''; // to allow the same file to be selected again if needed
      alert('Unsupported file type. Please select an image file (jpeg, png, webp, gif, bmp).');
    }
  };

  return (
    <div className="image2text-button">
      <input 
        type="file" 
        onChange={handleFileChange} 
        accept="image/jpeg, image/png, image/webp, image/gif, image/bmp"
      />
      {selectedFile && (
        <div>
          <p>Selected File: {selectedFile.name}</p>
          {/* You can display more file information here */}
        </div>
      )}
    </div>
  );
}

function Image2TextNode({ id, data }) {
  var icon = sd_icon;
  const [priceLabel, setPriceLabel] = useState('N/A');
  //const [selectedFile, setSelectedFile] = useState(null);
  const fileInputRef = useRef(null);

  useEffect(() => {
    if(data.price){
      // setPriceLabel(
      //   data.price
      //   ? `${data.price.price_fixed/1000} sats + ${data.price.price_variable/1000} sats/MIN`
      //   : "N/A"
      // )
      setPriceLabel(`${data.price/1000} sats`)
    }
  }, [data]);

  return (
    <div className="custom-node">
      <div className="custom-node__icon">
        <img src={icon} alt="icon" />
      </div>
      <div className="custom-node__header" style={{ paddingRight: '30px' }}>
        <strong>Image2Text</strong>
        <FileInput selectedFile={data.selectedFile} setSelectedFile={data.setSelectedFile} ref={fileInputRef}/>
      </div>
      <Handle type="target" position={Position.Left} id="a" />
      <Handle type="source" position={Position.Right} id="a" />
      <div className="custom-node__header">
        <p>
          <strong>Estimated Price: </strong>
          {priceLabel}
        </p>
      </div>
    </div>
  );
}





export default memo(Image2TextNode);
