import React, { createContext, useContext, useState } from 'react';

const ActiveTabContext = createContext();

export function ActiveTabProvider({ children, initialActiveTab }) {
  const [activeTab, setActiveTab] = useState(initialActiveTab);
  //console.log("initialActiveTab:",initialActiveTab);
  return (
    <ActiveTabContext.Provider value={{ activeTab, setActiveTab }}>
      {children}
    </ActiveTabContext.Provider>
  );
}


export function useActiveTab() {
  const context = useContext(ActiveTabContext);
  if (!context) {
    throw new Error('useActiveTab must be used within an ActiveTabProvider');
  }
  return context;
}
