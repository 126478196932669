import React, { useState , useEffect} from 'react';
import { IconButton, Box } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

const ToolsTabsComponent = ({ toolsTabs, setToolsTabs, activeToolsTab, setActiveToolsTab }) => {
  const [scrollIndex, setScrollIndex] = useState(0);

  useEffect(() => {
    if (activeToolsTab < scrollIndex) {
      setScrollIndex(activeToolsTab);
    } else if (activeToolsTab >= scrollIndex + 3) {
      setScrollIndex(activeToolsTab - 2);
    }
  }, [activeToolsTab, scrollIndex]);

  const handleRemoveToolsTab = (index) => {
    if (index > 0) { // Prevent closing the first tab
      const newToolsTabs = toolsTabs.filter((_, i) => i !== index);
      setToolsTabs(newToolsTabs);
      if (activeToolsTab === index) {
        setActiveToolsTab(0); // Set active to the first tab if the current active is closed
      }
    }
  };

  const scrollLeft = () => {
    if (activeToolsTab > 0) {
      setActiveToolsTab(activeToolsTab - 1);
      if (activeToolsTab - 1 < scrollIndex) {
        setScrollIndex(scrollIndex - 1);
      }
    }
  };

  const scrollRight = () => {
    if (activeToolsTab < toolsTabs.length - 1) {
      setActiveToolsTab(activeToolsTab + 1);
      if (activeToolsTab + 1 >= scrollIndex + 3) {
        setScrollIndex(scrollIndex + 1);
      }
    }
  };

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', overflow: 'hidden', background: 'clear', padding: '5px' }}>
      <IconButton
        onClick={scrollLeft}
        disabled={activeToolsTab === 0}
        style={{ color: activeToolsTab === 0 ? 'rgba(255, 255, 255, 0.5)' : 'white' }}
      >
        <ArrowBackIosIcon />
      </IconButton>
      {toolsTabs.slice(scrollIndex, scrollIndex + 3).map((tab, index) => (
        <Box key={index} sx={{
          padding: '10px 15px',
          display: 'flex',
          alignItems: 'center',
          backgroundColor: activeToolsTab === scrollIndex + index ? 'rgba(255, 140, 0, 0.75)' : 'transparent',
          color: 'white',
          borderRadius: '5px 5px 0 0',
          border: '2px solid #FF8C00',
          borderBottom: 'none',
          marginRight: '8px',
          cursor: 'pointer',
          '&:hover': {
            backgroundColor: 'rgba(255, 140, 0, 0.85)',
          }
        }}>
          <span onClick={() => setActiveToolsTab(scrollIndex + index)} style={{ flexGrow: 1 }}>
            {tab.title}
          </span>
          {scrollIndex + index !== 0 && scrollIndex + index !== 1 && (
            <IconButton onClick={() => handleRemoveToolsTab(scrollIndex + index)} size="small">
              <CloseIcon fontSize="small" />
            </IconButton>
          )}
        </Box>
      ))}
      { toolsTabs.length > 2 && (
        <IconButton
        onClick={scrollRight}
        disabled={activeToolsTab === toolsTabs.length - 1}
        style={{ color: activeToolsTab === toolsTabs.length - 1 ? 'rgba(255, 255, 255, 0.5)' : 'white' }}
      >
        <ArrowForwardIosIcon />
      </IconButton>
      )
      }
      
    </Box>
  );
};

export default ToolsTabsComponent;
