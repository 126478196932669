import axios from 'axios';
import { getAuthHeader } from '../common/nip105-client';

// Function to strip HTML tags
const stripHtml = (html) => {
  const tempDiv = document.createElement('div');
  tempDiv.innerHTML = html;
  return tempDiv.textContent || tempDiv.innerText || '';
};

const formatDescription = (description, maxLength = 1000) => {
  // Strip HTML tags
  let plainText = stripHtml(description);

  // Trim the text to the desired length
  if (plainText.length > maxLength) {
    plainText = plainText.slice(0, maxLength) + '...';
  }

  return plainText;
};

async function fetchAndParseFeeds(searchString) {
  try {
    let headers = {};
    const authHeader = getAuthHeader();
    console.log("got authHeader for POST:", authHeader);
    if (authHeader) {
      headers['Authorization'] = authHeader;
    }
    const requestData = {
      podcastName: searchString,
    };
    const response = await axios.post('https://rss-extractor-app-yufbq.ondigitalocean.app/searchFeeds', requestData, { headers });
    console.log("response:", response);
    const feeds = response.data.data.feeds.map(feed => {
      return {
        id: feed.id,
        title: feed.title,
        url: feed.url,
        originalUrl: feed.originalUrl,
        link: feed.link,
        description: formatDescription(feed.description),
        author: feed.author,
        ownerName: feed.ownerName,
        image: feed.image
      };
    });

    console.log(JSON.stringify(feeds, null, 2));
    return feeds;
  } catch (error) {
    console.error('Error fetching or parsing feeds:', error);
  }
}

async function fetchAndParseFeedData(feedUrl, feedId) {
  try {
    let headers = {};
    const authHeader = getAuthHeader();
    console.log("got authHeader for POST:", authHeader);
    if (authHeader) {
      headers['Authorization'] = authHeader;
    }

    const requestData = {
      feedUrl,
      feedId,
      limit:35,
    };
    const response = await axios.post('https://rss-extractor-app-yufbq.ondigitalocean.app/getFeed', requestData, { headers });

    const episodes = response.data.episodes.episodes.map(episode => {
      return {
        episodeNumber: episode.episodeNumber,
        creator: episode.creator,
        episodeImage: episode.episodeImage,
        itemTitle: episode.itemTitle,
        episodeGUID: episode.episodeGUID,
        itemUrl: episode.itemUrl,
        publishedDate: episode.publishedDate,
        length: episode.length,
        description: formatDescription(episode.description)
      };
    });

    console.log(JSON.stringify(episodes, null, 2));
    return episodes;
  } catch (error) {
    console.error('Error fetching or parsing feed data:', error);
  }
}

async function getFountainLink(guid) {
  try {
    const response = await axios.get(`https://api.fountain.fm/v1/search/lookup?type=episode&guid=${guid}`);
    if (response.data.success) {
      const fountainId = response.data.ids.episode.fountain;
      return `https://fountain.fm/episode/${fountainId}`;
    } else {
      throw new Error('Failed to get Fountain link');
    }
  } catch (error) {
    console.error('Error fetching Fountain link:', error);
  }
}

export {
  fetchAndParseFeeds,
  fetchAndParseFeedData,
  getFountainLink
};