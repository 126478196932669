import React from "react";
import Modal from "react-modal";
import {
  Navbar,
  Collapse,
  Typography,
  Button,
  IconButton,
  List,
  ListItem,
  Menu,
  MenuHandler,
  MenuList,
  MenuItem,
} from "@material-tailwind/react";
import {
  ChevronDownIcon,
  CubeTransparentIcon,
  CubeIcon,
  Bars3Icon,
  XMarkIcon,
  FlagIcon,
  RocketLaunchIcon,
} from "@heroicons/react/24/outline";

import cascadr_banner from "../../../media/images/cascdr/cascdr_banner.png";
import { useActiveTab } from "../../ActiveTabContext";
import debounce from "lodash/debounce";
import BitcoinConnectButton from "../BitcoinConnectButton";
import {GPTIcon, SDIcon, YTIcon, WhisperIcon,YTAgentIcon,CHADBotIcon} from '../../../lib/custom_icons'
import {signOut} from "../../../lib/accountManagement"
import CheckoutFormModal from "../modal/CheckoutFormModal";
import CustomAlert from "../CustomAlert";
import { update } from "lodash";
import "./NavbarWithMegaMenu.css"

const colors = {
  blue: "bg-blue-50 text-blue-500",
  orange: "bg-orange-50 text-orange-500",
  green: "bg-green-50 text-green-500",
  "blue-gray": "bg-blue-gray-50 text-blue-gray-500",
  purple: "bg-purple-50 text-purple-500",
  teal: "bg-teal-50 text-teal-500",
  cyan: "bg-cyan-50 text-cyan-500",
  pink: "bg-pink-50 text-pink-500",
  red: "bg-red-50 text-red-500",
  yellow: "bg-yellow-50 text-yellow-500",
};

const navListMenuItems = [
  {
    color: "blue",
    icon: CubeIcon,
    title: "Logic App",
    description: "Build your own workflows by combining services!",
  },
  {
    color: "green",
    icon: GPTIcon,
    title: "ChatGPT",
    description: "Make Proxied Queries to the Flagship LLM",
  },
  {
    color: "orange",
    icon: YTAgentIcon,
    title: "RSS Agent",
    description: "Extract summaries or write articles with an RSS AI Agent!",
  },
  {
    color: "yellow",
    icon: RocketLaunchIcon,
    title: "CHADbot Subscription Manager",
    description: "Subscribe to updates and summaries from Youtube channels",
  },
  {
    color: "cyan",
    icon: WhisperIcon,
    title: "Voice to Text",
    description: "Turn audio or video clips into text transcripts w/ word level timestamps",
  },
  {
    color: "purple",
    icon: SDIcon,
    title: "Stable Diffusion",
    description: "Text-to-image Services from the Open Source Model",
  },
  {
    color: "red",
    icon: YTIcon,
    title: (
      <div className="flex items-center gap-1">
        CASCDRVision
      </div>
    ),
    description: "View/Download, Make Clips of YouTube Videos Privately",
  },
];

function AlertExample({isOpen,message,affirmativeButtonText,closeAction}) {

  const button1 = {
    text: affirmativeButtonText,
    action: () => {
      console.log('closeAction is:', typeof closeAction); // Check if it's a function
      if (typeof closeAction === 'function') {
        closeAction(); // Hide the alert if it's a function
      } else {
        console.error('closeAction is not a function'); // Log an error if it's not
        console.log(typeof closeAction )
      }
    },
  };

  // const button2 = {
  //   text: 'Cancel',
  //   action: () => {
  //     console.log('Cancel clicked!');
  //     setAlertVisible(false); // Hide the alert
  //   },
  // };

  return (
    <div>      
      {isOpen && (
        <CustomAlert 
          message={message}
          button1={button1} 
          // button2={button2} 
        />
      )}
    </div>
  );
}

const NavbarCheckoutModal = ({ isOpen, onClose, onSuccess,onAlreadySignedUp }) => (
  <Modal
    isOpen={isOpen}
    onRequestClose={onClose}
    style={{
      overlay: { backgroundColor: "rgba(0, 0, 0, 0.5)" },
      content: {
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        padding: "20px",
        borderRadius: 0,
        background: "black",
        color: "white",
        boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
      },
    }}
  >
    <CheckoutFormModal
      isOpen={isOpen}
      onAlreadySignedUp={onAlreadySignedUp}
      onClose={onClose}
      onSuccess={onSuccess}
    />
  </Modal>
);


const NavbarWithMegaMenu = ({paymentChoiceStatus, setPaymentChoiceStatus }) =>  {
  const [openNav, setOpenNav] = React.useState(false);
  const [isCheckoutOpen, setIsCheckoutOpen] = React.useState(false);
  const [isSignInAlertVisible, setIsSignInAlertVisible] = React.useState(false);
  const [isWelcomeAlertVisible, setIsWelcomAlertVisible] = React.useState(false);
  const [isCheckoutAlertVisible, setIsCheckoutAlertVisible] = React.useState(false);
  const [ctaState, setCtaState] = React.useState("signIn"); // Default state
  const { activeTab } = useActiveTab();
  
  function NavListMenu() {
    const [isMenuOpen, setIsMenuOpen] = React.useState(false);
    const [isMobileMenuOpen, setIsMobileMenuOpen] = React.useState(false);
    const { activeTab, setActiveTab } = useActiveTab();
  
    const tabIdMapping = {
      '0': 'Logic App',
      '1': 'GPT',
      '2': 'yt-agent',
      '3': 'chadbot',
      '4': 'whisper',
      '5': 'SD',
      '6': 'yitter',
    };
  
    const handleItemClick = (key) => {
      // Assuming 'key' is the index of the clicked item
      var appendix = ""
      if(tabIdMapping[key]){
        appendix=`?tab=${tabIdMapping[key]}`
      }
      window.location.href = `${window.location.origin}${window.location.pathname}${appendix}`;
    };

    const NavList = () => (
      <List className="mt-4 mb-6 p-0 lg:mt-0 lg:mb-0 lg:flex-row lg:p-1">
        <Typography as="a" href="#" variant="small" color="blue-gray" className="font-normal"></Typography>
        <div className="flex items-center justify-center gap-2">
          <BitcoinConnectButton style={{ padding: "8px", color: "white" }} />
        </div>
        <NavListMenu />
      </List>
    );
   
    const renderItems = navListMenuItems.map(
      ({ icon, title, description, color }, key) => (
        <a href="#" key={key} onClick={() => handleItemClick(key)}>
          <MenuItem className="flex items-center gap-3 rounded-lg">
            <div className={`rounded-lg p-5 ${colors[color]}`}>
              {React.createElement(icon, {
                strokeWidth: 2,
                className: "h-6 w-6",
              })}
            </div>
            <div>
              <Typography
                variant="h6"
                color="blue-gray"
                className="flex items-center text-sm"
              >
                {title}
              </Typography>
              <Typography variant="small" color="gray" className="font-normal">
                {description}
              </Typography>
            </div>
          </MenuItem>
        </a>
      )
    );
   
    return (
      <React.Fragment>
        <Menu
          open={isMenuOpen}
          handler={setIsMenuOpen}
          offset={{ mainAxis: 20 }}
          placement="bottom"
          allowHover={true}
          style={{backgroundColor:'white'}}
        >
          <MenuHandler>
            <Typography as="div" variant="small" className="font-normal">
              <ListItem
                className="flex items-center gap-2 py-2 pr-4 hidden lg:flex"
                selected={isMenuOpen || isMobileMenuOpen}
                onClick={() => setIsMobileMenuOpen((cur) => !cur)}
                style={{ color: 'white', marginRight:'-5px', width:"180px"}}
              >
                <CubeTransparentIcon  className="hidden h-[18px] w-[18px] lg:block" style={{color: isMenuOpen ? "black" : "white"}}/>
                <div style={{color: isMenuOpen ? "black" : "white"}} className="hidden lg:block">
                  <strong >Available Services</strong>
                </div>
                <ChevronDownIcon
                  strokeWidth={2.5}
                  style={{color: isMenuOpen ? "black" : "white"}}
                  className={`hidden h-3 w-3 transition-transform lg:block ${
                    isMenuOpen ? "rotate-180" : ""
                  }`}
                />
              </ListItem>
            </Typography>
          </MenuHandler>
          <MenuList className="hidden max-w-screen-xl rounded-xl lg:block lg:max-h-[100px]" style={{ overflowY: 'auto' }}>
            <ul className="grid gap-y-2" style={{
              gridTemplateColumns: 'repeat(3, minmax(0, 1fr))', // Default to 3 columns
              '@media (max-width: 600px)': { // For screens smaller
                gridTemplateColumns: 'repeat(1, minmax(0, 1fr))' // Change to 1 column
              }
            }}>
              {renderItems}
            </ul>
          </MenuList>
        </Menu>
        <div className="block lg:hidden" style={{backgroundColor:'white', overflowY: 'auto', maxHeight: '250px'}}>
          {renderItems}
        </div>
      </React.Fragment>
    );
  }
   
  function NavList() {
    return (
      <List className="mt-4 mb-6 p-0 lg:mt-0 lg:mb-0 lg:flex-row lg:p-1">
        <Typography
          as="a"
          href="#"
          variant="small"
          color="blue-gray"
          className="font-normal"
        >
        </Typography>
        <div className="flex items-center justify-center gap-2">
          <BitcoinConnectButton style={{'padding':'8px',color:'white'}}/>
        </div>
        <NavListMenu />
        <Typography
          as="a"
          href="#"
          variant="small"
          color="blue-gray"
          className="font-normal"
        >
        </Typography>
      </List>
    );
  }



  const openCheckoutModal = () => {
    setOpenNav(false);
    setIsCheckoutOpen(true);
  };

  const closeCheckoutPrompt = () => {
    setIsCheckoutAlertVisible(false)
  }

  const closeCheckoutModal = () => {
    updateCTAState();
    setIsCheckoutOpen(false);
  };


  const closeWelcomeAlert = () => {
    setIsWelcomAlertVisible(false); // This should close the alert
  };
  

  function handleSignOut(){
    console.log("Signing out")
    setCtaState("signIn")
    signOut();
  }

  function handleAlreadySignedUp(){
    updateCTAState();
    closeCheckoutModal();
    //TODO: set alert!
  }

  function handleCheckout(){
    updateCTAState();
    setIsWelcomAlertVisible(true);
    closeCheckoutModal();
    //TODO: set alert!
  }

  //Update UI to react to CTA State appropriately
  const CTAButton = ({ ctaState, onSignIn, onUpgrade, onSignOut }) => {
    let buttons = [];

    // Add Sign In or Upgrade button
    if (ctaState === "signIn") {
      buttons.push(
        <Button
          key="signIn"
          variant="gradient"
          size="sm"
          onClick={onSignIn}
          className="mr-2"
        >
          Sign In
        </Button>
      );
    } else if (ctaState === "upgrade") {
      buttons.push(
        <Button
          key="upgrade"
          variant="gradient"
          size="sm"
          onClick={onUpgrade}
          className="mr-2"
        >
          Upgrade
        </Button>
      );
    }

    // Add Sign Out button if state is signOut or upgrade
    if (ctaState === "signOut" || ctaState === "upgrade") {
      buttons.push(
        <Button
          key="signOut"
          variant="outlined"
          size="sm"
          onClick={onSignOut}
          style={{
            borderColor: 'white',
            color: 'white',
            backgroundColor: 'transparent'
          }}
          className="hover:bg-white hover:text-black transition-colors duration-300"
        >
          Sign Out
        </Button>
      );
    }

    return <div className="flex">{buttons}</div>;
  };

  //write to CTA state based on stored items
  function updateCTAState(){
    const email = localStorage.getItem("email");
    const isSubscribed = localStorage.getItem("isSubscribed");
    if(!email){
      setCtaState("signIn")
      return
    }
    else if(isSubscribed === "true"){// has email and is subscribed
      setCtaState("signOut")
    }
    else{ // has email, not subscribed
      setCtaState("upgrade")
    }
  }

  React.useEffect(() => {
    updateCTAState();
  }, []);

  React.useEffect(() => {
    updateCTAState();
  }, [paymentChoiceStatus]);

  React.useEffect(() => {
    const handleResize = debounce(() => {
      if (window.innerWidth >= 960) {
        setOpenNav(false);
      }
    }, 300);

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);


  

  return (
    <Navbar
      className="mx-auto max-w-full w-full fixed"
      style={{
        background: "linear-gradient(to right, #F81E1E, #f8801e)",
        width: "100%",
        height: "110px",
        maxHeight: "110px",
        margin: "0px",
        zIndex: "999",
      }}
    >

      <NavbarCheckoutModal
          isOpen={isCheckoutOpen}
          onClose={closeCheckoutModal}
          onAlreadySignedUp={handleAlreadySignedUp}
          onSuccess={handleCheckout}
      />
      
      <div className="flex justify-between items-center w-full">
        <a href="/" style={{ display: "inline-block" }}>
          <img src={cascadr_banner} alt="Logo" style={{ maxHeight: "75px" }} />
        </a>
        <div className="flex justify-end items-center gap-2">
          <div className="hidden lg:block">
            <NavList />
          </div>
          <div className="hidden gap-2 lg:flex" style={{ marginLeft: "10px", marginRight: "15px" }}>
            <CTAButton
              ctaState={ctaState}
              onSignIn={openCheckoutModal}
              onUpgrade={openCheckoutModal}
              onSignOut={handleSignOut}
            />
          </div>
          <IconButton
            variant="text"
            color="blue-gray"
            className="lg:hidden"
            onClick={() => setOpenNav(!openNav)}
          >
            {openNav ? (
              <XMarkIcon className="h-6 w-6" strokeWidth={2} />
            ) : (
              <Bars3Icon className="h-6 w-6" strokeWidth={2} />
            )}
          </IconButton>
        </div>
      </div>
      <Collapse open={openNav}>
        <div style={{ backgroundColor: "white" }}>
          <NavList />
          <div
            className="pb-8 flex w-full flex-nowrap items-center justify-center gap-2 lg:hidden"
            onClick={openCheckoutModal}
          >
            <Button variant="gradient" size="md">
              Subscribe for Updates
            </Button>
          </div>
        </div>
      </Collapse>

      <AlertExample isOpen={isSignInAlertVisible} message={"Sign In Success. Enjoy CASCDR!"} affirmativeButtonText={"Sweet."} closeAction={closeCheckoutModal} />
      <AlertExample isOpen={isWelcomeAlertVisible} message={"Your subscription was a success! Enjoy exploring the future of AI with CASCDR!"} affirmativeButtonText={"Let's go!"} closeAction={closeWelcomeAlert}  />
      <AlertExample isOpen={isCheckoutAlertVisible} message={"Subscribe to CASCDR Amber to get unlimited CASCDR services for one $9.99/mo fee"} affirmativeButtonText={"Let's go!"} closeAction={closeCheckoutPrompt}  />
    </Navbar>
  );
}


export default NavbarWithMegaMenu;
