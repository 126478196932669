import React, { useEffect, useState } from 'react';
import PromptOptionsComponent from './PromptOptionsComponent';
import './PromptOptionsComponent.css';
import { BeatLoader } from 'react-spinners';


const TranscriptAnalysisToggle = ({ 
  transcriptAvailable, 
  renderTranscriptContent, 
  onOptionChange, 
  onCustomPromptChange,
  onRunAnalysis,
  showOptionsOnOpen=false,
  initialToggleTab,
  isLoading
}) => {
  const [selectedView, setSelectedView] = useState(transcriptAvailable ? 'Transcript' : 'AI Analysis');

  useEffect(() => {
    // console.log(`showOptionsOnOpen:`,showOptionsOnOpen)
    setSelectedView(initialToggleTab)
  }, [initialToggleTab]);

  useEffect(() => {
    // console.log(`showOptionsOnOpen:`,showOptionsOnOpen)
    console.log(selectedView)
  }, [selectedView]);

  const handleViewChange = (view) => {
    setSelectedView(view);
  };

  const handleRunAnalysis = () => {
    setSelectedView('AI Analysis');
    onRunAnalysis();
  }

  return (
    <div className="transcript-analysis-toggle">
      <div className="segmented-control">
        <button
          className={`segmented-control-button ${selectedView === 'Transcript' ? 'active' : ''}`}
          onClick={() => handleViewChange('Transcript')}
          disabled={!transcriptAvailable}
        >
          Transcript
        </button>
        <button
          className={`segmented-control-button ${selectedView === 'AI Analysis' ? 'active' : ''}`}
          onClick={() => handleViewChange('AI Analysis')}
        >
          AI Analysis
        </button>
      </div>

      <div className="content-area">
        {selectedView === 'Transcript' && transcriptAvailable ? (
          renderTranscriptContent()
        ) : (
          <div className="ai-analysis-container">
            <PromptOptionsComponent
              onOptionChange={onOptionChange}
              onCustomPromptChange={onCustomPromptChange}
              showOptionsOnOpen={showOptionsOnOpen}
            />
            <div className="ctsm-run-button-container">
                <button className="ctsm-run-button" onClick={handleRunAnalysis}>
                {isLoading ? (
                    <BeatLoader color="white" size={8} loading={true} />
                ) : (
                    <strong>Run</strong>
                )}
                </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default React.memo(TranscriptAnalysisToggle);