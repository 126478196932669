import React from 'react';
import SquareAppCard from '../SquareAppCard';
import { useActiveTab } from '../../ActiveTabContext';


const SquareAppGrid = ({ appData, title }) => {
    const { activeTab, setActiveTab } = useActiveTab();
    const rootUrl = `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}`;

    const handlePopState = () => {
        const params = new URLSearchParams(window.location.search);
        const tab = params.get('tab');
        const wfid = params.get('wfid')
        if (tab) {
            setActiveTab(tab);
        }
    };

    const handleCardClick = (actionId) => {
        const newUrl = `${rootUrl}/?tab=${actionId}`;
        window.history.pushState({ path: newUrl }, '', newUrl);
        handlePopState();
    };
  
    return (
        <div className="w-full max-w-4xl mx-auto relative" style={{paddingTop:'80px'}}>
            <h2 className="text-3xl font-bold text-white mb-8 text-right font-['League_Spartan'] px-6">{title}</h2>
            <div className="flex justify-center w-full">        
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 max-w-6xl">
                {appData.map((app, index) => (
                    <SquareAppCard
                    key={index}
                    title={app.pageName}
                    description={app.description}
                    icon={app.image}
                    actionId={app.link}
                    onClick={handleCardClick}
                    />
                ))}
                </div>
            </div>
        </div>
      
    );
  };
  

export default SquareAppGrid;