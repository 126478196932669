import React from 'react';
import { MarkerType, Position } from 'reactflow';

export const edges = [
  // { 
  //   id: 'e1-2', 
  //   source: '1', 
  //   target: '2', 
  //   // label: 'this is an edge label',
  //   markerEnd: {
  //     type: MarkerType.ArrowClosed,
  //   },
  // },
  // {
  //   id: 'e2-3',
  //   source: '2',
  //   target: '3',
  //   type: 'smoothstep',
  //   sourceHandle: 'handle-0',
  //   data: {
  //     selectIndex: 0,
  //   },
  //   markerEnd: {
  //     type: MarkerType.ArrowClosed,
  //   },
  // },
  // {
  //   id: 'e3-5',
  //   source: '3',
  //   target: '5',
  //   type: 'smoothstep',
  //   sourceHandle: 'handle-0',
  //   data: {
  //     selectIndex: 0,
  //   },
  //   markerEnd: {
  //     type: MarkerType.ArrowClosed,
  //   },
  // },
];


export const demo2Edges = [
{ 
    id: 'e1-2', 
    source: '1', 
    target: '2', 
    // label: 'this is an edge label',
    markerEnd: {
      type: MarkerType.ArrowClosed,
    },
    data: {
      // Define your edge data here
    },
  },
  { 
    id: 'e2-3', 
    source: '2', 
    target: '3', 
    // label: 'this is an edge label',
    markerEnd: {
      type: MarkerType.ArrowClosed,
    },
    data: {
      // Define your edge data here
    },
  },
  { 
    id: 'e3-5', 
    source: '3', 
    target: '5', 
    // label: 'this is an edge label',
    markerEnd: {
      type: MarkerType.ArrowClosed,
    },
    data: {
      // Define your edge data here
    },
  },
];
