import React from 'react';
import TestimonialCard from '../TestimonialCard';

const TestimonialsGrid = ({title,testimonials}) => {
  return (
    <div className="bg-black py-12 w-full">
      <div className="max-w-6xl mx-auto px-4">
        <h2 className="text-3xl font-bold text-white mb-8 text-left">{title}</h2>
        <div className="space-y-6">
          {testimonials.map((testimonial, index) => (
            <TestimonialCard 
              key={index}
              text={testimonial.text}
              name={testimonial.name}
              role={testimonial.role}
              image={testimonial.image}
              imagePosition={index % 2 === 0 ? 'left' : 'right'}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default TestimonialsGrid;