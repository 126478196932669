import React from 'react';

const ContactUsSection = ({ title, links }) => {
    return (
        <div className="w-full max-w-4xl mx-auto px-4 md:px-6">
          <h2 className="text-3xl font-bold text-white mb-4 text-left font-['League_Spartan']">{title}</h2>
          <div className="bg-gradient-to-r from-[#f8801e] to-[#f84c1e] rounded-lg p-4 md:p-5 flex justify-center items-center">
            {links.map((link, index) => (
              <a
                key={index}
                href={link.url}
                target="_blank"
                rel="noopener noreferrer"
                className="mx-3 md:mx-4 bg-black rounded-full p-2 transition-transform hover:scale-110"
              >
                <img src={link.icon} alt={link.name} className="w-8 h-8 md:w-10 md:h-10" style={{margin:'4px'}}/>
              </a>
            ))}
          </div>
        </div>
      );
};

export default ContactUsSection;