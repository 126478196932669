import React, { useState,useEffect ,useRef} from 'react';
import './YitterComponent.css'; 
import { run_yitter_example,run_youtube_agent,getYoutubeVideoId, getVideoDetails, getVideoTimestampedTranscript } from '../../common/nip105-client';
import { BeatLoader } from 'react-spinners';
import YTIcon from '../../media/images/services/CASCDRVision.png';
import VideoPlayer from '../../common/components/VideoPlayer';
import { ActiveTabProvider, useActiveTab } from '../../common/ActiveTabContext';
import TranscriptCaptureComponent from '../../common/components/TranscriptCaptureComponent';
import QuotesDisplayComponent from '../../common/components/QuotesDisplayComponent';
import ToolsTabsComponent from '../../common/components/ToolsTabsComponent'
import ArchivedClipsComponent from '../../common/components/ArchivedClipsComponent';
import Button from '@mui/material/Button'; // For the toggle button



const YitterComponent = ({paymentChoiceStatus, setPaymentChoiceStatus}) => {
  const [youtubeURL, setYoutubeURL] = useState('');
  const [streamableURL, setStreamableURL] = useState(null);
  const [isLoading,setIsLoading] = useState(false);
  const [transcript, setTranscript] = useState('');
  const [videoId, setVideoId] = useState('');
  const [transcriptSelectedTime, setTranscriptSelectedTime] = useState(null)
  const [playerTime, setPlayerTime] = useState(0)
  const { activeTab, setActiveTab } = useActiveTab();
  const [isVideoPaused, setIsVideoPaused] = useState(false);
  const [transcriptLoading, setTranscriptLoading] = useState(false);
  const [transcriptVersion, setTranscriptVersion] = useState(0);
  const [quoteClipsLoading, setQuoteClipsLoading] = useState(false);
  const [showBubble, setShowBubble] = useState(false);
  const [bubbleText, setBubbleText] = useState("")
  const [currentVideoTitle, setCurrentVideoTitle] = useState("");
  const [customLLMPrompt,setCustomLLMPrompt] = useState(null);
  const forceUpdateRef = useRef();
  const defaultQuotePrompt = "Extract the top 5 most thought provoking quotes from this talk...";
  const videoPlayerRef = useRef();

  const forceUpdatePlayer = (time) => {
      if (videoPlayerRef.current) {
          videoPlayerRef.current.seekTo(time);
      }
  };

  const handlePreview = (start, end) => {
    console.log(`loopStart:${start}, loopEnd:${end}`)
    // setLoopStart(start);
    // setLoopEnd(end);
    // setShouldLoop(true);
    setIsVideoPaused(false); // Assuming you have a state for managing play/pause
  };

  const handleTimeUpdate = (time) => {
    //console.log(`handleTimeUpdate from YitterComponent:${time}`)
    setPlayerTime(time)
    forceUpdatePlayer(time); 
  };

  const showClipFinishBubble = () => {
    setShowBubble(true);
    setBubbleText("Clip finished and added to archive tab.")
  }

  const showCopyFinish = () => {
    setShowBubble(true);
    setBubbleText("Clip URL copied to clipboard.")
  }

  const handleClipFinish = () => {
    handleOpenOrSwitchTab('Archived Clips', () => <ArchivedClipsComponent onClipCopy={showCopyFinish} />);
  }

  const CustomPromptComponent = ({ initialPrompt, onRun, setCustomPrompt, clipsLoading }) => {
    const [prompt, setPrompt] = useState(initialPrompt);
  
    useEffect(() => {
      setCustomPrompt(prompt); // Update the parent state whenever the prompt changes
    }, [prompt, setCustomPrompt]);
  
    return (
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '200%' }}>
        <label htmlFor="customPrompt" style={{ alignSelf: 'flex-start' }}>Step 2 Custom Prompt (Optional):</label>
        <textarea
          id="customPrompt"
          value={prompt}
          onChange={(e) => setPrompt(e.target.value)}
          style={{
            width: '80%', // Set width to 80% of its parent
            height: '100px',
            padding:'4px',
            margin: '10px 0',
            color: 'black'
          }}
        />
        <div style={{ width: '80%', display: 'flex', justifyContent: 'flex-end' }}>
          <Button
            onClick={() => onRun(prompt)}
            disabled={clipsLoading}
            sx={{
              padding: '10px 15px',
              border: '2px solid #FFA500',
              color: 'white',
              borderRadius: '4px',
              cursor: 'pointer',
              fontWeight: 'bold',
              background: 'transparent',
              '&:hover': {
                background: '#FFA500',
                color: 'black',
                textDecoration: 'none'
              }
            }}
          >
            {clipsLoading ? <BeatLoader color="white" size={8} loading={true} /> : 'Run'}
          </Button>
        </div>
      </div>
    );
  };
  
  
  
  const ToolsSummaryComponent = ({ onShowTranscript, onShowTopQuotesClips, transcriptLoading, clipsLoading, setCustomPrompt }) => {
    const [showCustomPrompt, setShowCustomPrompt] = useState(false);
  
    const handleQuotesClick = () => {
      setShowCustomPrompt(true);
      // You may need to set additional state or perform other actions here
    };

    const handleRunClick = () =>{
      setShowCustomPrompt(false);
      onShowTopQuotesClips();
    }

    const buttons = [
      {
        title: 'TRANSCRIPT',
        action: onShowTranscript,
        loading: transcriptLoading,
      },
      {
        title: 'Quotes (Clips)',
        action: handleQuotesClick,
        loading: clipsLoading,
      },
    ];
  
    return (
      <div style={{
        display: 'grid',
        gridTemplateColumns: 'repeat(2, 1fr)',
        gap: '10px',
        padding: '10px',
        border: '1px solid #201201',
        borderRadius: '8px'
      }}>
        {buttons.map((button, index) => (
          <Button
            key={index}
            component="div"
            onClick={button.action}
            className="tool-tab-button"
            disabled={button.loading}
            sx={{
              padding: '15px',
              border: '2px solid #FFA500',
              color: 'white',
              borderRadius: '4px',
              cursor: 'pointer',
              fontWeight: 'bold',
              background: 'transparent',
              '&:hover': {
                background: '#FFA500',
                color: 'black',
                textDecoration: 'none'
              }
            }}
          >
            {button.loading ? <BeatLoader color="white" size={8} /> : button.title}
          </Button>
        ))}
        {showCustomPrompt && (
        <CustomPromptComponent
          initialPrompt={defaultQuotePrompt}
          onRun={handleRunClick}
          setCustomPrompt={setCustomLLMPrompt}
          clipsLoading={clipsLoading}
        />
      )}
      </div>
    );
  };
  

  const initialTabs = [
    { title: 'Tools 🛠️', component: ToolsSummaryComponent },
    {title: 'Archived Clips',component: () => <ArchivedClipsComponent onClipCopy={showCopyFinish} />}
  ]
  const [toolsTabs, setToolsTabs] = useState(initialTabs);

  const [activeToolsTab, setActiveToolsTab] = useState(0);
  const [showTools, setShowTools] = useState(true);

  const toggleToolsVisibility = () => {
    setShowTools(!showTools);
  };
  

  const handlePauseVideo = () => {
    console.log('handlePauseVideo')
    setIsVideoPaused(true);  // Update state to reflect that video should be paused
  };

  const handleInputChange = (event) => {
    setYoutubeURL(event.target.value);
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleButtonClick();
    }
  };

  const handleTranscriptViewCallback = (action) =>{
    if(action === 'search'){
      console.log('handleTranscriptViewCallback search')
    }
  }

  function sanitizeUrl(url) {
    const videoIdRegex = /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/watch\?v=|youtu\.be\/)([^&?\/\s]{11})/;
    const match = url.match(videoIdRegex);
    if (match && match[1]) {
        return `https://www.youtube.com/watch?v=${match[1]}`;
    }
    return null;
  }  

  const showTopQuotesClips = async () => {
    try {
      const sanitizedUrl = sanitizeUrl(youtubeURL);
      if (!sanitizedUrl) {
        alert('Invalid YouTube video URL. Please try again.');
        return;
      }
  
      const promptText = customLLMPrompt ? customLLMPrompt : defaultQuotePrompt;
      console.log(`promptText:${promptText}`)
      
      const data = {
        ytLink: sanitizedUrl,
        customPrompt: promptText,
        type: 'quote_clips'
      };

      const newTabs = toolsTabs.filter(tab => tab.title !== 'Top Quotes Clips'); // Assuming 'Top Quotes Clips' is the title of your tab   setToolsTabs(newTabs);
      setToolsTabs(newTabs);
      setQuoteClipsLoading(true);
      const result = await run_youtube_agent(data);
      setQuoteClipsLoading(false);

      if (result.authCategory && result.authCategory === "not-selected") {
        setPaymentChoiceStatus("choosePaymentMethod");
        return;
      }
  
      if (result && result["0"]) {
        const quotesArray = Object.keys(result).filter(key => key !== "authCategory" && key !== "paymentHash" && key !== "successAction").map(key => ({
          quote: result[key].quote,
          start: parseFloat(result[key].start),
          end: parseFloat(result[key].end)
        }));

        handleOpenOrSwitchTab('Top Quotes Clips', () => (
          <QuotesDisplayComponent 
            quotes={quotesArray} 
            videoTitle={currentVideoTitle}
            videoId={videoId} 
            onPreview={handlePreview}
            onClickText={(start) => {
              console.log(`onClickText:${start}`)
              setTranscriptSelectedTime(start);  // Set state
              forceUpdatePlayer(start) 
              const interval = setInterval(() => {
                setTranscriptSelectedTime(null);
              }, 1000);
              return () => clearInterval(interval); 
            }}
            onClipFinish={handleClipFinish}
            setPaymentChoiceStatus={setPaymentChoiceStatus}
          />
        ));
  
        // const newTabTitle = 'Top Quotes Clips';
        // setToolsTabs(prevTabs => [...prevTabs, { title: newTabTitle, component: newComponent }]);
        // setActiveToolsTabByTitle(newTabTitle); // Make the new tab active
      } else {
        throw new Error("No valid data available for quotes clips.");
      }
    } catch (error) {
      console.error('Error running YouTube agent:', error);
      alert('Failed to process the request. Please try again.');
    }
  }

  const showTranscript = async () => {
    try {
        const fetchMsg = "Fetching video transcript...";
        console.log(fetchMsg);
        setTranscriptLoading(true);
        const fetchedVideoId = getYoutubeVideoId(youtubeURL);
        const fetchedTranscript = await getVideoTimestampedTranscript(fetchedVideoId);
        if (fetchedTranscript.data.transcript) {
            console.log('Successfully fetched transcript:', fetchedTranscript.data.transcript);
            setTranscript(fetchedTranscript.data.transcript);
            setTranscriptVersion(v => v + 1);
          
            // Check if the "Transcript" tab is already present
            if (!toolsTabs.some(tab => tab.title === 'Transcript')) {
              handleOpenOrSwitchTab('Transcript', () => (
                <TranscriptCaptureComponent
                  title="Transcript"
                  videoTitle={currentVideoTitle}
                  key={playerTime}
                  videoId={videoId}
                  transcript={fetchedTranscript.data.transcript}
                  setTranscriptTime={setTranscriptSelectedTime}
                  playerTime={playerTime}
                  onSearchSubmit={handlePauseVideo}
                  setPaymentChoiceStatus={setPaymentChoiceStatus}
                  transcriptLoading={transcriptLoading}
                  onMakeClip={handleClipFinish}
              />
              ));
            }
        } else {
            throw new Error("No transcript data available.");
        }
    } catch (error) {
        console.error("Failed to fetch transcript:", error);
        setTranscript("Failed to grab transcript. Please try again.");
    } finally {
        setTranscriptLoading(false);
    }
};


  const hideTranscript = async () => {
    setTranscript('');
    setTranscriptSelectedTime(null);
    setTranscriptLoading(false);
  }

  const handleOpenOrSwitchTab = (title, componentFunction) => {
    // Check if the tab already exists
    const tabIndex = toolsTabs.findIndex(tab => tab.title === title);
    if (tabIndex !== -1) {
      // Tab exists, just switch to it
      setActiveToolsTab(tabIndex);
    } else {
      // Tab doesn't exist, create new one and open it
      const newTabs = [...toolsTabs, { title: title, component: componentFunction }];
      setToolsTabs(newTabs);
      setActiveToolsTab(newTabs.length - 1);
    }
  };

  const handleButtonClick = async () => {
    performJob(youtubeURL)
  };

  async function performJob(youtubeURL) {
    try{
      setStreamableURL(null)
      setIsLoading(true);
      const result = await run_yitter_example(youtubeURL);
      console.log(`result:`,result)
      if (result.authCategory && result.authCategory === "not-selected" || typeof result.ytLink != 'string') {
        setPaymentChoiceStatus("choosePaymentMethod");
        setIsLoading(false);
        return;
      }
      result ? setStreamableURL(result.ytLink) : alert(`Failed fetching url for: ${youtubeURL}. Please try again`);
      setVideoId(result.videoId);
      setToolsTabs(initialTabs);
      setTranscript('');
      setPlayerTime(0);
      setIsVideoPaused(true);
      setTranscriptSelectedTime(null);
      setTranscriptLoading(false);
      console.log(`videoId:${videoId}`)
      
      setIsLoading(false);
    }
    catch (error) {
      console.error('Error in performJob:', error);
      setIsLoading(false);
    }
    
  }

  useEffect(() => {
    if(transcriptSelectedTime === null){
      return;
    }
    console.log("selected time set in YitterComponent:", transcriptSelectedTime)
    const parsedTime = parseFloat(transcriptSelectedTime);
    if(parsedTime){
      console.log(`parsedTime:${parsedTime}`)
      handleTimeUpdate(parsedTime)
    }
  }, [transcriptSelectedTime, forceUpdateRef.current]);

  useEffect(() => {
    const handlePopState = () => {
        // Extract the tab parameter from the URL
        const params = new URLSearchParams(window.location.search);
        const tab = params.get('tab');

        // Update the active tab based on the URL, or reset to default if no tab is specified
        if (tab) {
            setActiveTab(tab);
        } else {
            // Reset to default state if back at main page without specific tab
            setActiveTab(null); // Or whatever your default state should be
        }
    };

    window.addEventListener('popstate', handlePopState);

    return () => window.removeEventListener('popstate', handlePopState);
}, [setActiveTab]);

useEffect(() => {
  // This code runs after `toolsTabs` has been updated
  if (toolsTabs.length > 0 && activeToolsTab >= toolsTabs.length) {
      setActiveToolsTab(toolsTabs.length - 1); // Adjust if necessary
  }
}, [toolsTabs]); // Only re-run when `toolsTabs` changes


useEffect(() => {
  if (showBubble) {
    const timer = setTimeout(() => setShowBubble(false), 5000); // Hide bubble after 5 seconds
    return () => clearTimeout(timer);
  }
}, [showBubble]);

const commonProps = {
  onShowTranscript: showTranscript,
  onShowTopQuotesClips: showTopQuotesClips,
  transcriptLoading: transcriptLoading,
  clipsLoading: quoteClipsLoading,
  setCustomPrompt: setCustomLLMPrompt // Assuming these functions are defined somewhere in your component
};

return (
  <div className="yitter-container">
    {streamableURL && <div style={{paddingTop: "100px"}}></div>}
    <h1>This Service is temporarily disabled while we work around new YouTube restrictions. Please check back later.</h1>
    <div className="yt-header-container">
      <div className="yt__icon">
        <img src={YTIcon} alt="icon"/>
      </div>
      <h1 className="yt-title">CASCDRVision: Stream, Download, Make Clips from YouTube</h1>
    </div>
    <div className="yitter-input-button-container">
      <input 
        type="text" 
        value={youtubeURL}
        onChange={handleInputChange}
        onKeyPress={handleKeyPress}
        placeholder="Enter your YouTube URL here"
        className="yitter-input"
      />
      {/*<Button 
        onClick={handleButtonClick}
        sx={{
          padding: '15px',
          border: '2px solid #FFA500',
          color:'white',
          borderRadius: '4px',
          cursor: 'pointer',
          fontWeight:'bold',
          background: 'transparent',
          '&:hover': {
            background: '#FFA500',
            color: 'black',
            textDecoration: 'none' // Ensures no underline on hover
          }
        }}
        disabled={isLoading}
      >
        {isLoading ? <BeatLoader color="white" size={8} loading={true} /> : 'Get Stream'}
      </Button>*/}
    </div>
    {streamableURL && (
      <div className="stream-container">
        <div className="video-container">
          <VideoPlayer 
            ref={videoPlayerRef}
            url={streamableURL}
            ytUrl={youtubeURL}
            showTranscript={toggleToolsVisibility}
            seekToTime={transcriptSelectedTime}
            handleTimeUpdate={handleTimeUpdate}
            isPaused={isVideoPaused}
            transcriptVisible={transcript !== ''}
            transcriptLoading={transcriptLoading}
            hideTranscript={toggleToolsVisibility}
            currentVideoTitle={currentVideoTitle}
            setCurrentVideoTitle={setCurrentVideoTitle}
          />
        </div>
        <div className="content-container">
        {showTools && (
          <ToolsTabsComponent
            toolsTabs={toolsTabs}
            setToolsTabs={setToolsTabs}
            activeToolsTab={activeToolsTab}
            setActiveToolsTab={setActiveToolsTab}
          />
        )}
        {showTools && toolsTabs.length > 0 && activeToolsTab < toolsTabs.length && (
          React.createElement(toolsTabs[activeToolsTab].component, {
            ...commonProps, // Spread the common props here
            key: activeToolsTab // Optional: helps with component re-mounting if necessary
          })
        )}
        {showBubble && (
          <div className="bubble-alert">
            {bubbleText}
          </div>
        )}
        </div>
      </div>
    )}
  </div>
);

};


export default YitterComponent;
