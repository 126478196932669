import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './CHADbotComponent.css';
import ChadIcon from '../../media/images/services/chadbot.png';
import ExtensionsIcon from '../../media/images/misc/extensionsIcon.png'
import { ActiveTabProvider, useActiveTab } from '../../common/ActiveTabContext';


const debug = false;
const CHAD_API_URL = (debug) ? ("http://localhost:6969") : ("https://king-prawn-app-7n3un.ondigitalocean.app");

const CHADbotComponent = ({paymentChoiceStatus, setPaymentChoiceStatus}) => {
  const [currentPhoneNumber, setCurrentPhoneNumber] = useState('');
  const [currentEmail, setCurrentEmail] = useState('');
  const [currentNpub, setCurrentNpub] = useState('');
  const [inputContact, setInputContact] = useState('');
  const [inputContactType, setInputContactType] = useState('npub');//useState('default');
  const [contactInfo, setContactInfo] = useState('');
  const [subscriptions, setSubscriptions] = useState([]);
  const { activeTab, setActiveTab } = useActiveTab();
  const [newSubscriptionLink, setNewSubscriptionLink] = useState('');
  const [loading, setLoading] = useState(false);
  const [lookupHasRun, setLookupHasRun] = useState(false);
  const [subscriptionEndpoints, setSubscriptionEndpoints] = useState([]);

  useEffect(() => {
    //localStorage.removeItem("jwtToken")
    const checkTokenAndFetchSubscriptions = async () => {
      const token = localStorage.getItem('jwtToken');
      if (token) {
        console.log('Token found:', token);
        await handleLookupSubscriptions();
      } else {
        console.log('No token found, doing nothing.');
      }
    };

    checkTokenAndFetchSubscriptions();
  }, []);

  useEffect(() => {
    const handlePopState = () => {
        // Extract the tab parameter from the URL
        const params = new URLSearchParams(window.location.search);
        const tab = params.get('tab');

        // Update the active tab based on the URL, or reset to default if no tab is specified
        if (tab) {
            setActiveTab(tab);
        } else {
            // Reset to default state if back at main page without specific tab
            setActiveTab(null); // Or whatever your default state should be
        }
    };

    window.addEventListener('popstate', handlePopState);

    return () => window.removeEventListener('popstate', handlePopState);
}, [setActiveTab]);

  //NIP07 Auth://////////////////////////////////
  function getUnsignedAuthEvent(challenge, challengeId) {
    return {
      kind: 22242,
      created_at: Math.floor(Date.now() / 1000),
      tags: [
        ["challenge", challenge],
        ["challenge_id", challengeId] // Assuming the backend needs challengeId; adjust as necessary
      ],
      content: "CASCDR Authentication",
    };
  }
  
  const authenticateWithNIP07 = async () => {
    // Fetch the challenge from the server
    const challengeResponse = await axios.get(`${CHAD_API_URL}/auth/challenge`);
    console.log("challengeResponse:", challengeResponse);
  
    // Extract the challenge and challengeId from the response
    const { challenge, challengeId } = challengeResponse.data;
  
    // Generate an unsigned event with the challenge
    const unsignedEvent = getUnsignedAuthEvent(challenge, challengeId); // Adjusted to pass both challenge and challengeId
  
    // Sign the event using the user's NOSTR private key through the extension
    let signedEvent;
    try{
       signedEvent = await window.nostr.signEvent(unsignedEvent);
    }
    catch(error){
      alert("Could not find Nostr (NIP07) Login Extension. Please set one up or try again.")
    }
  
    // Send the signedEvent to the server for verification
    const verifyResponse = await axios.post(`${CHAD_API_URL}/auth/verify`, {
      signedEvent, // Sending the entire signed event object
    });
  
    // Extract the JWT token from the response
    const { token, npub } = verifyResponse.data;
    setCurrentNpub(npub)
  
    // Store the JWT token for further authenticated requests
    localStorage.setItem('jwtToken', token); // Adjusted for direct token usage
  };
  
  async function getNIP07Auth(){
    // Check if the token exists in local storage
    if (localStorage.getItem('jwtToken')) {
      const token = localStorage.getItem('jwtToken');
      console.log('Token:', token);
      return token;
      // You can now use the token for your application needs, such as setting Authorization headers for API requests.
    } else {
      try{
        await authenticateWithNIP07();
        const retry = await getNIP07Auth();
        if(retry){
          return retry
        }
      }
      catch(error){
        console.log("error with nip07:",error)
      }
      
      alert("Could not find Nostr (NIP07) Login Extension. Please set one up or try again.")
      console.log('No token found in local storage.');
    }
  }

  ////END: NIP07 Auth/////////////////

  const handleSubscriptionEndpointsChange = (value) => {
    if (subscriptionEndpoints.includes(value)) {
      setSubscriptionEndpoints(subscriptionEndpoints.filter((option) => option !== value));
    } else {
      setSubscriptionEndpoints([...subscriptionEndpoints, value]);
    }
  };

  const handleLinkChange = (newValue, index) => {
    // Create a copy of the subscriptions array
    const updatedSubscriptions = [...subscriptions];
    // Update the link of the subscription at the specified index
    updatedSubscriptions[index].channelLink = newValue;
    // Update the state with the new subscriptions array
    setSubscriptions(updatedSubscriptions);
  };

  

  const handleAddSubscription = () => {
    // make sure the link isnt blank
    if (newSubscriptionLink === '') {
      //TODO: better validation
      alert('Please enter a valid Youtube Channel Link');
      return;
    }
    // Check if maximum subscriptions reached
    if (subscriptions.length < 5) {
      // Add new subscription
      setSubscriptions([...subscriptions, { channelLink: newSubscriptionLink  }]);
      setNewSubscriptionLink('');
    } else {
      alert('Maximum subscriptions reached');
    }
  };

  const handleSubmitChanges = async () => {
    //make sure at least one contact method is entered
    if (currentPhoneNumber === '' && currentEmail === '' && currentNpub === '') {
      alert('Please enter at least one contact method');
      return;
    }
    // Save changes to the database via API
    setLoading(true);

    // Create the data object to send to the API
    const contactArray = [];
    if (currentPhoneNumber != '') {
      contactArray.push({ contactDetail: currentPhoneNumber, distributionType: 'sms' });
    }
    if (currentEmail != '') {
      contactArray.push({ contactDetail: currentEmail, distributionType: 'email' });
    }
    if (currentNpub != '') {
      contactArray.push({ contactDetail: currentNpub, distributionType: 'nostr' });
    }

    const feedSroucesArray = subscriptions.map((subscription) => {
      let modifiedLink = subscription.channelLink.replace("https://www.youtube.com/channel/UC", "");

      return {
        sourceData: modifiedLink,
        sourceType: 'Youtube Channel'
      }
    });

    const data = {
      contactDetail: currentNpub,
      resultDistributionMethods: contactArray,
      subscriptionEndpoints: subscriptionEndpoints,
      feedSources: feedSroucesArray

    };
    console.log('final data');
    console.log(JSON.stringify(data, null, 2));

    const jwt = await getNIP07Auth();
    const upsertCHADUrl = `${CHAD_API_URL}/upsertCHADSub`;
    var config = {
      method: 'post',
      url: upsertCHADUrl,
      headers: { 
         'Authorization': `Bearer ${jwt}` , 
      },
      data: data
   };
    axios(config)
      .then(response => {
        console.log('response:', response);
        handleLookupSubscriptions();
        // setTimeout(async () => {
        //   handleLookupSubscriptions();
        // }, 5000); // Delay of 500 milliseconds
      })
      .catch(error => {
        console.error('Error saving changes:', error);
      })
      .finally(() => {
        //setLoading(false);
      });
  };

  const handleDeleteSubscription = (index) => {
    const updatedSubscriptions = [...subscriptions];
    updatedSubscriptions.splice(index, 1);
    setSubscriptions(updatedSubscriptions);
  };

  const handleExtensionsButtonClick = (index) => {
    console.log("handleExtensionsButtonClick")
  };

  const handleLookupSubscriptions = async () => {
    //localStorage.removeItem("jwtToken")
    const jwt = await getNIP07Auth();
     // make sure a contact type is selected
     if (inputContactType === 'default') {
      alert('Please select a contact type');
      return;
    }
    //make sure a contact is entered
    if (inputContact === '' && !jwt) {
      alert('Please enter a contact');
      return;
    }

    // Fetch subscriptions from the database via API
    setLoading(true);

    console.log('fetching subscriptions for:', inputContact, inputContactType);

    const getCHADUrl = `${CHAD_API_URL}/getCHADSub`;
    var config = {
      method: 'get',
      url: getCHADUrl,
      headers: { 
         'Authorization': `Bearer ${jwt}` , 
      }
   };
    
    axios(config)
    .then(response => {
        console.log('response:', response);
        // parse subscriptions and store them as an array of objects
        const subscriptionsArray = response.data.feedSources.map((source) => {
          // only include youtube channels
          console.log("subscriptionArray iteration:",source)
          console.log("source.sourceType:", source.sourceType)
          console.log("source.sourceType !== 'YouTube Channel'", source.sourceType !== 'Youtube Channel')
          if (source.sourceType != 'Youtube Channel') {
            console.log("Not YouTube Channel!")
            return;
          }
          console.log("returning object:",{channelLink: source.channelLink})
          return {
            channelLink: source.channelLink,
            channelName: source.channelName,
            channelThumbnail: source.channelThumbnail
          }
        });
        setSubscriptions(subscriptionsArray);
        console.log("subscriptionsArray:",subscriptionsArray);
        // parse distribtion methods and store each matching one
        response.data.resultDistributionMethods.forEach((method) => {
          switch (method.distributionType) {
            case 'sms':
              setCurrentPhoneNumber(method.contactDetail);
              break;
            case 'email':
              setCurrentEmail(method.contactDetail);
              break;
            case 'nostr':
              setCurrentNpub(method.contactDetail);
              break;
            default:
              break;
          }
        });

        // make sure inputContact is saved to the correct state
        // console.log("inputContactType:",inputContactType)
        // switch (inputContactType) {
        //   case 'npub':
        //     setCurrentNpub(inputContact);
        //     break;
        //   case 'phone':
        //     setCurrentPhoneNumber(inputContact);
        //     break;
        //   case 'email':
        //     setCurrentEmail(inputContact);
        //     break;
        //   default:
        //     break;
        // }
        setSubscriptionEndpoints(response.data.subscriptionEndpoints);
        setContactInfo(currentNpub);
        setLoading(false);
      }).catch(error => {
        console.error('Error fetching subscriptions:', error);
      }).finally(() => {
        setLookupHasRun(true);
      });

    //wait 3 seconds to simulate loading for testing
    /*setTimeout(() => {
      setLoading(false);
      setSubscriptions([
        { link: 'Youtube Channel 1', distributionMethods: ['email', 'sms'] },
        { link: 'Youtube Channel 2', distributionMethods: ['nostr'] },
        { link: 'Youtube Channel 3', distributionMethods: ['email'] },
        { link: 'Youtube Channel 4', distributionMethods: ['sms'] },
        { link: 'Youtube Channel 5', distributionMethods: ['email', 'sms', 'nostr'] }
      ]);
      setCurrentEmail('test@test.com');
      setCurrentPhoneNumber('123-456-7890');
      setCurrentNpub('npub:1234567890');

      
    }, 3000);
    setLookupHasRun(true);*/
  }

  // Function to copy text to the clipboard
  const copyText = (text) => {
    const textarea = document.createElement('textarea');
    textarea.value = text;
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand('copy');
    document.body.removeChild(textarea);
  };
  
  return (
    <div>
    <div className="chad-container">
      {lookupHasRun != '' && (
        <div
          className='padding-[12em]'
        >
        </div>
      )}
      <div className="chad-header-container">
        <div className="chad__icon">
          <img src={ChadIcon} alt="icon"/>
        </div>
        <h1 className="chad-title">CHADBOT: Get AI Generated Content Based on YT Channels</h1>
      </div>
      {loading && (
        <div>
          <h1>Loading...</h1>
        </div>
      )}
      {!loading && (<>
          {/* {(!lookupHasRun) && (
          <h2>Enter Your Npub OR hit extension button</h2>
          )} */}
          <div>
          {/* <div className="chad-input-group">
            <input 
              type="text" 
              value={inputContact} 
              onChange={(e) => setInputContact(e.target.value)} 
              placeholder="Enter Your Npub OR hit extension button"
              className='chad-input'
            />
            <button 
              onClick={handleExtensionsButtonClick} 
              className="chad-extensions-button"
              title="Use extension"
              height="50px"
              width="50px"
            >
              <img src={ExtensionsIcon} alt="Extensions" className="chad-extensions-icon" />
            </button>
          </div> */}

            {/* <br /><br />
            <select
              value={inputContactType}
              onChange={(e) => setInputContactType(e.target.value)}
              className='chad-input'
            >
              <option value="npub">Nostr npub</option>
            </select> */}
          </div>

          {!lookupHasRun && (
            <button
              onClick={handleLookupSubscriptions}
              className="chad-button"
              disabled={loading}
            >
              Login with Nostr Extension to Manage Subscriptions (Alby, nos2x)
            </button>
          )}

          {(lookupHasRun) && (
          <div className='chad-contact-info'>
            <h2>Nostr npub for DM Updates:</h2>
            {/* <label>
                Phone:
              <input
                type="text"
                value={currentPhoneNumber}
                onChange={(e) => setCurrentPhoneNumber(e.target.value)}
                className='chad-input'
              />
            </label>
            <br />
            <button onClick={() => setCurrentPhoneNumber('')} className='chad-button'>Remove Phone Number</button>
            <br />
            <label>
              Email:
              <input
                type="text"
                value={currentEmail}
                onChange={(e) => setCurrentEmail(e.target.value)}
                className='chad-input'
              />
            </label>
            <br />
            <button onClick={() => setCurrentEmail('')} className='chad-button'>Remove Email</button>
            <br /> */}
            <label>
              Npub:
              <input
                type="text"
                value={currentNpub}
                onChange={(e) => setCurrentNpub(e.target.value)}
                className='chad-input'
                readOnly
              />
            </label>
            <br />
            {/* <button onClick={() => setCurrentNpub('')} className='chad-button'>Clear Npub</button> */}
          </div>          
          )}
          {lookupHasRun && (<>
            <div className='chad-subscription' style={{borderRadius: 10}}>
            <h1>Subscription Type Selection:</h1>
            <br />
            <div>
              <input
                type="checkbox"
                value="alert"
                checked={subscriptionEndpoints.includes("alert")}
                onChange={(e) => handleSubscriptionEndpointsChange(e.target.value)}
              />
              <label htmlFor="option1">New Video Alerts</label>
              </div>
              <div>
                <input
                  type="checkbox"
                  value="YT_Summarizer"
                  checked={subscriptionEndpoints.includes("YT_Summarizer")}
                  onChange={(e) => handleSubscriptionEndpointsChange(e.target.value)}
                />
                <label htmlFor="option2">New Video Summaries</label>
              </div>
              <div>
                <input
                  type="checkbox"
                  value="YT2BlogPost"
                  checked={subscriptionEndpoints.includes("YT2BlogPost")}
                  onChange={(e) => handleSubscriptionEndpointsChange(e.target.value)}
                />
                <label htmlFor="option3">New Video Blog Posts</label>
              </div>
            </div>
            <h2>Subscriptions {subscriptions.length > 2 && '- Scroll to View All'}</h2>
            <ul>
            <div
                className='max-w-[500px] overflow-x-auto flex-row flex'
            > 
              {subscriptions.map((subscription, index) => (
                /*<div
                          className='chad-link'
                        >
                          {subscription?.link || subscription}
                        </div> */
                    <li key={index} className='chad-subscription'>
                    {(
                      <>
                        <label className="block mt-2">{subscription.channelName || "New Subscription"}</label>
                        <img
                          src={subscription.channelThumbnail || ChadIcon}
                          alt={subscription.channelName || "Channel Icon"}
                          width={80}
                          height={80}
                          style={{ width: 80, height: 80, objectFit: 'cover', marginBottom: 10, marginTop: 10, borderRadius: 18}}
                        />
                        <div>
                        <button
                            onClick={() => copyText(subscription?.channelLink || '')}
                            className='chad-button'
                            style={{marginRight: 10}}
                          >
                            Copy Link
                        </button>
                        <button 
                          className='chad-button mt-2'
                          onClick={() => handleDeleteSubscription(index)}
                          disabled={loading}
                        >
                          Delete
                        </button>
                      </div>
                      </>
                    )}
                  </li>
              ))}
            </div>
            {subscriptions.length === 0 && (
              <>
              <li>No subscriptions found. You Can Create one Below!</li>
              </>
            )}
            <br />
            </ul>
          <h1>Add New Subscription</h1>
          <input 
            type="text" 
            value={newSubscriptionLink} 
            className='chad-input'
            onChange={(e) => setNewSubscriptionLink(e.target.value)} 
            placeholder="Enter Youtube Channel Link (ex: https://www.youtube.com/@pbs_plebs)" 
          />
          <button 
            onClick={handleAddSubscription}
            style={{marginBottom:'10px'}}
            className='chad-button'
            disabled={loading}
          >
            Add Subscription
          </button>
          <button
            onClick={handleSubmitChanges}
            className='chad-button'
            style={{marginBottom:'10px'}}
            disabled={loading}
          >
            Save All Changes
          </button>
          </>)}
      </>)}
    </div>
    </div>
  );
};

export default CHADbotComponent;