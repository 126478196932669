import { Fragment } from 'react';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

export default function CustomAlert({ message, button1, button2 }) {
  return (
    <Fragment>
      <Paper
        variant="outlined"
        sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}
        style={{
          backgroundColor: 'white',
          color: 'black',
          textAlign: 'center',
          border: '1px solid black',
          padding: '20px',
          marginLeft:'350px',
          marginRight:'350px',
          minWidth: '400px'
        }}
      >
        <Typography variant="h6" align="center" style={{ paddingBottom: '10px' }}>
          {message}
        </Typography>

        <Box sx={{ display: 'flex', justifyContent: 'center', gap: 2 }}>
          {button1 && (
            <Button
              variant="contained"
              sx={{ backgroundColor: 'black', color: 'white' }}
              onClick={button1.action}
            >
              {button1.text}
            </Button>
          )}
          {button2 && (
            <Button
              variant="outlined"
              sx={{ borderColor: 'black', color: 'black' }}
              onClick={button2.action}
            >
              {button2.text}
            </Button>
          )}
        </Box>
      </Paper>
    </Fragment>
  );
}
