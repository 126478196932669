import React, { useCallback, useState, useEffect} from 'react';
import { MarkerType, Position } from 'reactflow';
import ReactFlow, {
  addEdge,
  MiniMap,
  Controls,
  Background,
  useNodesState,
  useEdgesState,
} from 'reactflow';

import { ActiveTabProvider, useActiveTab } from '../../common/ActiveTabContext';
import { LogicAppTabProvider, useLogicAppTab } from './LogicAppTabContext';
import {demo2Edges as initialEdges } from './initial-elements';
import GPTNode from '../GPT/GPTNode';
import SDNode from '../StableDiffusion/SDNode';
import RssExtractorNode from '../Yitter/RssExtractorNode';
import WhisperNode from '../Whisper/WhisperNode';
import Image2TextNode from '../Image2Text/Image2TextNode';
import { BeatLoader } from 'react-spinners';
import TranscriptModal from '../../common/components/modal/TranscriptModal';
import CompartmentalizedTranscriptModal from '../../common/components/modal/CompartmentalizedTranscriptModal';

import 'reactflow/dist/style.css';
import './overview.css';
import { 
  run_example_chained_logic_app, 
  run_example_chained_logic_app2,
  run_sketch_to_webpage,
  get_gpt_price_estimate, 
  get_sd_price_estimate, 
  fetchAllPrices ,
  run_rss_agent
} from '../../common/nip105-client'; // Adjust the path accordingly
import LogCaptureComponent from '../../common/components/LogCaptureComponent';
import DemoModal from '../../common/components/modal/DemoModal'; // Import the CustomModal component
import demoImage1 from '../../media/images/demo/demo_image1.png'
import demoImage2 from '../../media/gifs/cascdrDemo2.gif'
import demoImage3 from '../../media/gifs/cascdrDemo3.gif'
import demoImage4 from '../../media/gifs/cascdrDemo4.gif'
import demoImage5 from '../../media/images/demo/demo_image5.png'
import demoImage6 from '../../media/gifs/cascdr_demo_yitter.gif'
import {getBitcoinPrice} from "../../common/bitcoinPrice"
import { requestProvider } from '@getalby/bitcoin-connect';

const demoModals = [
  {
    title: 'What Is This Demo?',
    explanationText: 'This example demos using ChatGPT to construct a high quality text to image prompt.',
    imageUrl: demoImage1,
    buttonText: 'Next',
  },
  {
    title: 'Getting Started',
    explanationText: 'Type in a vague prompt & Cascdr will cascade your ChatGPT result straight into the text to image service.',
    imageUrl: demoImage2,
    buttonText: 'Next',
  },
  {
    title: 'Lightning Payments',
    explanationText: 'You can pay for the services with a Lightning Browser Plugin like Alby.',
    imageUrl: demoImage3,
    buttonText: 'Next',
  },
  {
    title: 'Explore Available Services',
    explanationText: 'Go to the top navigation bar to see the menu of available services & call them individually.',
    imageUrl: demoImage4,
    buttonText: "Let's Go!",
  },
];

const demoModals2 = [
  {
    title: 'What Is This Demo?',
    explanationText: 'This example demos using the RSS Feed + AI Transcriptions to get a transcript of the latest episode',
    imageUrl: demoImage5,
    buttonText: 'Next',
  },
  {
    title: 'Getting Started',
    explanationText: 'Type in the name of the podcast you want to search.',
    imageUrl: demoImage6,
    buttonText: 'Next',
  },
  {
    title: 'Lightning Payments',
    explanationText: 'You can pay for the services with a Lightning Browser Plugin like Alby.',
    imageUrl: demoImage3,
    buttonText: 'Lets Go!',
  },
];

const nodeTypes = {
  gpt: GPTNode,
  sd:SDNode,
  yitter:RssExtractorNode,
  whisper:WhisperNode,
  image2text:Image2TextNode
};

const minimapStyle = {
  height: 120,
};

const onLoad = (reactFlowInstance) => {
    // Set minZoom and maxZoom to the same value to disable zooming
    reactFlowInstance.setMinMaxZoom(1, 1);
  };

const onInit = (reactFlowInstance) => (null);

const FollowUpPrompt = ({ text2ImagePreconfiguredFollowUp, setText2ImagePreconfiguredFollowUp,setText2ImagePrompt }) => {
  function resetToCustom(){
    setText2ImagePreconfiguredFollowUp('');
    setText2ImagePrompt('');
  }
  function setToShoppingAssistant(){
    const prompt = `Please identify the object shown in the photograph. If possible, provide me your best guess at an Amazon or Home Depot link where I could purchase the item.


    Please provide the answer only in markdown text like the following example:
    **Item Description:** Harry Potter by JK Rowling  
    **Product Link:** [Amazon](https://amazon.com/s?k=harry+potter)
    `;

    setText2ImagePrompt(prompt)
    setText2ImagePreconfiguredFollowUp('shopping_assistant')
  }

  function setToStackSatsAppraiser(){
    const webpageDefaultPrompt = "I am trying to sell all my belongings for bitcoin in preparation for the great bull run. Please take the photo provided and help me determine a competitive value for it in bitcoin."
    setText2ImagePrompt(webpageDefaultPrompt)
    setText2ImagePreconfiguredFollowUp('stack_sats_assistant')
  }

  return (
    <div style={{backgroundColor:"transparent"}}>
      <button
        onClick={() =>  setToStackSatsAppraiser()}
        className={text2ImagePreconfiguredFollowUp === 'stack_sats_assistant' ? 'active-tab' : ''}
        style={{
          fontSize: '18px',
          fontWeight: text2ImagePreconfiguredFollowUp === 'stack_sats_assistant' ? 'bold' : 'normal',
          paddingLeft: '28px',
          marginLeft: '12px',
          marginTop:'10px',
          paddingRight: '18px',
          background: 'linear-gradient(to right, #F81E1E, #f8801e)',
          borderRadius: '4px',
          borderWidth: text2ImagePreconfiguredFollowUp === 'stack_sats_assistant' ? '3px' : '0px',
          borderColor:'white',
          opacity: text2ImagePreconfiguredFollowUp === 'stack_sats_assistant' ? '100%' : '50%'
        }}
      >
        Sat Stacker Assistant
    </button>
      <button
        onClick={() => setToShoppingAssistant()}
        className={text2ImagePreconfiguredFollowUp === 'shopping_assistant' ? 'active-tab' : ''}
        style={{
          fontSize: '18px',
          fontWeight: text2ImagePreconfiguredFollowUp === 'shopping_assistant' ? 'bold' : 'normal',
          paddingLeft: '28px',
          marginLeft: '12px',
          paddingRight: '18px',
          marginTop:'10px',
          background: 'linear-gradient(to right, #F81E1E, #f8801e)',
          borderRadius: '4px',
          borderWidth: text2ImagePreconfiguredFollowUp === 'shopping_assistant' ? '3px' : '0px',
          borderColor:'white',
          opacity: text2ImagePreconfiguredFollowUp === 'shopping_assistant' ? '100%' : '50%'
        }}
      >
        Shopping Assistant
      </button>
      <button
        onClick={() =>  resetToCustom()}
        className={text2ImagePreconfiguredFollowUp === '' ? 'active-tab' : ''}
        style={{
          fontSize: '18px',
          fontWeight: text2ImagePreconfiguredFollowUp === '' ? 'bold' : 'normal',
          paddingLeft: '28px',
          marginLeft: '12px',
          marginTop:'10px',
          paddingRight: '18px',
          background: 'linear-gradient(to right, #F81E1E, #f8801e)',
          borderRadius: '4px',
          borderWidth: text2ImagePreconfiguredFollowUp === '' ? '3px' : '0px',
          borderColor:'white',
          opacity: text2ImagePreconfiguredFollowUp === '' ? '100%' : '50%'
        }}
      >
        Custom
      </button>
    </div>
  );
};

const Tabs = ({ logicAppTab, setLogicAppTab }) => {
  return (
    <div>
      <button
        onClick={() => setLogicAppTab('llm_to_text2image')}
        className={logicAppTab === 'llm_to_text2image' ? 'active-tab' : ''}
        style={{
          fontSize: '18px',
          fontWeight: logicAppTab === 'llm_to_text2image' ? 'bold' : 'normal',
          paddingLeft: '28px',
          marginLeft: '12px',
          paddingRight: '18px',
          background: 'linear-gradient(to right, #F81E1E, #f8801e)',
          borderRadius: '4px',
          borderWidth: logicAppTab === 'llm_to_text2image' ? '3px' : '0px',
          borderColor:'white',
          opacity: logicAppTab === 'llm_to_text2image' ? '100%' : '50%'
        }}
      >
        {'GPT -> Image'}
      </button>
      <button
        onClick={() => setLogicAppTab('yitter_to_transcriptions')}
        className={logicAppTab === 'yitter_to_transcriptions' ? 'active-tab' : ''}
        style={{
          fontSize: '18px',
          fontWeight: logicAppTab === 'yitter_to_transcriptions' ? 'bold' : 'normal',
          paddingLeft: '28px',
          marginLeft: '12px',
          paddingRight: '18px',
          background: 'linear-gradient(to right, #F81E1E, #f8801e)',
          borderRadius: '4px',
          borderWidth: logicAppTab === 'yitter_to_transcriptions' ? '3px' : '0px',
          borderColor:'white',
          opacity: logicAppTab === 'yitter_to_transcriptions' ? '100%' : '50%'
        }}
      >
        {'RSS -> Transcript'}
      </button>
      <button
        onClick={() => setLogicAppTab('image2text_playground')}
        className={logicAppTab === 'image2text_playground' ? 'active-tab' : ''}
        style={{
          fontSize: '18px',
          fontWeight: logicAppTab === 'image2text_playground' ? 'bold' : 'normal',
          paddingLeft: '28px',
          marginLeft: '12px',
          paddingRight: '18px',
          background: 'linear-gradient(to right, #F81E1E, #f8801e)',
          borderRadius: '4px',
          borderWidth: logicAppTab === 'image2text_playground' ? '3px' : '0px',
          borderColor:'white',
          opacity: logicAppTab === 'image2text_playground' ? '100%' : '50%'
        }}
      >
        Image2Text Playground
      </button>
    </div>
  );
};


const LogicAppView = ({ wfid, paymentChoiceStatus, setPaymentChoiceStatus }) => {
  const [gptPrompt, setGptPrompt] = useState('');
  const [sdModel, setSdModel] = useState(null);
  const [isLoading, setIsLoading] = useState(false); // State for loading animation
  const [logsAreVisible,setLogsAreVisible] = useState(true);
  const [workflowResult,setWorkflowResult] = useState(null);
  //Service Prices
  const [gptPrice, setGPTPrice] = useState(null);
  const [sdPrice, setSdPrice] = useState(null);
  const [yitterPrice, setYitterPrice] = useState(null);
  const [whisperPrice, setWhisperPrice] = useState(null);
  const [text2ImagePrice, setText2ImagePrice] = useState(null);
  const [totalPriceSummary,setTotalPriceSummary] = useState('69420 sats');
  //Demo Modals:
  const [demoModalState,setDemoModalState] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { activeTab, setActiveTab } = useActiveTab();
  const {logicAppTab, setLogicAppTab} = useLogicAppTab();
  //Transcripts:
  const [transcript, setTranscript] = useState('');
  const [currentEpisodeData,setCurrentEpisodeData] = useState({guid:null,itemUrl:null});
  const [isTranscriptModalOpen, setIsTranscriptModalOpen] = useState(false);
  const [hasSeenYitterDemo,setHasSeenYitterDemo] = useState(false);
  const [isAiAnalysisOpen, setIsAiAnalysisOpen] = useState(false);
  const [aiAnalysis,setAiAnalysis] = useState('');
  const [initialToggleTab,setInitialToggleTab] = useState('Transcript')
  //Image2Text:
  const [selectedFile, setSelectedFile] = useState(null);
  const [text2ImagePrompt,setText2ImagePrompt] = useState(null);
  const [text2ImagePreconfiguredFollowUp, setText2ImagePreconfiguredFollowUp] = useState(null);

  function getText2ImageAnnotation(){
    var result = "📸🤔Enter custom follow up prompt to the uploaded image (ex: who is this person or what is this object?)"
    if(text2ImagePreconfiguredFollowUp === "stack_sats_assistant"){
      result = " 🍊💊💰🤠👍 This prompt helps you stack sats by appraising the value of an object based on the image you upload! Stay humble, Sell All Your Belongings & Stack Sats!"
    }
    else if(text2ImagePreconfiguredFollowUp === "shopping_assistant"){
      result = "📸🔍🛒This prompt helps you discover where you can purchase the uploaded image of an item!"
    }
    return result
  }

  const handleGPTPromptChange = (event) => {
      // console.log('handleGPTPromptChange:')
      // console.log(`event`,event.target.value)
      setGptPrompt(event.target.value);
  }

  const handleSDModelChange = (event) => {
    console.log('Stable Diffusion Model change to:');
    console.log(event.target.value);
    setSdModel(event.target.value);
  }

  const handleText2ImageChange = (event) => {
      // console.log('handleGPTPromptChange:')
      // console.log(`event`,event.target.value)
      setText2ImagePrompt(event.target.value);
  }

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleCopyToClipboard = (text) => {
    // Implement the copy to clipboard logic here
    // You can use the Clipboard API or a library like clipboard.js
    // Example using Clipboard API:
    navigator.clipboard.writeText(text).then(() => {
      // Handle success
    }).catch((err) => {
      console.error('Error copying to clipboard:', err);
    });
  };

  function extractUSDValue(markdownText) {
    //console.log("extractUSDValue...")
    const regex = /\*\*USD Value:\*\* \$([\d]+)/;
    const match = markdownText.match(regex);
    
    if (match && match[1]) {
      //console.log("got USD Value!")
      return parseInt(match[1], 10); // Converts the captured group to an integer
    } else {
      //console.log("didn't get usd value!")
      return null; // or an appropriate default/fallback value
    }
  }

  const ConditionalTranscriptModal = ({ 
    isTranscriptModalOpen, 
    transcript, 
    setIsTranscriptModalOpen,
    isAiAnalysisOpen,
    setIsAiAnalysisOpen,
    aiAnalysis,
    setAiAnalysis
  }) => {
    const handleCopy = (text) => navigator.clipboard.writeText(text);
    const [customPrompt,setCustomPrompt] = useState('');

    async function performJob() {
      setInitialToggleTab('AI Analysis')
      const data = {
        ytLink: 'episode mode',
        customPrompt: customPrompt,
        type: "Summary",
        episodeItemUrl: currentEpisodeData.itemUrl,
        guid: currentEpisodeData.guid
      };

      
      setIsLoading(true);
      try {
        const result = await run_rss_agent(data);
        console.log(`got result:`,result)
        if (result.authCategory && result.authCategory === "not-selected") {
          setPaymentChoiceStatus("choosePaymentMethod");
        } else {
          console.log(`rss_agent result:`,result)
          setAiAnalysis(result.transcript);
          setIsAiAnalysisOpen(true)
        }
      } catch (error) {
        console.error('Error running YouTube agent:', error);
        alert('Failed to process the request. Please try again.');
      }
      setIsLoading(false);
    }
  
    if (logicAppTab === "image2text_playground") {
      return (
        <>
          {isTranscriptModalOpen && (
            <TranscriptModal
              title="Image Analysis"
              transcript={transcript}
              onClose={() => setIsTranscriptModalOpen(false)}
              onCopy={handleCopy}
            />
          )}
        </>
      );
    } else {
      return (
        <>
          {isTranscriptModalOpen &&(
            <CompartmentalizedTranscriptModal
              title="Media Transcript"
              transcriptData={transcript}
              onClose={() => setIsTranscriptModalOpen(false)}
              onCopy={handleCopy}
              onRunAnalysis={performJob}
              setCustomPrompt={setCustomPrompt}
              showOptionsOnOpen={true}
              initialToggleTab={initialToggleTab}
              isLoading={isLoading}
            />
          )}
          {isAiAnalysisOpen && (
            <TranscriptModal
              title={"Summary"}
              transcript={aiAnalysis}
              onClose={() => setIsAiAnalysisOpen(false)}
              onCopy={handleCopy}
            />
          )}
        </>
      );
    }
  };


  useEffect(() => {
    // Open the modal corresponding to the demoModalState
    setLogsAreVisible(window.innerWidth > 1100)
    if(activeTab === 'demo'){
      setDemoModalState(0)

    }
    else{
      setDemoModalState(null)
    }
    const webpageDefaultPrompt = "I am trying to sell all my belongings for bitcoin in preparation for the great bull run. Please take the photo provided and help me determine a competitive value for it in bitcoin."
    //const webpageDefaultPrompt = "Please identify the object shown in the photograph. If possible provide me your best guess at an amazon or home depot link where I could purchase the item. Please respond only in JSON with nothing else like so: {item_description:'Harry Potter by JK Rowling',product_link:'https://amazon.com/search/harry+potter'}"
    setText2ImagePrompt(webpageDefaultPrompt)
    setText2ImagePreconfiguredFollowUp("stack_sats_assistant");
  }, []);

  useEffect(() => {
    // Function to parse URL parameters
    const parseParams = () => {
        const params = new URLSearchParams(window.location.search);
        const wfidParam = params.get('wfid');
        if (wfidParam) setLogicAppTab(wfidParam);
    };

    parseParams();
}, []);
  

  useEffect(() => {
      const handlePopState = () => {
          // Extract the tab parameter from the URL
          const params = new URLSearchParams(window.location.search);
          const tab = params.get('tab');

          // Update the active tab based on the URL, or reset to default if no tab is specified
          if (tab) {
              setActiveTab(tab);
          } else {
              // Reset to default state if back at main page without specific tab
              setActiveTab(null); // Or whatever your default state should be
          }
      };

      window.addEventListener('popstate', handlePopState);

      return () => window.removeEventListener('popstate', handlePopState);
  }, [setActiveTab]);

  useEffect(() => {
    // Open the modal corresponding to the demoModalState
      openModal();
  }, [demoModalState]);

  function setInitialView(){
    // Close the last modal
      setActiveTab(0);
      setDemoModalState(null);
      //setLogicAppTab('yitter_to_transcriptions')//
      closeModal();

      async function fetchProvider() {
        try {
          const provider = await requestProvider();
        }
        catch{

        }
      }
      const isSubscribed = localStorage.getItem("isSubscribed")
      if(isSubscribed != "true"){fetchProvider()}
  }

  const handleNextButtonClick = () => {
    // Increment the demoModalState if it's not the last modal
    const limit = (logicAppTab == "llm_to_text2image") ? demoModals.length - 1 : demoModals2.length - 1
    if (demoModalState >= 0 && demoModalState < limit) {
      setDemoModalState((prevState) => prevState + 1);
    } else {
      setInitialView();
    }
  };

  useEffect(() => {
  const fetchGPTPrice = async () => {
    try {
      const prices = await fetchAllPrices();
      setGPTPrice(prices.gpt_price);
      setSdPrice(prices.sd_price);
      setWhisperPrice({
        price_fixed : prices.whisper_price_fixed,
        price_variable: prices.whisper_price_variable,
        price_units : prices.whisper_price_unit
      });
      setYitterPrice(prices.yitter_price);
      setText2ImagePrice(prices.image2text_price_fixed);
      //console.log(`prices:`,prices.image2text_price_fixed)
    } catch (error) {
      console.error('Failed to fetch GPT price:', error);
    }
  };
  fetchGPTPrice(); // This line calls fetchAllPrices
  setNodes(initialNodes);

  window.addEventListener(
    "resize",
    () => {
      setLogsAreVisible(window.innerWidth > 1100)
    }
  );
  //console.log("logicAppTab:",logicAppTab)
}, [logicAppTab]); // This hook runs whenever logicAppTab changes

  useEffect(() => {

    if(logicAppTab != "image2text_playground"){return}
    // if(text2ImagePreconfiguredFollowUp === "shopping_assistant"){
    //   //const webpageDefaultPrompt = "Please identify the object shown in the photograph. If possible provide me your best guess at an amazon or home depot link where I could purchase the item. Please respond only in JSON with nothing else like so: {item_description:'Harry Potter by JK Rowling',product_link:'https://amazon.com/search/harry+potter'}"
    //   setText2ImagePrompt("");
    // }
    // else{
    //   const webpageDefaultPrompt = "Please identify the object shown in the photograph. If possible provide me your best guess at an amazon or home depot link where I could purchase the item. Please respond only in JSON with nothing else like so: {item_description:'Harry Potter by JK Rowling',product_link:'https://amazon.com/search/harry+potter'}"
    //   setText2ImagePrompt(webpageDefaultPrompt)
    // }
    setNodes(initialNodes);
    // console.log("text2ImagePreconfiguredFollowUp:",text2ImagePreconfiguredFollowUp);
    // console.log("text2ImagePrompt",text2ImagePrompt);
  },[text2ImagePreconfiguredFollowUp])


  useEffect(() => {
    if(gptPrice === null){
      setNodes(initialNodes);
    }
    if(logicAppTab === 'yitter_to_transcriptions' && whisperPrice){
      setTotalPriceSummary((`${(whisperPrice.price_fixed + yitterPrice)/1000} sats + ${whisperPrice.price_variable/1000} sats/min`))
    }
    else if(logicAppTab === 'llm_to_text2image'){
      setTotalPriceSummary(`${(gptPrice + sdPrice) / 1000} sats`)
    }
    else if (logicAppTab === "image2text_playground"){
      setTotalPriceSummary(`${(text2ImagePrice) / 1000} sats`)
    }
  },[gptPrice,whisperPrice,yitterPrice,sdPrice,text2ImagePrice]);


  useEffect(() => {
    const generateInitialNodes = () => {
      if (logicAppTab === "llm_to_text2image") {
    return [
      // First commented value assignment here
        {
        id: '1',
        type: 'input',
        data: {
            label: 'Start',
        },
        className: 'ellipse',
        style: {
            color: 'white',
            backgroundImage: 'linear-gradient(to right, #F81E1E, #f8801e)', //Example linear gradient
            fontWeight: 'bold',
        },
        position: { x: -100, y: 150 },
        sourcePosition: Position.Right,
        targetPosition: Position.Left,
    },
    {
      id: '2',
      type: 'gpt',
      position: { x: 50, y: 200 },
      data: {
        selects: {
          'handle-0': 'smoothstep',
          'handle-1': 'smoothstep',
        },
        onChange: handleGPTPromptChange,
        price: gptPrice
      },
    },
    {
      id: '3',
      type: 'sd',
      position: { x: 350, y: 300 },
      data: { 
        onChange: handleSDModelChange,
        price: sdPrice 
      },
    },
    // {
    // id: '4',
    // type: 'default',
    // className: 'annotation',
    //   data: {
    //     label: (
    //       <div style={{ background: 'white', padding: '8px',borderRadius:'8px', width:'300px' }}>
    //         Welcome to the <strong>Cascdr demo app 🌊 </strong>! This example shows how we can chain or cascade NIP-105 endpoint calls together to make high quality image prompts with ChatGPT! 
    //         Simply type in a vague description and the workflow will make sure you get a great image!
    //       </div>
    //     ),
    //   },
    //   draggable: false,
    //   selectable: false,
    //   position: { x: 250, y: 550 },
    // },
    {
        id: '5',
        type: 'output',
        data: {
            label: (
                <div
                  onClick={() => {
                    console.log(`showing result:${workflowResult}`)
                    window.open(workflowResult, '_blank');
                  }}
                  style={{ textDecoration: workflowResult ? 'underline' : 'none' }} 
                > 
                  {workflowResult === null ? "Result" : "Click for Result"}
                </div>
              )
        },
        className: 'ellipse',
        style: {
            color: 'white',
            backgroundImage: 'linear-gradient(to right, #F81E1E, #f8801e)', //Example linear gradient
            fontWeight: 'bold',
            height: workflowResult === null ? '40px' : '60px',
            width: workflowResult === null ? '100px' : '100px'
        },
        position: { x: 575, y: 450 },
        sourcePosition: Position.Right,
        targetPosition: Position.Left,
        onClick: () => {
          // Handle the click event for this node
          console.log('Node #5 clicked');
          // Add your custom click logic here
        },
    }
    ];
  } else if (logicAppTab === "yitter_to_transcriptions") {
    return [
        // Second commented value assignment here
        {
          id: '1',
          type: 'input',
          data: {
              label: 'Start',
          },
          className: 'ellipse',
          style: {
              color: 'white',
              backgroundImage: 'linear-gradient(to right, #F81E1E, #f8801e)', //Example linear gradient
              fontWeight: 'bold',
          },
          position: { x: -100, y: 150 },
          sourcePosition: Position.Right,
          targetPosition: Position.Left,
      },
      {
        id: '2',
        type: 'yitter',
        position: { x: 50, y: 200 },
        data: {
          selects: {
            'handle-0': 'smoothstep',
            'handle-1': 'smoothstep',
          },
          onChange: handleGPTPromptChange,
          price: yitterPrice
        },
      },
      {
        id: '3',
        type: 'whisper',
        position: { x: 300, y: 350 },
        data: { 
          onChange: handleSDModelChange,
          price: whisperPrice 
        },
      },
      {
          id: '5',
          type: 'output',
          data: {
              label: (
                  <div
                    onClick={() => {
                      // console.log(`showing result:${workflowResult}`)
                      // window.open(workflowResult, '_blank');
                      setTranscript(workflowResult);
                      setIsTranscriptModalOpen(true);
                    }}
                    style={{ textDecoration: workflowResult ? 'underline' : 'none' }} 
                  > 
                    {workflowResult === null ? "Result" : "Click for Result"}
                  </div>
                )
          },
          className: 'ellipse',
          style: {
              color: 'white',
              backgroundImage: 'linear-gradient(to right, #F81E1E, #f8801e)', //Example linear gradient
              fontWeight: 'bold',
              height: workflowResult === null ? '40px' : '60px',
              width: workflowResult === null ? '100px' : '100px'
          },
          position: { x: 575, y: 450 },
          sourcePosition: Position.Right,
          targetPosition: Position.Left,
          onClick: () => {
            // Handle the click event for this node
            //console.log('Node #5 clicked');
            // Add your custom click logic here
          },
      }
    ];
  }else if (logicAppTab === "image2text_playground") {
    //const webpageDefaultPrompt = "Provide a description of this sketch of a website such that it could easily be interpretted by an LLM and made into the code needed to render the site." 
    //const webpageDefaultPrompt = "Provide a description of this sketch of a graphic such that it could easily be interpretted by an LLM and made into a high quality image using an text to image model." 

    return [
        // Second commented value assignment here
        {
          id: '1',
          type: 'input',
          data: {
              label: 'Start',
          },
          className: 'ellipse',
          style: {
              color: 'white',
              backgroundImage: 'linear-gradient(to right, #F81E1E, #f8801e)', //Example linear gradient
              fontWeight: 'bold',
          },
          position: { x: -100, y: 180 },
          sourcePosition: Position.Right,
          targetPosition: Position.Left,
      },
      {
        id: '2',
        type: 'image2text',
        position: { x: 50, y: 220 },
        data: {
          selects: {
            'handle-0': 'smoothstep',
            'handle-1': 'smoothstep',
          },
          onChange: handleGPTPromptChange,
          price: text2ImagePrice,
          selectedFile: selectedFile,
          setSelectedFile: setSelectedFile
        },
      },
      {
        id: '3',
        type: 'gpt',
        position: { x: 300, y: 280 },
        data: { 
          onChange: handleText2ImageChange,
          price: "0",
          textInput: text2ImagePrompt
        },
      },
      {
      id: '4',
      type: 'default',
      className: 'annotation',
        data: {
          label: (
            <div style={{ background: 'white', padding: '8px',borderRadius:'8px', width:'300px' }}>
              <p><strong>Step 1:</strong> Upload an Image</p>
              <div style={{ height: '10px' }}></div> {/* Spacer */}
              <p><strong>Step 2:</strong> Hit the Run button</p>
              <div style={{ height: '10px' }}></div> {/* Spacer */}
              <p><strong>Step 3:</strong> The follow up prompt in the 3rd box will tailor the response. You can select the preconfigured prompts above or hit "custom" to enter your own.</p>
              <div style={{ height: '10px' }}></div> {/* Spacer */}
              <p>{getText2ImageAnnotation()}</p>
            </div>
          ),
        },
        draggable: false,
        selectable: false,
        position: { x: -100, y: 350 },
      },
      {
          id: '5',
          type: 'output',
          data: {
              label: (
                  <div
                    onClick={() => {
                      // console.log(`showing result:${workflowResult}`)
                      // window.open(workflowResult, '_blank');
                      setTranscript(workflowResult);
                      setIsTranscriptModalOpen(true);
                    }}
                    style={{ textDecoration: workflowResult ? 'underline' : 'none' }} 
                  > 
                    {workflowResult === null ? "Result" : "Click for Result"}
                  </div>
                )
          },
          className: 'ellipse',
          style: {
              color: 'white',
              backgroundImage: 'linear-gradient(to right, #F81E1E, #f8801e)', //Example linear gradient
              fontWeight: 'bold',
              height: workflowResult === null ? '40px' : '60px',
              width: workflowResult === null ? '100px' : '100px'
          },
          position: { x: 575, y: 450 },
          sourcePosition: Position.Right,
          targetPosition: Position.Left,
          onClick: () => {
            // Handle the click event for this node
            //console.log('Node #5 clicked');
            // Add your custom click logic here
            setTranscript(workflowResult);
            setIsTranscriptModalOpen(true);
          },
      }
    ];
  } 
   else {
    return []; // Empty array when neither my_variable nor my_variable2 is true
  }
    };
    setTotalPriceSummary(null)
    const newInitialNodes = generateInitialNodes();
    setNodes(newInitialNodes);
    setWorkflowResult(null);
    if(logicAppTab == "yitter_to_transcriptions" && hasSeenYitterDemo === false){
      setHasSeenYitterDemo(true);
      setDemoModalState(0);
    }
    //console.log("setText2ImagePrompt:",text2ImagePrompt)
  }, [logicAppTab, /* Add other dependencies here */]);

  let initialNodes;

  if (logicAppTab === "llm_to_text2image") {
    initialNodes = [
      // First commented value assignment here
        {
        id: '1',
        type: 'input',
        data: {
            label: 'Start',
        },
        className: 'ellipse',
        style: {
            color: 'white',
            backgroundImage: 'linear-gradient(to right, #F81E1E, #f8801e)', //Example linear gradient
            fontWeight: 'bold',
        },
        position: { x: -100, y: 280 },
        sourcePosition: Position.Right,
        targetPosition: Position.Left,
    },
    {
      id: '2',
      type: 'custom',
      position: { x: 50, y: 200 },
      data: {
        selects: {
          'handle-0': 'smoothstep',
          'handle-1': 'smoothstep',
        },
        onChange: handleGPTPromptChange,
        price: gptPrice
      },
    },
    {
      id: '3',
      type: 'sd',
      position: { x: 350, y: 300 },
      data: { 
        onChange: handleSDModelChange,
        price: sdPrice 
      },
    },
    // {
    // id: '4',
    // type: 'default',
    // className: 'annotation',
    //   data: {
    //     label: (
    //       <div style={{ background: 'white', padding: '8px',borderRadius:'8px', width:'300px' }}>
    //         Welcome to the <strong>Cascdr demo app 🌊 </strong>! This example shows how we can chain or cascade NIP-105 endpoint calls together to make high quality image prompts with ChatGPT! 
    //         Simply type in a vague description and the workflow will make sure you get a great image!
    //       </div>
    //     ),
    //   },
    //   draggable: false,
    //   selectable: false,
    //   position: { x: 250, y: 550 },
    // },
    {
        id: '5',
        type: 'output',
        data: {
            label: (
                <div
                  onClick={() => {
                    console.log(`showing result:${workflowResult}`)
                    window.open(workflowResult, '_blank');
                  }}
                  style={{ textDecoration: workflowResult ? 'underline' : 'none' }} 
                > 
                  {workflowResult === null ? "Result" : "Click for Result"}
                </div>
              )
        },
        className: 'ellipse',
        style: {
            color: 'white',
            backgroundImage: 'linear-gradient(to right, #F81E1E, #f8801e)', //Example linear gradient
            fontWeight: 'bold',
            height: workflowResult === null ? '40px' : '60px',
            width: workflowResult === null ? '100px' : '100px'
        },
        position: { x: 575, y: 350 },
        sourcePosition: Position.Right,
        targetPosition: Position.Left,
        onClick: () => {
          // Handle the click event for this node
          console.log('Node #5 clicked');
          // Add your custom click logic here
        },
    }
    ];
  } else if (logicAppTab === "yitter_to_transcriptions") {
    initialNodes = [
        // Second commented value assignment here
        {
          id: '1',
          type: 'input',
          data: {
              label: 'Start',
          },
          className: 'ellipse',
          style: {
              color: 'white',
              backgroundImage: 'linear-gradient(to right, #F81E1E, #f8801e)', //Example linear gradient
              fontWeight: 'bold',
          },
          position: { x: -100, y: 150 },
          sourcePosition: Position.Right,
          targetPosition: Position.Left,
      },
      {
        id: '2',
        type: 'yitter',
        position: { x: 50, y: 200 },
        data: {
          selects: {
            'handle-0': 'smoothstep',
            'handle-1': 'smoothstep',
          },
          onChange: handleGPTPromptChange,
          price: yitterPrice
        },
      },
      {
        id: '3',
        type: 'whisper',
        position: { x: 300, y: 350 },
        data: { 
          onChange: handleSDModelChange,
          price: whisperPrice 
        },
      },
      // {
      // id: '4',
      // type: 'default',
      // className: 'annotation',
      //   data: {
      //     label: (
      //       <div style={{ background: 'white', padding: '8px',borderRadius:'8px', width:'300px' }}>
      //         Welcome to the <strong>Cascdr demo app 🌊 </strong>! This example shows how we can chain or cascade NIP-105 endpoint calls together to make high quality image prompts with ChatGPT! 
      //         Simply type in a vague description and the workflow will make sure you get a great image!
      //       </div>
      //     ),
      //   },
      //   draggable: false,
      //   selectable: false,
      //   position: { x: 250, y: 550 },
      // },
      {
          id: '5',
          type: 'output',
          data: {
              label: (
                  <div
                    onClick={() => {
                      // console.log(`showing result:${workflowResult}`)
                      // window.open(workflowResult, '_blank');
                      setTranscript(workflowResult);
                      setIsTranscriptModalOpen(true);
                    }}
                    style={{ textDecoration: workflowResult ? 'underline' : 'none' }} 
                  > 
                    {workflowResult === null ? "Result" : "Click for Result"}
                  </div>
                )
          },
          className: 'ellipse',
          style: {
              color: 'white',
              backgroundImage: 'linear-gradient(to right, #F81E1E, #f8801e)', //Example linear gradient
              fontWeight: 'bold',
              height: workflowResult === null ? '40px' : '60px',
              width: workflowResult === null ? '100px' : '100px'
          },
          position: { x: 575, y: 450 },
          sourcePosition: Position.Right,
          targetPosition: Position.Left,
          onClick: () => {
            // Handle the click event for this node
            //console.log('Node #5 clicked');
            // Add your custom click logic here
          },
      }
    ];
    
  }
  else if (logicAppTab === "image2text_playground"){
    initialNodes = [
        // Second commented value assignment here
        {
          id: '1',
          type: 'input',
          data: {
              label: 'Start',
          },
          className: 'ellipse',
          style: {
              color: 'white',
              backgroundImage: 'linear-gradient(to right, #F81E1E, #f8801e)', //Example linear gradient
              fontWeight: 'bold',
          },
          position: { x: -100, y: 180 },
          sourcePosition: Position.Right,
          targetPosition: Position.Left,
      },
      {
        id: '2',
        type: 'image2text',
        position: { x: 50, y: 220 },
        data: {
          selects: {
            'handle-0': 'smoothstep',
            'handle-1': 'smoothstep',
          },
          //onChange: handleGPTPromptChange,
          price: text2ImagePrice,
          selectedFile: selectedFile,
          setSelectedFile: setSelectedFile
        },
      },
      {
        id: '3',
        type: 'gpt',
        position: { x: 300, y: 280 },
        data: { 
          onChange: handleText2ImageChange,
          price: "0",
          textInput: text2ImagePrompt
        },
      },
      {
        id: '4',
        type: 'default',
        className: 'annotation',
          data: {
            label: (
              <div style={{ background: 'white', padding: '8px',borderRadius:'8px', width:'300px' }}>
                <p><strong>Step 1:</strong> Upload an Image</p>
                <div style={{ height: '10px' }}></div> {/* Spacer */}
                <p><strong>Step 2:</strong> Hit the Run button</p>
                <div style={{ height: '10px' }}></div> {/* Spacer */}
                <p><strong>Step 3:</strong> The follow up prompt in the 3rd box will tailor the response. You can select the preconfigured prompts above or hit "custom" to enter your own.</p>
                <div style={{ height: '10px' }}></div> {/* Spacer */}
                <p>{getText2ImageAnnotation()}</p>
            </div>
            ),
          },
          draggable: false,
          selectable: false,
          position: { x: -100, y: 350 },
      },
      {
          id: '5',
          type: 'output',
          data: {
              label: (
                  <div
                    onClick={() => {
                      // console.log(`showing result:${workflowResult}`)
                      // window.open(workflowResult, '_blank');
                      setTranscript(workflowResult);
                      setIsTranscriptModalOpen(true);
                    }}
                    style={{ textDecoration: workflowResult ? 'underline' : 'none' }} 
                  > 
                    {workflowResult === null ? "Result" : "Click for Result"}
                  </div>
                )
          },
          className: 'ellipse',
          style: {
              color: 'white',
              backgroundImage: 'linear-gradient(to right, #F81E1E, #f8801e)', //Example linear gradient
              fontWeight: 'bold',
              height: workflowResult === null ? '40px' : '60px',
              width: workflowResult === null ? '100px' : '100px'
          },
          position: { x: 575, y: 450 },
          sourcePosition: Position.Right,
          targetPosition: Position.Left,
          onClick: () => {
            // Handle the click event for this node
            //console.log('Node #5 clicked');
            // Add your custom click logic here
            setTranscript(workflowResult);
            setIsTranscriptModalOpen(true);
          },
      }
    ];
  } 
  else {
    initialNodes = []; // Empty array when neither my_variable nor my_variable2 is true
  }


  const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges);
  const onConnect = useCallback((params) => setEdges((eds) => addEdge(params, eds)), []);

  // we are using a bit of a shortcut here to adjust the edge type
  // this could also be done with a custom edge for example
  const edgesWithUpdatedTypes = edges.map((edge) => {
    if (edge.sourceHandle) {
      const edgeType = nodes.find((node) => node.type === 'custom').data.selects[edge.sourceHandle];
      edge.type = edgeType;
    }

    return edge;
  });

  function handleRequestPayment(){
    setIsLoading(false)
    setPaymentChoiceStatus("choosePaymentMethod")
  }

  const handleRunClick = async () => {
    //console.log("handleRunClick")
    setWorkflowResult(null)
    if(isLoading == true){return}
    try {
      //console.log("handleRunClick:", logicAppTab)
      setIsLoading(true); // Start the loading animation
      if(logicAppTab === "llm_to_text2image"){
        const result = await run_example_chained_logic_app(gptPrompt,sdModel,handleRequestPayment); // Call the main function when the button is clicked
        (result === "not-selected") ? (setPaymentChoiceStatus("choosePaymentMethod")) :(setPaymentChoiceStatus(null))
      }
      else if(logicAppTab === "yitter_to_transcriptions"){
        const {transcript:transcriptResult,guid, itemUrl} = await run_example_chained_logic_app2(gptPrompt);
        console.log(`transcriptResult:${transcriptResult}`)
        if(transcriptResult === "not-selected") {
          (setPaymentChoiceStatus("choosePaymentMethod")) 
        }else{
          setWorkflowResult(transcriptResult);
          setCurrentEpisodeData({guid:guid,itemUrl:itemUrl});
          setTranscript(transcriptResult);
          setIsTranscriptModalOpen(true);
        }
      }
      else if(logicAppTab === "image2text_playground"){
        setIsLoading(true); // Start the loading animation
        //console.log("handleRunClick gptPrompt:", gptPrompt)
        if(selectedFile){
          var prompt = text2ImagePrompt;
          var price = null;
          if(text2ImagePreconfiguredFollowUp === "stack_sats_assistant"){
            price = await getBitcoinPrice();
            console.log(`got bitcoinPrice:`,price)
            prompt = `
            Please help me determine this item & its approximate market value. Provide your best guess. I understand that this appraisal is imperfect and for demonstration purposes only.

            Respond only in Markdown format like this:

            **Item Description:** DeWalt Drill  

            **Rationale:** New drills are $200; let's assume $110 since this is after market.  
            
            **USD Value:** $110
            `;

            //The current bitcoin price is:${price}. Please format the response as JSON like so {item_description:"DeWalt Drill",rationale:"New drills are $200 let's assume $110 since this is after market.",USD_value:"$110",sats:"293,000
            //console.log(`stack sats assistant prompt:`, prompt)
          }
          var result = await run_sketch_to_webpage(selectedFile,prompt);
          if(result === "not-selected"){
            (setPaymentChoiceStatus("choosePaymentMethod"))
            return;
          }else{
            (setPaymentChoiceStatus(null))
          }
          // console.log(`result:`,result)
          // console.log(`text2ImagePreconfiguredFollowUp:`,text2ImagePreconfiguredFollowUp)
          if(text2ImagePreconfiguredFollowUp === "stack_sats_assistant"){
            //console.log("result:",result)
            const usdValue = extractUSDValue(result);
            if(usdValue && price){
              // console.log(`usdValue:`,usdValue)
              // console.log(`bitcoinPrice`,price)
              const sats =  Math.floor(usdValue * 1e8/price);
              // console.log("Sats:",sats)
              const resultForDisplay = result + "\r\n" +
              `
**Sats Value:** ${Number(sats)}
              `;

              result = resultForDisplay;
              // console.log(resultForDisplay)
              // console.log(`usdValue:`,usdValue)
            }
            else{
              console.log("couldn't parse USD value")
            }
          }
          else{
            //console.log("result:",result)
          }
          //console.log("setting workflow result!")
          // console.log("resultForDisplay:",result)
          setWorkflowResult(result);
          setTranscript(result);
          setIsTranscriptModalOpen(true);
          //console.log(`after set:`,isTranscriptModalOpen)
          setSelectedFile(null);
          //setNodes(initialNodes);
        }
        
      }
      else{
        setIsLoading(false);
      }
      
    } catch (error) {
      console.error('Error running main function:', error);
    }
    finally{
      setIsLoading(false);
    }
  };

  return (
    <div className="reactflow-container" style={{ display: 'flex', height: 'calc(100vh - 60px)', width: '95vw', paddingTop:'115px' }}> 
    {
    demoModalState != null && (
      <DemoModal
        isOpen={isModalOpen}
        onClose={setInitialView}
        onNext={handleNextButtonClick}
        title={logicAppTab === 'llm_to_text2image' ? demoModals[demoModalState].title : demoModals2[demoModalState].title}
        explanationText={logicAppTab === 'llm_to_text2image' ? demoModals[demoModalState].explanationText : demoModals2[demoModalState].explanationText}
        imageUrl={logicAppTab === 'llm_to_text2image' ? demoModals[demoModalState].imageUrl : demoModals2[demoModalState].imageUrl}
        buttonText={logicAppTab === 'llm_to_text2image' ? demoModals[demoModalState].buttonText : demoModals2[demoModalState].buttonText}
        onButtonClick={handleNextButtonClick} // Handle button click to move to the next modal
      />
      )
    }

    {/* Render the modal component when isModalOpen is true */}
    {isTranscriptModalOpen && (
     <ConditionalTranscriptModal
        isTranscriptModalOpen={isTranscriptModalOpen}
        transcript={transcript}
        setIsTranscriptModalOpen={setIsTranscriptModalOpen}
        text2ImagePreconfiguredFollowUp={handleCopyToClipboard}
        isAiAnalysisOpen={isAiAnalysisOpen}
        setIsAiAnalysisOpen={setIsAiAnalysisOpen}
        aiAnalysis={aiAnalysis}
        setAiAnalysis={setAiAnalysis}
      />
    )}
    
      {logsAreVisible && (
        <div style={{ flex: '3', height: '100%', overflowY: 'auto', backgroundColor: 'black' }}>
          {/* If LogCaptureComponent has its own background color, you don’t need to set backgroundColor here */}
          <LogCaptureComponent title={'Pipeline Activity:'} setWorkflowResult={setWorkflowResult} />
        </div>
      )}
      
      <div style={{ flex: '7', position: 'relative' }}> 
        {/* Button and ReactFlow components remain the same, ensure they are within this div */}
        
        {demoModalState === null && (
          <button
            onClick={handleRunClick}
            style={{
              background: 'linear-gradient(to right, #F81E1E, #f8801e)',
              color: 'white', 
              padding: '10px 20px',
              borderRadius: '5px',
              position: 'absolute',
              bottom: '20px',
              right: '20px',
              cursor: 'pointer',
              width: '120px',
              height: '40px',
              border: 'none',
              zIndex: 998,
            }}
            disabled={isLoading}
          >
            {isLoading ? (
              <BeatLoader color="white" size={8} loading={true} />
            ) : (
              <strong>Run</strong>
            )}
          </button>
        )}

          <Tabs logicAppTab={logicAppTab} setLogicAppTab={setLogicAppTab} style={{paddingTop:"350px"}}/>

          
          <div style={{color:'white',backgroundColor:'black',width:'340px',height:'50px',right:'0px',position:'absolute'}}> Estimated Total Price: {totalPriceSummary}</div>
          
        <ReactFlow
          nodes={nodes}
          edges={edgesWithUpdatedTypes}
          onNodesChange={onNodesChange}
          onEdgesChange={onEdgesChange}
          onConnect={onConnect}
          onInit={onInit}
          fitView
          attributionPosition="top-right"
          nodeTypes={nodeTypes}
        >
          {logicAppTab === "image2text_playground" && (
            <>
              <div style={{ marginTop: '10px',zIndex: 1000, color: 'white', backgroundColor: 'transparent', width: '500px', height: '50px', left: '40px', position: 'absolute' }}>
                <strong>Follow Up Prompts:</strong>
                <FollowUpPrompt
                text2ImagePreconfiguredFollowUp={text2ImagePreconfiguredFollowUp}
                setText2ImagePreconfiguredFollowUp={setText2ImagePreconfiguredFollowUp}
                setText2ImagePrompt={setText2ImagePrompt}
                style={{zIndex:1000, color: 'white', width: '600px', height: '50px', left: '0px', top: '100px' }}
              />
              </div>
              
            </>
          )}
          <MiniMap style={minimapStyle} zoomable pannable />
          <Controls />
          <Background color="#aaa" gap={16} />
        </ReactFlow>
        
      </div>
    </div>
  );
};

export default LogicAppView;
