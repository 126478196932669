import React from 'react';
import { Button, init } from '@getalby/bitcoin-connect-react';

// Initialize Bitcoin Connect
init({
  appName: "CASCDR",
  showBalance: true
});

function BitcoinConnectButton() {
  return (
    <Button 
    style={{ color: 'white' }} 
    onConnect={async (provider) => {
      const { preimage } = await provider.sendPayment("lnbc...");
      console.log("Payment preimage:", preimage);
    }}>
      Connect to Bitcoin Wallet
    </Button>
  );
}

export default BitcoinConnectButton;
